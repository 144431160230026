import { Button, Checkbox, Menu } from '@blueprintjs/core';
import { MenuItem2, Tooltip2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';
import {
  Cell,
  Column,
  ColumnHeaderCell,
  EditableCell2,
  RowHeaderCell,
  Table2,
} from '@blueprintjs/table';
import Fuse from 'fuse.js';
import { produce } from 'immer';
import isNumber from 'lodash/isNumber';
import { useMemo, useState } from 'react';

type ColumnType = 'str' | 'bool' | 'int' | 'float';
export interface IntentTableDTO {
  type: 'Table';
  index: (number | string)[];
  columns: string[];
  table_schema: [string, ColumnType][];
  data: (string | number | boolean | null)[][];
  confidence: number;
  last_updated: null | string;
  last_hash: null | string;
}

const IntentTableInput = ({
  initialData,
  onSubmit,
  submitOnChange,
  showSubmitButton,
}: {
  initialData: IntentTableDTO;
  onSubmit: (data: IntentTableDTO) => void;
  submitOnChange?: boolean;
  showSubmitButton?: boolean;
}) => {
  const columnNames = initialData.table_schema.map(
    ([columnName, columnType]) => columnName
  );

  const columnNamesToTypes: Record<string, ColumnType> =
    initialData.table_schema.reduce((acc, [name, type]) => {
      return { ...acc, [name]: type };
    }, {});

  const [data, setData] = useState(initialData);

  const handleChange = (data: IntentTableDTO) => {
    setData(data);
    if (submitOnChange) {
      onSubmit(data);
    }
  };

  const availableColumns = useMemo(
    () => columnNames.filter((colName) => !data.columns.includes(colName)),
    [columnNames, data.columns]
  );

  const getColumnType = (colName: string) => {
    return columnNamesToTypes[colName];
  };

  const renderCell = (rowIndex: number, columnIndex: number) => {
    const colName = data.columns[columnIndex];
    const colType = getColumnType(colName);
    const value = data.data[rowIndex][columnIndex];
    if (colType === 'bool') {
      return (
        <Cell intent={value === null ? 'warning' : undefined}>
          <Tooltip2
            content={value === null ? 'null' : ''}
            disabled={value !== null}
          >
            <Checkbox
              checked={value === true ? true : false}
              indeterminate={value === null}
              onChange={() => {
                if (value === true || value === false) {
                  handleChange(
                    produce(data, (draft) => {
                      if (isNumber(rowIndex) && isNumber(columnIndex)) {
                        draft.data[rowIndex][columnIndex] =
                          value === true ? false : null;
                      }
                    })
                  );
                }
                if (value === null) {
                  handleChange(
                    produce(data, (draft) => {
                      if (isNumber(rowIndex) && isNumber(columnIndex)) {
                        draft.data[rowIndex][columnIndex] = true;
                      }
                    })
                  );
                }
              }}
            />
          </Tooltip2>
        </Cell>
      );
    }

    if (value === null) {
      return (
        <EditableCell2
          truncated
          tooltip={'null'}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          intent={'warning'}
          value={''}
          onChange={(value, rowIndex, columnIndex) =>
            handleChange(
              produce(data, (draft) => {
                if (isNumber(rowIndex) && isNumber(columnIndex)) {
                  draft.data[rowIndex][columnIndex] =
                    value === '' ? null : value;
                }
              })
            )
          }
          onConfirm={(value, rowIndex, columnIndex) =>
            handleChange(
              produce(data, (draft) => {
                if (isNumber(rowIndex) && isNumber(columnIndex)) {
                  draft.data[rowIndex][columnIndex] =
                    value === '' ? null : value;
                }
              })
            )
          }
        />
      );
    }

    if (colType === 'float') {
      return (
        <EditableCell2
          truncated
          tooltip={value?.toString()}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          value={value?.toString()}
          onConfirm={(value, rowIndex, columnIndex) =>
            handleChange(
              produce(data, (draft) => {
                const stripped = value.replace(/[^\d.]/g, '');

                if (isNumber(rowIndex) && isNumber(columnIndex)) {
                  draft.data[rowIndex][columnIndex] =
                    value === '' || isNaN(parseFloat(stripped))
                      ? null
                      : parseFloat(stripped);
                }
              })
            )
          }
        />
      );
    }
    if (colType === 'int') {
      return (
        <EditableCell2
          truncated
          tooltip={value?.toString()}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          value={value?.toString()}
          onConfirm={(value, rowIndex, columnIndex) =>
            handleChange(
              produce(data, (draft) => {
                const stripped = value.replace(/[^\d.]/g, '');

                if (isNumber(rowIndex) && isNumber(columnIndex)) {
                  draft.data[rowIndex][columnIndex] =
                    value === '' || isNaN(parseInt(stripped))
                      ? null
                      : parseInt(stripped);
                }
              })
            )
          }
        />
      );
    }

    if (colType === 'str') {
      return (
        <EditableCell2
          truncated
          tooltip={value?.toString()}
          rowIndex={rowIndex}
          columnIndex={columnIndex}
          value={value?.toString()}
          onConfirm={(value, rowIndex, columnIndex) =>
            handleChange(
              produce(data, (draft) => {
                if (isNumber(rowIndex) && isNumber(columnIndex)) {
                  draft.data[rowIndex][columnIndex] =
                    value === '' ? null : value;
                }
              })
            )
          }
        />
      );
    }
    return <Cell>{value.toString()}</Cell>;
  };

  const renderColumnHeader = (columnIndex: number) => {
    const value = data.columns[columnIndex];

    return (
      <ColumnHeaderCell
        name={`${value}`}
        menuRenderer={() => (
          <div>
            <Button
              minimal
              text="Delete Column"
              intent="danger"
              onClick={() =>
                handleChange(
                  produce(data, (draft) => {
                    draft.columns.splice(columnIndex, 1);
                    draft.data.forEach((row) => {
                      row.splice(columnIndex, 1);
                    });

                    if (draft.columns.length === 0) {
                      draft.index = [];
                      draft.data = [];
                    }
                  })
                )
              }
            />
            <Select2
              popoverProps={{ isOpen: true }}
              items={availableColumns}
              itemListPredicate={(query, items) => {
                if (!query) {
                  return items;
                }

                const fuzzy = new Fuse(items);
                const result = fuzzy.search(query);
                return result.map((result) => result.item);
              }}
              itemRenderer={(colName, { handleClick, modifiers, index }) => {
                return (
                  <MenuItem2
                    key={`${colName}`}
                    text={`${colName}`}
                    onClick={handleClick}
                    active={modifiers.active}
                  />
                );
              }}
              onItemSelect={(colName) => {
                handleChange(
                  produce(data, (draft) => {
                    draft.columns.splice(columnIndex + 1, 0, colName);
                    draft.data.forEach((row) => {
                      row.splice(columnIndex + 1, 0, null);
                    });
                  })
                );
              }}
            />
          </div>
        )}
      />
    );
  };

  const renderRowHeader = (rowIndex: number) => (
    <RowHeaderCell
      menuRenderer={(index) => (
        <Menu>
          <MenuItem2
            text="Add Row"
            onClick={() => {
              handleChange(
                produce(data, (draft) => {
                  draft.data.splice(
                    rowIndex + 1,
                    0,
                    new Array(data.columns.length).fill(null)
                  );
                  draft.index.push(draft.index.length);
                })
              );
            }}
          />
          <MenuItem2
            text="Delete Row"
            intent="danger"
            onClick={() => {
              handleChange(
                produce(data, (draft) => {
                  if (isNumber(index)) {
                    draft.data.splice(index, 1);
                    draft.index.pop();

                    if (draft.index.length === 0) {
                      draft.data = [];
                      draft.columns = [];
                    }
                  }
                })
              );
            }}
          />
        </Menu>
      )}
      name={rowIndex.toString()}
    />
  );

  return data.columns.length <= 1 && data.data.length === 0 ? (
    <Select2
      items={availableColumns}
      itemListPredicate={(query, items) => {
        if (!query) {
          return items;
        }

        const fuzzy = new Fuse(items);
        const result = fuzzy.search(query);
        return result.map((result) => result.item);
      }}
      itemRenderer={(colName, { handleClick, modifiers }) => (
        <MenuItem2
          key={`${colName}`}
          text={colName}
          onClick={handleClick}
          active={modifiers.active}
        />
      )}
      onItemSelect={(colName) => {
        handleChange(
          produce(data, (draft) => {
            draft.columns.push(colName);
            draft.data.push([null]);
            draft.index.push(0);
          })
        );
      }}
    >
      <Button text="Add Column" />
    </Select2>
  ) : (
    <>
      <Table2
        numRows={data.index.length}
        defaultColumnWidth={250}
        rowHeaderCellRenderer={renderRowHeader}
        enableRowResizing={false}
      >
        {data.columns.map((colName, colIdx) => {
          return (
            <Column
              key={colIdx}
              cellRenderer={renderCell}
              columnHeaderCellRenderer={renderColumnHeader}
            />
          );
        })}
      </Table2>
      {showSubmitButton && (
        <Button text="Submit" onClick={() => onSubmit(data)} />
      )}
    </>
  );
};
export default IntentTableInput;
