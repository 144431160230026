import { Button } from '@blueprintjs/core';
import { MenuItem2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';
import Fuse from 'fuse.js';
import { FunctionComponent } from 'react';

import { useScopeConfigs } from '../state/taggingApiRepository';

interface AddClassificationButtonProps {
  scopeName: string;
  defaultTimezone?: string;
  onAddPartyProperty(propertyName: string): void;
  className?: string;
  minimal?: boolean;
  loading: boolean;
}

export const AddPartyPropertyButton: FunctionComponent<AddClassificationButtonProps> =
  ({ scopeName, onAddPartyProperty, className, minimal, loading }) => {
    const { data } = useScopeConfigs(scopeName);
    if (!data) return <></>;

    const { properties } = data;

    return (
      <Select2
        items={properties}
        itemListPredicate={(query, items) => {
          if (!query) {
            return items;
          }
          const options = {
            keys: [
              { name: 'name', weight: 0.8 },
              { name: 'description', weight: 0.2 },
            ],
          };
          const fuzzy = new Fuse(items, options);
          const result = fuzzy.search(query);
          return result.map((result) => result.item);
        }}
        itemRenderer={(property, { handleClick, modifiers }) => (
          <MenuItem2
            active={modifiers.active}
            key={property.name}
            label={
              property.description.length > 30
                ? property.description.slice(0, 27) + '...'
                : property.description
            }
            onClick={handleClick}
            text={property.name}
          />
        )}
        onItemSelect={(propertyOption) => {
          onAddPartyProperty(propertyOption.name);
        }}
        resetOnClose
      >
        <Button
          text="Add Property"
          icon="add"
          loading={loading}
          className={className}
          minimal={minimal}
        />
      </Select2>
    );
  };
