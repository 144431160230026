import { FunctionComponent, ReactNode } from 'react';

import { AlertIcon } from '../components/Icons/AlertIcon';
import { ConversationIcon } from '../components/Icons/ConversationIcon';

import { Checkbox } from './Checkbox/Checkbox';
import { Colors } from './Colors';
import { createToaster } from './Toast/Toaster';
import { ToasterPosition } from './Toast/ToasterPosition';
import { ToastType } from './Toast/ToastType';

const toasterPosition = ToasterPosition.Top;

const vanillaEliseToaster = createToaster({ position: toasterPosition });

export const appToaster = {
  clear: vanillaEliseToaster.clear.bind(vanillaEliseToaster),
  show: vanillaEliseToaster.show.bind(vanillaEliseToaster),

  /**
   * Display a success toast.
   */
  success: (description?: ReactNode, title = 'Success') => {
    vanillaEliseToaster.show({
      toastType: ToastType.Success,
      title,
      description,
      position: toasterPosition,
      icon: (
        <Checkbox
          isChecked
          checkedBorderColor={Colors.White}
          checkedFillColor={Colors.GoodGreen}
          checkmarkColor={Colors.White}
        />
      ),
    });
  },

  /**
   * Display an error to the screen temporarily.
   *
   * Good as a .catch() handler for promises, especially
   * user-initiated mutations.
   */
  toastError: (e: unknown, title = 'Error') => {
    vanillaEliseToaster.show({
      toastType: ToastType.Error,
      title,
      description: <ErrorMsg error={e} />,
      position: toasterPosition,
      icon: (
        <div>
          <AlertIcon isInverse />
        </div>
      ),
    });
  },
  /**
   * Display a warning to the screen temporarily.
   *
   */
  toastWarning: (description: string, title = 'Warning') => {
    vanillaEliseToaster.show({
      toastType: ToastType.Warning,
      title,
      description,
      position: toasterPosition,
      icon: (
        <div>
          <ConversationIcon isInverse />
        </div>
      ),
    });
  },
};

const ErrorMsg: FunctionComponent<{ error: any }> = ({ error: e }) => {
  const serverResponse = e?.response?.data;
  if (!serverResponse) return e?.message ?? 'An unknown error occurred.';
  const serverMessage = serverResponse?.message;

  return (
    <>
      <p className="m-0 mb-3">The server sent back the following message:</p>
      <code>{serverMessage ?? JSON.stringify(serverResponse, null, 2)}</code>
    </>
  );
};
