import { ButtonGroup, H5, Menu } from '@blueprintjs/core';

import PartyDTO from '../../../state-tagger/party/PartyDTO';
import PartyVM from '../../../state-tagger/party/PartyVM';
import UpdatePartyButton from '../../../state-tagger/party/UpdatePartyButton';

type PartyButtonGroupProps = {
  parties: PartyDTO[];
  scopeName: string;
  onUpdateParty?: (updatedParty: PartyDTO) => void;
  readOnly?: boolean;
  vertical?: boolean;
};

export default function PartyButtonGroup({
  parties,
  scopeName,
  onUpdateParty,
  readOnly,
  vertical,
}: PartyButtonGroupProps) {
  return (
    <Menu
      style={{ display: 'flex', flexDirection: vertical ? 'column' : 'row' }}
      className="mb-3  p-3"
    >
      <H5>Parties</H5>
      <ButtonGroup
        className="d-flex justify-center"
        alignText="center"
        minimal
        vertical={vertical}
      >
        {parties.map((party, i) => {
          const partyVm = PartyVM.fromDTO(party);
          return (
            <UpdatePartyButton
              key={party.name}
              readOnly={readOnly}
              scopeName={scopeName}
              party={partyVm}
              onUpdateParty={onUpdateParty}
            />
          );
        })}
      </ButtonGroup>
    </Menu>
  );
}
