import appToaster from '../../appToaster';

import { TimexItem } from './TimexDTO';

interface ClipboardTimex {
  timeItem: TimexItem;
  positive: boolean;
}

export const getClipboardTimex = async (): Promise<ClipboardTimex | null> => {
  try {
    return JSON.parse(await navigator.clipboard.readText());
  } catch {
    return null;
  }
};

export const hasClipboardTimex = async () => {
  return !!(await getClipboardTimex());
};

const toastInvalidTimex = () =>
  appToaster.toastError({ message: 'Clipboard does not contain a timex' });

export const readTimexFromClipboard = async () => {
  const clipboardTimex = await getClipboardTimex();
  if (!clipboardTimex) {
    toastInvalidTimex();
    return;
  }

  return clipboardTimex;
};
