import { Button, Checkbox, Dialog, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useState } from 'react';

import IntentTableInput, { IntentTableDTO } from './IntentTableInput';

const IntentTableInputDialogButton = ({
  tableIdx,
  initialData,
  onSubmit,
  submitOnChange,
  showSubmitButton,
}: {
  tableIdx: number;
  initialData: IntentTableDTO;
  onSubmit: (intent: IntentTableDTO) => void;
  submitOnChange: boolean;
  showSubmitButton?: boolean;
  tableClassName?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        text={`View {tables:${tableIdx}}`}
        onClick={() => setIsOpen(true)}
      />
      <Dialog
        title={`{tables:${tableIdx}}`}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        style={{ height: '90vh', width: '90vw' }}
      >
        <Tooltip2
          content={
            <div>
              <span>Checkboxes</span>
              <Checkbox label="True" checked={true} />
              <Checkbox label="False" checked={false} />
              <Checkbox label="N/A" indeterminate />
            </div>
          }
        >
          <Icon
            icon="help"
            style={{
              float: 'right',
              position: 'relative',
              right: 0,
              margin: '.5rem',
            }}
          />
        </Tooltip2>
        <IntentTableInput
          key={tableIdx}
          initialData={initialData}
          submitOnChange={submitOnChange}
          showSubmitButton={showSubmitButton}
          onSubmit={(data) => onSubmit(data)}
        />
      </Dialog>
    </>
  );
};

export default IntentTableInputDialogButton;
