/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TourTypeOption = {
    value: TourTypeOption.value;
    label: string;
}

export namespace TourTypeOption {

    export enum value {
        UNKNOWN = 'UNKNOWN',
        SELF_GUIDED = 'SELF_GUIDED',
        VIRTUAL_SHOWING = 'VIRTUAL_SHOWING',
        WITH_AGENT = 'WITH_AGENT',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
