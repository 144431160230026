/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RecurringEventCreateRequestDto = {
    calendarId?: number;
    startTime?: string;
    endTime?: string;
    daysOfWeek?: Array<number>;
    daysOfMonth?: Array<number>;
    startOfRecurrence?: string;
    endOfRecurrence?: string;
    frequency?: RecurringEventCreateRequestDto.frequency;
    frequencyMultiplier?: number;
    createdBy?: string;
    summary?: string;
}

export namespace RecurringEventCreateRequestDto {

    export enum frequency {
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
    }


}
