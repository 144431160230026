import { DateTime } from 'luxon';

import TimexVM from './TimexVM';

export default function TimexText({ timex }: { timex: TimexVM }) {
  return (
    <span>
      {timex.items
        .map((item) => {
          const maybeNot = item.positive ? '' : 'NOT AT ';
          const start = item.start
            .setZone(timex.timezone)
            .toLocaleString(
              timex.has_date ? DateTime.DATETIME_SHORT : DateTime.TIME_SIMPLE
            );
          const maybeEnd = item.end
            ?.setZone(timex.timezone)
            .toLocaleString(
              timex.has_date ? DateTime.DATETIME_SHORT : DateTime.TIME_SIMPLE
            );

          const momentOrRange = [start, maybeEnd].filter(Boolean).join(' – '); // en-dash

          return maybeNot + momentOrRange;
        })
        .join(', ')}
    </span>
  );
}
