import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';

import { useRefWithCallback } from '../../vanillaelise/useRefWithCallback';

import { VanillaScssHeader } from './VanillaScssHeader';
import { VanillaScssNavBar } from './VanillaScssNavBar';
import styles from './VanillaScssPage.module.scss';

export interface VanillaScssPageProps {
  /**
   * Whether the nav bar should be shown
   */
  showNav?: boolean;
  /**
   * Content to put in the header
   */
  headerContent?: React.ReactNode;
  /**
   * Content to place beneath the header
   */
  children?: React.ReactNode;
  /**
   * Whether the content in the content
   * section should scroll or extend past
   * screen's limits
   */
  scrollContent?: boolean;
  /**
   * Class name to apply to the entire
   * page
   */
  pageClassName?: string;
  /**
   * Class name to apply to the content section
   */
  contentClassName?: string;
  /**
   * Whether the content of the page
   * has been shimmed into this new layout
   */
  isLegacyPage?: boolean;
  /**
   * Whether the page should be able to accept arbitrary
   * drop targets anywhere on the page
   *
   * @default false
   */
  acceptArbitraryDropTargets?: boolean;
  /**
   * Whether the page is internal-only rather than customer-facing
   *
   * @default false
   */
  isInternalPage?: boolean;
}

export default function VanillaScssPage({
  showNav = true,
  headerContent,
  children,
  scrollContent = true,
  pageClassName,
  contentClassName,
  isLegacyPage = false,
  acceptArbitraryDropTargets = false,
  isInternalPage = false,
}: VanillaScssPageProps) {
  const [pageContentStyles, setPageContentStyles] = useState({});
  const [childrenStyles, setChildrenStyles] = useState({});
  const navBarRefCallback = useCallback((node: HTMLDivElement) => {
    const maxWidth = window.innerWidth - node.clientWidth;
    setPageContentStyles((prev) => ({
      ...prev,
      maxWidth: maxWidth > 0 ? maxWidth : undefined,
    }));
  }, []);
  const headerRefCallback = useCallback((node: HTMLDivElement) => {
    const maxHeight = window.innerHeight - node.clientHeight;
    setChildrenStyles((prev) => ({
      ...prev,
      maxHeight: maxHeight > 400 ? maxHeight : undefined, // Arbitrarily decided number. Based it on phone screen sizes
    }));
  }, []);
  const { ref: navBarRef, setRef: setNavBarRef } =
    useRefWithCallback<HTMLDivElement>(navBarRefCallback);
  const { ref: headerRef, setRef: setHeaderRef } =
    useRefWithCallback<HTMLDivElement>(headerRefCallback);

  const onWindowResize = useCallback(() => {
    if (navBarRef.current) {
      const maxWidth = window.innerWidth - navBarRef.current.clientWidth;
      setPageContentStyles((prev) => ({
        ...prev,
        maxWidth: maxWidth > 0 ? maxWidth : undefined,
      }));
    }
    if (headerRef.current) {
      const maxHeight = window.innerHeight - headerRef.current.clientHeight;
      setChildrenStyles({
        maxHeight: maxHeight > 400 ? maxHeight : undefined, // Arbitrarily decided number. Based it on phone screen sizes
      });
    }
  }, [headerRef, navBarRef]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  return (
    <div className={clsx(styles.page, pageClassName)}>
      {showNav && (
        <div ref={setNavBarRef}>
          <VanillaScssNavBar />
        </div>
      )}
      <div
        className={clsx(styles.pageContent, styles.hasNavBar)}
        style={pageContentStyles}
      >
        <div ref={setHeaderRef}>
          <VanillaScssHeader
            content={headerContent}
            isInternalPage={isInternalPage}
          />
        </div>
        <div
          onDragOver={(e) => {
            if (!acceptArbitraryDropTargets) {
              return;
            }
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            if (!acceptArbitraryDropTargets) {
              return;
            }
            e.preventDefault();
          }}
          className={clsx(
            styles.children,
            {
              [styles.scrollableContent]: scrollContent,
              [styles.legacyContent]: isLegacyPage,
            },
            contentClassName
          )}
          style={childrenStyles}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
