/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { XagacityUserWithCredentials } from '../models/XagacityUserWithCredentials';
import { request as __request } from '../core/request';

export class XagacityUserControllerService {

    /**
     * @param id
     * @returns XagacityUserWithCredentials OK
     * @throws ApiError
     */
    public static async getOne(
        id: number,
    ): Promise<XagacityUserWithCredentials> {
        const result = await __request({
            method: 'GET',
            path: `/api/v2/user/${id}`,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns XagacityUserWithCredentials OK
     * @throws ApiError
     */
    public static async update(
        id: number,
        requestBody: XagacityUserWithCredentials,
    ): Promise<XagacityUserWithCredentials> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v2/user/${id}`,
            body: requestBody,
        });
        return result.body;
    }

}