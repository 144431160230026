import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
let handlers: Function[] = [];

/**
 * @deprecated use `@mantine/hooks#useClickOutside` instead.
 *
 * @see https://mantine.dev/hooks/use-click-outside
 */
export const useOnOutsideClick = (
  onOutsideClickHandler: (event: Event) => void
) => {
  useEffect(() => {
    // Bind the event listener
    handlers.push(onOutsideClickHandler);
    document.onmousedown = (ev) => {
      handlers.forEach((h) => h(ev));
    };
    return () => {
      // Unbind the event listener on clean up
      handlers = handlers.filter((h) => h !== onOutsideClickHandler);
      document.onclick = (ev) => {
        handlers.forEach((h) => h(ev));
      };
    };
  }, [onOutsideClickHandler]);
};
