import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { assertString } from '../assert';

/**
 * Auth0 provider, configured for use with react-router, Datadog, and LogRocket.
 *
 * Because this uses `useHistory()`, it must be rendered _inside_ a
 * react-router context.
 */
const AuthProvider: FC = ({ children }) => {
  const history = useHistory();
  const onRedirectCallback = useCallback(
    (appState: AppState) => {
      history.replace(appState?.returnTo || window.location.pathname);
    },
    [history]
  );

  assertString(process.env.REACT_APP_OAUTH_DOMAIN);
  assertString(process.env.REACT_APP_OAUTH_CLIENT_ID);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_OAUTH_DOMAIN}
      clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_OAUTH_AUDIENCE}
      issuer={process.env.REACT_APP_OAUTH_ISSUER}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
