import { NonIdealState, Spinner } from '@blueprintjs/core';

import BlueprintPage from './BlueprintPage';

export default function LoadingPage({ title = 'Loading...', showNav = true }) {
  return (
    <BlueprintPage showNav={showNav}>
      <NonIdealState title={title} icon={<Spinner />} />
    </BlueprintPage>
  );
}
