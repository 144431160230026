/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AlternativeILSAddressGeoFormat } from './AlternativeILSAddressGeoFormat';

export type BuildingDetails = {
    id?: number;
    managementCompanyId?: number;
    organizationId?: number;
    active?: number;
    handoffForwardToEmails?: Array<string>;
    responseCcEmails?: Array<string>;
    responseBccEmails?: Array<string>;
    associateCalendarId?: number;
    buildingName?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    alternativeILSAddresses?: Array<string>;
    city?: string;
    state?: string;
    postalCode?: string;
    crossStreets?: string;
    neighborhood?: string;
    nearbyTransportation?: string;
    buildingOwnership?: number;
    buildingType?: number;
    totalUnits?: number;
    totalFloors?: number;
    yearBuilt?: number;
    block?: number;
    lot?: number;
    schoolDistrict?: string;
    communityDistrict?: number;
    newConstruction?: boolean;
    preWarBuilding?: boolean;
    brownstone?: boolean;
    greenBuilding?: boolean;
    elevator?: boolean;
    freightElevator?: boolean;
    parking?: number;
    petPolicy?: string;
    petNumberLimit?: string;
    petWeightLimit?: string;
    petFeeOrDeposit?: string;
    utilitiesIncluded?: string;
    utilitiesNotIncluded?: string;
    leaseTermsAccepted?: string;
    minLeaseTermAccepted?: number;
    furnishedOffered?: boolean;
    furnishedUnitsPolicy?: string;
    internationalStudentRequirements?: string;
    amenitiesFee?: string;
    nonSmoking?: string;
    piedATerreAllowed?: boolean;
    diplomatsOk?: boolean;
    corporateLeasesAccepted?: boolean;
    shortTermAllowed?: boolean;
    doorman?: number;
    intercom?: boolean;
    liveInSuper?: boolean;
    concierge?: string;
    laundryInBuilding?: boolean;
    packageReceiving?: boolean;
    gym?: boolean;
    storage?: boolean;
    bicycleRoom?: boolean;
    lounge?: boolean;
    clubHouse?: boolean;
    businessCenter?: boolean;
    outdoorSpace?: string;
    roofDeck?: boolean;
    courtyard?: boolean;
    childrenPlayroom?: boolean;
    nursery?: boolean;
    maidService?: boolean;
    pool?: boolean;
    wheelchairAccess?: boolean;
    guarantorTypesAccepted?: string;
    thirdPartyGuarantorTypesAccepted?: string;
    noCreditQualificationAlternative?: string;
    securityDepositText?: string;
    flexWallPolicy?: string;
    leaseholderApplicationFee?: number;
    occupantApplicationFee?: number;
    guarantorApplicationFee?: number;
    tenantIncomeRequirementRentMultiplier?: number;
    guarantorIncomeRequirementRentMultiplier?: number;
    incomeRequirementRentMultiplierTimePeriod?: number;
    leaseholderMinimumCreditScoreRequirement?: string;
    guarantorMinimumCreditScoreRequirement?: string;
    leaseholderApplicationDocumentsRequired?: string;
    selfEmployedApplicationDocumentsRequired?: string;
    guarantorApplicationDocumentsRequired?: string;
    guarantorsAccepted?: string;
    financialsInsufficientAlternative?: string;
    prepaymentPolicy?: string;
    rentRequiredOnLeaseSigning?: string;
    securityDepositRentMultiplier?: number;
    paymentFormsAccepted?: string;
    clientEmail?: string;
    description?: string;
    notes?: string;
    leasingOfficeHours?: string;
    occupiedUnitsShowingHours?: string;
    internalNotes?: string;
    leasingPhoneNumber?: string;
    callCenterNumber?: string;
    buildingWebsiteUrl?: string;
    applicationLink?: string;
    geoAddress?: string;
    googleMapsUrl?: string;
    alerts?: string;
    rentComputeType?: number;
    autosendActive?: number;
    advancedMoveInAllowenceInDays?: number;
    alternativeBuildingNames?: Array<string>;
    externalIds?: Array<string>;
    externalCrmId?: string;
    defaultAvailabilityQuoteMatchMoveInDate?: number;
    leaseRenewalNoticeInDays?: number;
    rentQuoteFormat?: string;
    rentWithIncentivesQuoteFormat?: string;
    isPartOfComplex?: number;
    alternativeILSAddressGeoFormat?: AlternativeILSAddressGeoFormat;
    effectiveRentComputeType?: number;
    maxLeaseTermAccepted?: number;
    parkingInformation?: string;
    storageFee?: string;
    incentivesProperlyConnected?: number;
    electricCompany?: string;
    gasCompany?: string;
    telephoneCompany?: string;
    televisionCompany?: string;
    waterCompany?: string;
    internetCompany?: string;
    localHospital?: string;
    localSchoolDistrict?: string;
    localElementarySchool?: string;
    localMiddleSchool?: string;
    localHighSchool?: string;
    localShoppingCenter?: string;
    localGroceryStore?: string;
    closestInterstates?: string;
    buildingPicturesUrl?: string;
    buildingFloorPlansUrl?: string;
    nonexistentLayouts?: Array<number>;
    offeredLayouts?: Array<number>;
    avoidQuotingPrices?: number;
    excludeNotRentReadyUnit?: number;
    flexWallsAllowed?: boolean;
    petsAccepted?: boolean;
    guarantorsAcceptedSwitch?: boolean;
    thirdPartyGuarantorsAccepted?: boolean;
    alwaysScheduleAppointments?: boolean;
    leasingOfficeOnsite?: boolean;
    building3dMediaUrl?: string;
    dailyreport?: number;
    dailyReportEmails?: Array<string>;
    nearbySubways?: string;
    bedroomTypes?: Array<string>;
    leasingBrochureUrl?: string;
    confidenceVariable?: number;
    textForwardToEmails?: Array<string>;
    synchronizationStatus?: number;
    omitDesiredFloorPlan?: boolean;
    pickingNewGuestCards?: boolean;
    showNotRespondingLeadsInReport?: boolean;
    leasingOfficeSetupType?: BuildingDetails.leasingOfficeSetupType;
    firstContactReasonRequired?: boolean;
    disableDynamicTto?: boolean;
    languageTranslationAllowed?: boolean;
    firstContactReason?: string;
    emailSignature?: string;
    tourDuration?: string;
    inPersonToursAllowed?: boolean;
    selfGuidedToursEnabled?: boolean;
    virtualTourType?: BuildingDetails.virtualTourType;
    virtualTourPlatforms?: string;
    noUnitSelfGuidedTourAllowed?: boolean;
    sgSchedulingBasedOnNormalAppointments?: boolean;
    tourTypePriority?: Array<'UNKNOWN' | 'SELF_GUIDED' | 'VIRTUAL_SHOWING' | 'WITH_AGENT' | 'MEDIA_TOUR'>;
    tourNotificationEmailsMode?: BuildingDetails.tourNotificationEmailsMode;
    showByOpenHouseOnly?: number;
    showUnitsWithPendingApplication?: number;
    showOccupiedUnitsWithPendingApplication?: number;
    confirmAppointmentsOccupiedUnits?: number;
    confirmAppointmentsRenovatingUnits?: number;
    occupiedUnitsAdvancedNoticeInHours?: number;
    prioritizeShowingVacantUnits?: number;
    showOccupiedUnits?: boolean;
    whiteListActivated?: boolean;
    scheduleToursWithNoViewableUnits?: number;
    appointmentMeetingLocation?: string;
    appointmentMeetingPointSpecific?: string;
    appointmentDirections?: string;
    appointmentAdditionalDirections?: string;
    launchDateTime?: string;
    clientRequiresShowEvent?: boolean;
    tempLeasingOfficeHoursAsJson?: string;
    effectiveHandoffForwardToEmails?: Array<string>;
    calendarId?: number;
}

export namespace BuildingDetails {

    export enum leasingOfficeSetupType {
        SINGLE_BUILDING = 'SINGLE_BUILDING',
        CENTRAL_LEASING_OFFICE_AS_SINGLE_BUILDING = 'CENTRAL_LEASING_OFFICE_AS_SINGLE_BUILDING',
    }

    export enum virtualTourType {
        NOT_ENABLED = 'NOT_ENABLED',
        SELF_MANAGED = 'SELF_MANAGED',
        WHEREBY = 'WHEREBY',
    }

    export enum tourNotificationEmailsMode {
        NO_NOTIFICATION = 'NO_NOTIFICATION',
        ALL_VIEWINGS = 'ALL_VIEWINGS',
        SAME_DAY_VIEWINGS = 'SAME_DAY_VIEWINGS',
    }


}
