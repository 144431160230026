import { createContext, useContext, useReducer } from 'react';

import StateDTO from '../state-tagger/state/StateDTO';

import {
  defaultState,
  defaultStateContextRegistry,
  StateContextActionRegistry,
  StateContextType,
} from './stateContextTypes';

type Props = {
  initialState: StateDTO;
  children: JSX.Element;
};

const StateContext = createContext<StateContextType>({
  state: defaultState,
  actions: defaultStateContextRegistry,
});

export function StateContextProvider({ initialState, children }: Props) {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  function stateReducer(
    state: StateDTO,
    action: { [key: string]: any }
  ): StateDTO {
    switch (action.type) {
      case 'UPDATE_ID':
        return { ...state, id: action.newStateId };
      default:
        return state;
    }
  }

  // Example of dispatch wrapper
  const updateStateId = (newStateId: string) => {
    dispatch({ type: 'UPDATE_ID', newStateId: newStateId });
  };

  // Wrap dispatcher in defined functions for ease of use
  const actions: StateContextActionRegistry = {
    updateStateId,
  };

  return (
    <StateContext.Provider value={{ state, actions }}>
      {children}
    </StateContext.Provider>
  );
}

export const useStateContext = () => useContext(StateContext);
