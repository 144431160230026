import Permission from './Permission';

// Namespace for custom OIDC claims
const ns = 'https://meetelise.com/';

/**
 * The logged-in user.
 *
 * Implementation note: Generally, object-oriented programming isn't
 * necessary with TypeScript, but the `class` keyword is used here for
 * an easy way to construct an `AuthUser` from an
 * {@link https://auth0.com/docs/protocols/openid-connect-protocol Auth0-issued OIDC Token}
 * as well as define the `hasPermission()` convenience method on `AuthUser`.
 */
export default class AuthUser {
  id: string;
  name: string;
  nickname: string;
  pictureURL: string;
  email: string;
  emailVerified: boolean;

  /**
   * Communities a user has access to, if they also have the read:building
   * permission. If they have the read:buildings permission (or corresponding
   * CRUD permissions), they can access any building in their org.
   */
  communityIds: number[];

  /**
   * Organization a user has access to, if they have the read:org permission.
   * If they have the read:orgs permission (or corresponding CRUD permissions),
   * they can access any organization in MeetElise.
   */
  organizationId: number;

  /**
   * Permissions a user has assigned.
   *
   * {@link https://manage.auth0.com/dashboard/us/meetelise/apis/6036b32e56aca50040fd6085/permissions The full list} is maintained in Auth0.
   */
  permissions: Permission[];

  /**
   * Check if the user has a permission.
   *
   * @param permission The permission to test for
   * @returns Whether or not the user has the permission
   */
  hasPermission(permission: Permission): boolean {
    return this.permissions.includes(permission);
  }

  /**
   * Whether or not a user is a member of MeetElise.
   *
   * True if a user has `read:orgs` permission.
   */
  isInternal: boolean;

  /**
   * Create an AuthUser from the ID token returned by auth0.
   *
   * @param auth0User The ID token from auth0.
   */
  constructor(auth0User: any) {
    this.hasPermission = this.hasPermission.bind(this);

    this.id = auth0User.sub;
    this.email = auth0User.email;
    this.emailVerified = auth0User.email_verified;
    this.name = auth0User.name;
    this.nickname = auth0User.nickname;
    this.pictureURL = auth0User.picture;

    // Give namespaced claims friendlier property names.
    this.organizationId = auth0User[`${ns}org_id`];
    this.communityIds = auth0User[`${ns}buildings`] ?? [];
    this.permissions = auth0User[`${ns}permissions`] ?? [];

    this.isInternal = this.hasPermission(Permission.READ_ORGS);
  }
}
