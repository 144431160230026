export enum Scope {
  LeasingWebchat = 'leasing_webchat',
  LeasingSms = 'leasing_sms',
  LeasingEmail = 'leasing_email',
  LeasingEmailDev = 'leasing_email_dev',
  LeasingSmsDev = 'leasing_sms_dev',
  LeasingWebchatDev = 'leasing_webchat_dev',
}

export const getScopeFromString = (scope: string) => {
  switch (scope) {
    case Scope.LeasingSms: {
      return Scope.LeasingSms;
    }
    case Scope.LeasingWebchat: {
      return Scope.LeasingWebchat;
    }
    case Scope.LeasingEmail: {
      return Scope.LeasingEmail;
    }
    case Scope.LeasingEmailDev: {
      return Scope.LeasingEmailDev;
    }
    case Scope.LeasingSmsDev: {
      return Scope.LeasingSmsDev;
    }
    case Scope.LeasingWebchatDev: {
      return Scope.LeasingWebchatDev;
    }
    default: {
      throw new Error(`${scope} is not a recognized scope`);
    }
  }
};
