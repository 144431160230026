/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AvailabilitiesPreferences } from './AvailabilitiesPreferences';
import type { BuildingRefreshInfo } from './BuildingRefreshInfo';

export type XagacityUser = {
    id?: number;
    organizationId?: number;
    clientEmail?: string;
    priority?: number;
    firstName?: string;
    active?: boolean;
    eliseUser?: boolean;
    payingOrTrial?: boolean;
    emailPushNotificationsActive?: boolean;
    emailPushNotificationsExpiration?: number;
    emailClientType?: string;
    textApi?: string;
    timezone?: string;
    outlookTimeOfLastSyncedMessage?: string;
    outlookIdOfLastSyncedMessage?: string;
    xagacityLabel?: string;
    unreadState?: number;
    agentSignature?: string;
    specificGuidlines?: string;
    templates?: string;
    agentPhoneNumber?: string;
    isItProcessed?: number;
    removeFromInbox?: number;
    draftReply?: number;
    forwardToTeamAgent?: number;
    gmailHistoryId?: string;
    collectDataOn?: number;
    actualAssistant?: number;
    lastName?: string;
    subjectPreference?: string;
    getgSheetId?: string;
    autoDrafting?: number;
    followUpsRequired?: number;
    followUpsTemplates?: string;
    lockedSheet?: number;
    smartResponding?: number;
    pendingFollowUps?: number;
    xagacityNumber?: string;
    password?: string;
    calendarHistoryId?: string;
    calendarName?: string;
    hasSalesforce?: boolean;
    dateActive?: string;
    leadTrackingEmailForwardingActive?: number;
    receivesForwardedEmails?: number;
    messageDelayInSeconds?: number;
    crmUsedType?: number;
    relatedEmailIds?: Array<number>;
    callsStartingHours?: string;
    callsClosingHours?: string;
    availabilitiesSoftwareType?: number;
    associatedDomains?: Array<string>;
    lastCrmSyncId?: string;
    crmSyncType?: number;
    importingNewLeadsFromCrmActive?: number;
    followUpDataSource?: number;
    prefersTextsConfirmations?: number;
    smsAppointmentReminderEnabled?: number;
    smsPreTourFollowUpEnabled?: number;
    smsPostTourFollowUpEnabled?: number;
    smsNoShowFollowUpEnabled?: number;
    eventsLastCrmSyncId?: string;
    whiteListActivated?: number;
    blackListActivated?: number;
    availabilitiesPreferences?: AvailabilitiesPreferences;
    qaAlertPriority?: number;
    useSmtpRelay?: boolean;
    needParseSignature?: boolean;
    signaturePattern?: string;
    spamHistoryId?: string;
    receivesUnsubscribeReport?: boolean;
    emailSyncState?: number;
    buildingRefreshInfo?: BuildingRefreshInfo;
    idVerificationProvider?: XagacityUser.idVerificationProvider;
}

export namespace XagacityUser {

    export enum idVerificationProvider {
        UNKNOWN = 'UNKNOWN',
        COGNITO_HQ = 'COGNITO_HQ',
        AVB_SCREENING_API = 'AVB_SCREENING_API',
    }


}
