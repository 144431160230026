/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConversationInformationDto } from './ConversationInformationDto';
import type { LeadInformationDto } from './LeadInformationDto';
import type { UpdateDto } from './UpdateDto';

export type EventsAPIRequest = {
    type?: string;
    updates?: UpdateDto;
    multi_conversation_id?: string;
    guest_card_id?: string;
    pms_name?: EventsAPIRequest.pms_name;
    time_sent?: string;
    lead_information?: LeadInformationDto;
    conversation_information?: ConversationInformationDto;
}

export namespace EventsAPIRequest {

    export enum pms_name {
        YARDI = 'Yardi',
        EQR = 'EQR',
        REAL_PAGE = 'RealPage',
        AVBSALESFORCE = 'AVBSalesforce',
        KNOCK = 'Knock',
        ENTRATA = 'Entrata',
        ANYONE_HOME = 'AnyoneHome',
        FUNNEL = 'Funnel',
        RES_MAN = 'ResMan',
    }


}
