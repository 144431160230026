// TODO remove blueprint from main bundle
// https://meetelise.atlassian.net/browse/REX-424
import { Callout, Intent } from '@blueprintjs/core';

import {
  VanillaScssButton,
  VanillaScssButtonType,
} from '../components/VanillaScssButton/VanillaScssButton';
import { VanillaScssErrorPage } from '../layouts/VanillaScssPage/VanillaScssErrorPage';

export function AppErrorPage({ error }: { error: Error }) {
  return (
    <VanillaScssErrorPage
      title="Sorry, the following error occurred:"
      description={
        <>
          <Callout intent={Intent.WARNING}>{error.message}</Callout>
          <p>
            The error details have been reported to MeetElise and will be
            investigated. Try refreshing the page.
          </p>
        </>
      }
      action={
        <VanillaScssButton
          text="Reload Page"
          onClick={() => window.location.reload()}
          buttonType={VanillaScssButtonType.Primary}
        />
      }
    />
  );
}
