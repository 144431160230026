import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';

import styles from './Tab.module.scss';

export interface TabProps {
  /**
   * Class name to provide custom styles to the component
   */
  className?: string;
  /**
   * The component to be rendered when the
   * given tab is selected
   */
  component: ReactElement;
  children?: React.ReactNode;

  /**
   * Whether the tab is disabled.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Unique identifier used to control which tab is selected
   * and to generate ARIA attributes for accessibility.
   */
  id: string | number;

  /**
   * Content of tab title element, rendered in a list above the active panel.
   * Can also be set via React `children`.
   */
  title: React.ReactNode;
}

export const Tab: FunctionComponent<TabProps> = ({ className, component }) => {
  return <div className={getTabClassNames(className)}>{component}</div>;
};

Tab.displayName = 'MeetElise.Tab';

const getTabClassNames = (className?: string) => clsx(styles.tab, className);
