import axios from 'axios';
import { QueryConfig, useQuery } from 'react-query';

export const useEnabledConversationPageBuildings = (
  opts?: QueryConfig<{ orgIds: number[]; buildingIds: number[] }, unknown>
) =>
  useQuery(
    ['useEnabledConversationPageBuildings'],
    async () => await getEnabledConersationPageBuildings()
  );

export const getEnabledConersationPageBuildings = async () => {
  const launchDarklyUrl = `https://app.launchdarkly.com/api/v2/flags/default/conversation-page`;
  const result = await axios.get<{
    environments: {
      production: {
        rules: {
          clauses: {
            attribute: string;
            values: number[];
          };
        }[];
      };
    };
  }>(launchDarklyUrl, {
    headers: { Authorization: process.env.REACT_APP_LAUNCH_DARKLY_API_KEY },
  });
  const rulesClauses = result.data.environments.production.rules.flatMap(
    (r) => r.clauses
  );

  const buildingIds =
    rulesClauses.find((c) => c.attribute === 'buildingId')?.values ?? [];

  const orgIds =
    rulesClauses.find((c) => c.attribute === 'orgId')?.values ?? [];

  return {
    orgIds,
    buildingIds,
  };
};
