/**
 * Collection of utilities for conversation objects. Primarily for the move away from conversation object view models
 * Many of the abstractions from the view model are made explicit in these utilities
 */

import { getName } from '../../calendarV2/getName';
import PartyDTO from '../../state-tagger/party/PartyDTO';
import { PartyPropertyDTO } from '../../state-tagger/party/PartyPropertyDTO';

/**
 * Event Object Utils
 */

/**
 * Party Object Utils
 */

export const getInitials = (party: PartyDTO) => {
  const defaultInitial = isLead(party) ? 'P' : 'S';
  const firstName = getFirstName(party);
  const lastName = getLastName(party);
  if (!getFirstName(party) && !getLastName(party)) {
    return getEmail(party)?.charAt(0) ?? defaultInitial;
  }

  return getName(firstName, lastName)
    .split(' ')
    .map((n) => n.replaceAll(/#/g, '').charAt(0))
    .join('');
};

export const isFromElise = (partyName?: string | null): boolean =>
  !!(partyName && partyName.startsWith('elise_'));

export const isLead = (party: PartyDTO | string): boolean => {
  if (typeof party === 'string') {
    return party.startsWith('lead');
  }
  return party.name.startsWith('lead');
};

export const findParty = (parties: PartyDTO[], partyType: string) => {
  return parties.find((party) => party.name.startsWith(partyType));
};

// Find a property in the properties list, remove it and return
// it if it exists.
export const pluckPartyProperty = (
  propertyName: string,
  propertyList: Array<PartyPropertyDTO>
) => {
  const key = `property-${propertyName}`;
  const index = propertyList.findIndex((p) => p.name === key);
  if (index === -1) return undefined;
  return propertyList.splice(index, 1)[0].text;
};

// Retrieves a value of a given party property
export const getProperty = (party: PartyDTO, propName: string) => {
  return party.properties.find((p) => p.name === `property-${propName}`)?.text;
};
export const getTextsProperty = (party: PartyDTO, propName: string) => {
  const property = party.properties.find(
    (p) => p.name === `property-${propName}`
  );
  return property?.texts;
};
export const getFirstName = (party: PartyDTO) =>
  getTextsProperty(party, 'name')?.[0];
export const getLastName = (party: PartyDTO) =>
  getTextsProperty(party, 'name')?.[1];
export const getPhoneNumber = (party: PartyDTO) =>
  getProperty(party, 'phone-number');
export const getEmail = (party: PartyDTO) =>
  getProperty(party, 'email-address');

export const getDisplayName = (party: PartyDTO) => {
  return getFirstName(party) || getLastName(party)
    ? [getFirstName(party), getLastName(party)].join(' ')
    : getEmail(party) || party.name;
};

// Retrieves a value of a given party property
export const getPropertyFromPartyProperties = (
  properties: PartyPropertyDTO[],
  propName: string
) => {
  return properties.find((p) => p.name === `property-${propName}`)?.text;
};
export const getTextsFromPartyProperties = (
  properties: PartyPropertyDTO[],
  propName: string
) => {
  const property = properties.find((p) => p.name === `property-${propName}`);
  return property?.texts;
};
export const getFirstNameFromPartyProperties = (
  properties: PartyPropertyDTO[]
) => getTextsFromPartyProperties(properties, 'name')?.[0];
export const getLastNameFromPartyProperties = (
  properties: PartyPropertyDTO[]
) => getTextsFromPartyProperties(properties, 'name')?.[1];
export const getPhoneNumberFromPartyProperties = (
  properties: PartyPropertyDTO[]
) => getPropertyFromPartyProperties(properties, 'phone-number');
export const getEmailFromPartyProperties = (properties: PartyPropertyDTO[]) =>
  getPropertyFromPartyProperties(properties, 'email-address');

export const getDisplayNameFromPartyProperties = (
  properties: PartyPropertyDTO[],
  fallbackName: string
) => {
  return getFirstNameFromPartyProperties(properties) ||
    getLastNameFromPartyProperties(properties)
    ? [
        getFirstNameFromPartyProperties(properties),
        getLastNameFromPartyProperties(properties),
      ].join(' ')
    : getEmailFromPartyProperties(properties) || fallbackName;
};
