import { FunctionComponent } from 'react';

import DefaultLoadingIndicator from './horizontal-loading-indicator-default.gif';
import InvertedLoadingIndicator from './horizontal-loading-indicator-inverted.gif';

export const HorizontalLoadingIndicator: FunctionComponent<{
  isInverted?: boolean;
  className?: string;
  style?: {};
}> = ({ isInverted = false, className, style }) =>
  isInverted ? (
    <img
      className={className}
      src={InvertedLoadingIndicator}
      alt="loading indicator"
      style={style}
    />
  ) : (
    <img
      style={style}
      className={className}
      src={DefaultLoadingIndicator}
      alt="loading indicator"
    />
  );
