import { QueryConfig, useQuery } from 'react-query';

import api from '../../api/axios';

export const useRelatedEntityIds = (
  buildingPartyId: string,
  opts?: QueryConfig<string[], unknown>
) =>
  useQuery(
    ['useRelatedEntityIds', buildingPartyId],
    async () => {
      const response = await api.get<string[]>(
        `../../platformApi/entity/${buildingPartyId}/related_entity_ids`
      );
      return response.data;
    },
    opts
  );
