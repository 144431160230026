import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@blueprintjs/core';
import { ComponentType, lazy, useCallback, useEffect } from 'react';

import ErrorPage from '../layouts/ErrorPage';
import LoadingPage from '../layouts/LoadingPage';
import { useQueryParams } from '../useQueryParams';

import { useMaybeUser } from './hooks';
import log from './log';
import Permission from './Permission';

const NotFoundPage = lazy(() => import('../layouts/NotFoundPage'));

/**
 * An HOC for authenticated pages.
 *
 * When passed a permission, and then a component, restricts users
 * to that permission.
 *
 * Curries, as is conventional with HOCs.
 *
 * Developer Note: This is implemented as an HOC, because it needs to
 * conditionally render something else entirely, which hooks can't do.
 */
export const withPermission = (permission?: Permission) =>
  withAnyPermission(permission ? [permission] : undefined);

/**
 * An HOC for authenticated pages.
 *
 * When passed a set of permissions, and then a component, prevents
 * users without any of the specified permissions from seeing the page.
 *
 * Curries, as is conventional with HOCs.
 *
 * Developer Note: This is implemented as an HOC, because it needs to
 * conditionally render something else entirely, which hooks can't do.
 */
export const withAnyPermission =
  (permissions?: Permission[]) => (Page: ComponentType) => {
    function Component(props: any) {
      const { loginWithRedirect, isLoading, error } = useAuth0();
      const user = useMaybeUser();
      const queryParams = useQueryParams();

      const login = useCallback(() => {
        loginWithRedirect({
          appState: {
            returnTo: `${window.location.pathname}?${queryParams.toString()}`,
          },
        });
      }, [loginWithRedirect, queryParams]);

      useEffect(() => {
        if (!user && !error && !isLoading) {
          log(`No user found, redirecting to login`);
          login();
        }
      }, [user, error, isLoading, login]);

      if (isLoading) return <LoadingPage title="Loading account info..." />;

      if (error) {
        return (
          <ErrorPage
            title="Failed to load user info"
            description={error?.message || 'An unknown error occurred'}
            action={<Button text="Retry" onClick={login} />}
          />
        );
      }

      if (!user) return <LoadingPage />; // Will redirect
      if (
        permissions &&
        permissions.filter((p) => user.hasPermission(p)).length === 0
      )
        return <NotFoundPage />;

      return <Page {...props} />;
    }
    Component.displayName = `withPageAuth(${getDisplayName(Page)})`;
    return Component;
  };

function getDisplayName(Component: ComponentType) {
  return Component.displayName || Component.name || 'Component';
}

export const withAdmin = withPermission(Permission.UPDATE_ORGS);
export const withInternal = withPermission(Permission.READ_ORGS);
export default withPermission();
