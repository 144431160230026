import clsx from 'clsx';
import { FunctionComponent } from 'react';

import styles from './NonIdealState.module.scss';

export interface NonIdealStateProps {
  /** An action to resolve the non-ideal state which appears after `description`. */
  action?: React.ReactNode;

  /**
   * A longer description of the non-ideal state.
   * A string or number value will be wrapped in a `<div>` to preserve margins.
   */
  description?: React.ReactNode;

  /** A ReactNode (such as `<LoadingIndicator />`) to render above the title. */
  attentionGrabber?: React.ReactNode;

  /** The title of the non-ideal state. */
  title?: React.ReactNode;

  className?: string;
}

export const NonIdealState: FunctionComponent<NonIdealStateProps> = ({
  action,
  description,
  attentionGrabber,
  title,
  className,
  children,
}) => (
  <div className={clsx(styles.nonIdealState, className)}>
    {attentionGrabber && (
      <div className={styles.attentionGrabber}>{attentionGrabber}</div>
    )}
    {title && <h4 className={styles.header}>{title}</h4>}
    {description && description}
    {action}
    {children}
  </div>
);
