import { Tag } from '@blueprintjs/core';

import ClassificationDTO from './ClassificationDTO';
import ClassificationMultiSelect from './ClassificationMultiSelect';
import ClassificationSingleSelect from './ClassificationSingleSelect';
import ClassificationTimexSelect from './ClassificationTimexSelect';
import ClassificationVM, { TimexClassificationVM } from './ClassificationVM';

interface ClassificationSelectProps {
  classification: ClassificationVM;
  onChangeClassification?(c: ClassificationDTO): void;
  onChangeTimexClassification?: (c: TimexClassificationVM) => void;
  className?: string;
  disabled?: boolean;
  onRemove?(): void;
  isSpeedMode?: boolean;
}

/**
 * An inline classification select.
 *
 * A "classification" is an enum property on an ML intent.
 *
 * Renders a classification select or multiselect (or readonly tag)
 * as appropriate. Calls the provided callback on change.
 */
export default function ClassificationSelect({
  classification,
  onChangeClassification: onChangeClassificationDTO,
  onChangeTimexClassification,
  className,
  disabled,
  onRemove,
  isSpeedMode,
}: ClassificationSelectProps) {
  const onChangeClassification = (c: ClassificationVM) => {
    onChangeClassificationDTO?.(ClassificationVM.toDTOs([c])[0]);
  };

  const handleChangeTimexClassification = (tvm: TimexClassificationVM) => {
    onChangeTimexClassification?.(tvm);
  };

  if (classification.type === 'TimexClassification') {
    if (isSpeedMode) {
      return <></>;
    }
    return (
      <ClassificationTimexSelect
        classification={classification}
        onChangeClassification={handleChangeTimexClassification}
        onRemove={onRemove}
        className={className}
        readOnly={disabled}
      />
    );
  }

  if (disabled) {
    return (
      <Tag large className={className}>
        {classification.name}:{' '}
        <strong>{classification.selected?.join(', ')}</strong>
      </Tag>
    );
  }

  if (classification.exclusive) {
    return (
      <ClassificationSingleSelect
        classification={classification}
        onChangeClassification={onChangeClassification}
        className={className}
      />
    );
  }

  return (
    <ClassificationMultiSelect
      classification={classification}
      onChangeClassification={onChangeClassification}
      className={className}
    />
  );
}
