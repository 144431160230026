import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';

import { HorizontalLoadingIndicator } from '../../vanillaelise/LoadingIndicator/HorizontalLoadingIndicator';

import styles from './VanillaScssButton.module.scss';

export enum VanillaScssButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

interface VanillaScssButtonProps {
  /**
   * The type of button which affects
   * its presentation, signalling intent
   * or urgency
   *
   * @default VanillaScssButtonType.Secondary
   */
  buttonType?: VanillaScssButtonType;
  /**
   * Type of button if used in a form for
   * default html behavior
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * The text to show on the button. If both
   * text and children are provided, text
   * takes precedence
   */
  text?: string;
  /**
   * An icon or element to display to the left of the button text
   */
  icon?: ReactElement;
  /**
   * Callback to perform when the button is clicked.
   * Is not called if disabled or isLoading
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * Class name to specify extra or custom
   * styles
   */
  className?: string;
  /**
   * Whether the button should be disabled and therefore
   * totally uninteractable
   */
  disabled?: boolean;
  /**
   * Whether to show the button in a
   * minimal fashion, i.e. without a background
   * but colored text to still signify
   * intent or urgency
   */
  minimal?: boolean;
  /**
   * If provided, the button won't change appearance
   * on hover or click
   */
  noTransitions?: boolean;
  /**
   * An element to display to the right of the text
   * in the button
   */
  rightElement?: ReactElement;
  /**
   * Whether to prevent interacting with the button while
   * showing a loading indicator
   * @default false
   */
  isLoading?: boolean;
  /**
   * Callback when button loses focus.
   */
  onBlur?: () => void;
}

export const VanillaScssButton: FunctionComponent<VanillaScssButtonProps> = ({
  buttonType = VanillaScssButtonType.Secondary,
  text,
  onClick,
  children,
  icon,
  className,
  disabled,
  minimal,
  rightElement,
  noTransitions,
  isLoading = false,
  type,
  onBlur,
}) => {
  return (
    <button
      className={getButtonClassName(
        buttonType,
        className,
        disabled,
        minimal,
        noTransitions
      )}
      onClick={(e) => {
        if (isLoading || disabled) {
          return;
        }
        onClick?.(e);
      }}
      onBlur={onBlur}
      type={type}
    >
      {isLoading && (
        <HorizontalLoadingIndicator
          className={styles.loading}
          isInverted={getIsLoadingIndicatorInverted(buttonType)}
        />
      )}
      {!isLoading && (
        <div className={styles.buttonContent}>
          {icon}
          <div className={getButtonTextClassName(!!icon, disabled)}>
            {text ?? children ?? null}
          </div>
          {rightElement && (
            <span className={styles.rightElement}>{rightElement}</span>
          )}
        </div>
      )}
    </button>
  );
};

const getButtonClassName = (
  buttonType: VanillaScssButtonType,
  className?: string,
  disabled?: boolean,
  minimal?: boolean,
  noTransitions?: boolean
) => {
  return clsx(
    {
      [styles.primaryButton]:
        buttonType === VanillaScssButtonType.Primary && !disabled,
      [styles.secondaryButton]:
        buttonType === VanillaScssButtonType.Secondary && !disabled,
      [styles.tertiaryButton]:
        buttonType === VanillaScssButtonType.Tertiary && !disabled,
      [styles.disabled]: !!disabled,
      [styles.minimal]: minimal,
      [styles.noTransitions]: noTransitions,
    },
    styles.button,
    className
  );
};

const getButtonTextClassName = (hasIcon: boolean, disabled?: boolean) =>
  clsx({ [styles.hasIconNeighbor]: hasIcon, [styles.disabledText]: disabled });

const getIsLoadingIndicatorInverted = (buttonType: VanillaScssButtonType) => {
  switch (buttonType) {
    case VanillaScssButtonType.Primary: {
      return true;
    }
    case VanillaScssButtonType.Secondary: {
      return false;
    }
    case VanillaScssButtonType.Tertiary: {
      return false;
    }
    default: {
      throw new Error(`${buttonType} is not a valid button type`);
    }
  }
};
