import isValid from 'date-fns/isValid';
import isWithinInterval from 'date-fns/isWithinInterval';
import { FilterType } from 'react-table';

/**
 * Additional filter types beyond those provided by react-table.
 *
 * @see https://github.com/tannerlinsley/react-table/blob/master/src/filterTypes.js
 * @see https://react-table.tanstack.com/docs/examples/filtering
 */
const filterTypes: { [key: string]: FilterType<object> } = {
  /**
   * A filter that keeps rows with values in a set of allowed values.
   */
  inSet: (rows, fields, filterValue) =>
    !filterValue
      ? rows
      : rows.filter((row) =>
          fields.some((field) => filterValue.has(row.values[field]))
        ),
  inDateRange: (rows, fields, filterValue) => {
    if (filterValue.hasDateValue) {
      return rows.filter((row) =>
        fields.some((field) => isValid(new Date(row.values[field])))
      );
    }
    return !(filterValue.startDate && filterValue.endDate)
      ? rows
      : rows.filter((row) =>
          fields.some((field) => {
            const date = new Date(row.values[field]);
            return isValid(date)
              ? isWithinInterval(date, {
                  start: filterValue.startDate,
                  end: filterValue.endDate,
                })
              : false;
          })
        );
  },
};

export default filterTypes;
