export enum ToasterPosition {
  Top = 'top',
  TopLeft = 'topLeft',
  TopRight = 'topRight',
  Bottom = 'bottom',
  BottomLeft = 'bottomLeft',
  BottomRight = 'bottomRight',
}

export const isBottom = (position: ToasterPosition) =>
  position === ToasterPosition.Bottom ||
  position === ToasterPosition.BottomLeft ||
  position === ToasterPosition.BottomRight;

export const isLeft = (position: ToasterPosition) =>
  position === ToasterPosition.TopLeft ||
  position === ToasterPosition.BottomLeft;

export const isRight = (position: ToasterPosition) =>
  position === ToasterPosition.TopRight ||
  position === ToasterPosition.BottomRight;
