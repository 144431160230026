import { Button, Colors } from '@blueprintjs/core';
import { useState } from 'react';

import PartyDTO from './PartyDTO';
import PartyVM from './PartyVM';
import UpdatePartyDialog from './UpdatePartyDialog';

interface UpdatePartyButtonProps {
  party: PartyVM;
  scopeName: string;
  onUpdateParty?(party: PartyDTO): void;
  className?: string;
  readOnly?: boolean;
}

/**
 * A button to edit a party.
 *
 * The button text is the human-friendly party name.
 *
 * On click, shows a dialog. When the dialog is closed, this calls
 * the provided callback.
 */
export default function UpdatePartyButton({
  party,
  scopeName,
  className,
  onUpdateParty,
  readOnly,
}: UpdatePartyButtonProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button className={className} onClick={() => setIsOpen(true)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{party.displayName}</div>
          {party.isLead && (
            <div
              style={{
                backgroundColor: Colors.LIGHT_GRAY1,
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                marginLeft: '1rem',
              }}
            />
          )}
          {party.name.startsWith('elise') && (
            <div
              style={{
                backgroundColor: Colors.BLUE3,
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                marginLeft: '1rem',
              }}
            />
          )}
        </div>
      </Button>
      <UpdatePartyDialog
        isOpen={isOpen}
        scopeName={scopeName}
        readOnly={readOnly}
        party={party}
        onUpdateParty={onUpdateParty}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
