/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EliseGuestCardDto } from './EliseGuestCardDto';

export type ScheduleDto = {
    guestCard?: EliseGuestCardDto;
    buildingId?: number;
    tourType?: ScheduleDto.tourType;
    unitId?: number;
    startTime?: number;
    endTime?: number;
    leasingAgentId?: number;
}

export namespace ScheduleDto {

    export enum tourType {
        UNKNOWN = 'UNKNOWN',
        SELF_GUIDED = 'SELF_GUIDED',
        VIRTUAL_SHOWING = 'VIRTUAL_SHOWING',
        WITH_AGENT = 'WITH_AGENT',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
