import {
  Button,
  Tag,
  Intent as UIIntent,
  TextArea,
  Icon,
} from '@blueprintjs/core';
import { TimezoneSelect } from '@blueprintjs/datetime2';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import formatISO from 'date-fns/formatISO';
import startOfToday from 'date-fns/startOfToday';
import { produce } from 'immer';
import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { getTaggingApi } from '../../state-tagger/state/taggingApi';
import { TaggingApiMethods } from '../../state-tagger/state/taggingApiRepository';
import { appToaster } from '../../vanillaelise/appToaster';
import { ToasterPosition } from '../../vanillaelise/Toast/ToasterPosition';
import AttributeClassificationDTO from '../AttributeClassification/AttributeClassificationDTO';
import { isTimexClassification } from '../AttributeClassification/utils';

import { readTimexFromClipboard } from './readTimexFromClipboard';
import TimeItemInput from './TimeItemInput';
import TimexDTO, { TimexItem } from './TimexDTO';

const getTimexInfoFromTimeWizard = async ({
  timexPhrase,
  timezone,
}: {
  timexPhrase: string;
  timezone: string;
}): Promise<{
  timex: TimexDTO | null;
  classifications: AttributeClassificationDTO[];
}> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.TIMEX_WIZARD,
    args: {
      timex_phrase: timexPhrase,
      timezone,
    },
  });
  return response.data;
};

interface TimexTagCollectionProps {
  timex: TimexDTO;
  tagClassName?: string;
  readOnly?: boolean;
  onUpdateTimex: (newTimex: TimexDTO) => void;
  onAddClassifications: (
    newClassifications: AttributeClassificationDTO[]
  ) => void;
}

/**
 * A string of tags that represent a timex.
 *
 * This is the main component to edit a timex.
 */
export default function TimexTagCollection(props: TimexTagCollectionProps) {
  const { timex, tagClassName, readOnly, onUpdateTimex, onAddClassifications } =
    props;

  const showTimexWizardFailure = () => {
    appToaster.show({
      icon: <Icon icon="predictive-analysis" />,
      title: 'Timex wizard failed. Please try again or enter timex manually.',
      position: ToasterPosition.Top,
      timeoutInMilliseconds: 5000,
    });
  };

  const [timexWizardPhrase, setTimexWizardPhrase] = useState('');
  const [requestTimexWizard, { isLoading: timexWizardIsLoading }] = useMutation(
    getTimexInfoFromTimeWizard,
    {
      onSuccess: (response) => {
        const { timex: timexDTO, classifications } = response;
        if (
          timexDTO === null ||
          (isEqual(timexDTO.timex?.positive_timexes, [[null, null]]) &&
            isEqual(timexDTO.timex?.negative_timexes, [[null, null]]))
        ) {
          showTimexWizardFailure();
        } else {
          onAddClassifications(classifications.filter(isTimexClassification));
          onUpdateTimex(
            produce(timex, (draft) => {
              if (draft.timex && timexDTO.timex) {
                draft.timex.positive_timexes.push(
                  ...timexDTO.timex.positive_timexes
                );
                draft.timex.negative_timexes.push(
                  ...timexDTO.timex.negative_timexes
                );
                draft.has_date = timexDTO.has_date;
              }
            })
          );
        }
      },
      onError(e) {
        showTimexWizardFailure();
      },
    }
  );

  const updateTimeToTimezone = (
    timeItem: TimexItem,
    oldTimezone: string,
    newTimezone: string
  ) => {
    const start = timeItem[0] ? utcToZonedTime(timeItem[0], oldTimezone) : null;
    const end = timeItem[1] ? utcToZonedTime(timeItem[1], oldTimezone) : null;

    const startUTC = start ? zonedTimeToUtc(start, newTimezone) : null;
    const endUTC = end ? zonedTimeToUtc(end, newTimezone) : null;

    const newStart = startUTC ? formatISO(startUTC) : null;
    const newEnd = endUTC ? formatISO(endUTC) : null;
    return [newStart, newEnd] as TimexItem;
  };

  const handleChageTimezone = (newTimezone: string) => {
    const oldTimezone = timex.timezone;
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timezone = newTimezone;
        if (draft.timex) {
          const updatedToTimezonePositives = draft.timex.positive_timexes.map(
            (timeItem) =>
              updateTimeToTimezone(timeItem, oldTimezone ?? '', newTimezone)
          );
          const updatedToTimezoneNegatives = draft.timex.negative_timexes.map(
            (timeItem) =>
              updateTimeToTimezone(timeItem, oldTimezone ?? '', newTimezone)
          );

          draft.timex.positive_timexes = updatedToTimezonePositives;
          draft.timex.negative_timexes = updatedToTimezoneNegatives;
        }
      })
    );
  };

  const handleChangeHasDate = (hasDate: boolean) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.has_date = hasDate;
      })
    );
  };

  const handleAddTime = () => {
    const utcStartToday = zonedTimeToUtc(startOfToday(), timex.timezone ?? '');

    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timex?.positive_timexes.push([formatISO(utcStartToday), null]);
      })
    );
  };

  const handleAddTimeFromClipboard = async () => {
    const clipboardTimex = await readTimexFromClipboard();

    if (clipboardTimex) {
      onUpdateTimex(
        produce(timex, (draft) => {
          if (clipboardTimex.positive) {
            draft.timex?.positive_timexes.push(clipboardTimex.timeItem);
          } else {
            draft.timex?.negative_timexes.push(clipboardTimex.timeItem);
          }
        })
      );
    }
  };

  const handleChangeToNegative = (idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timex?.negative_timexes.push(draft.timex.positive_timexes[idx]);
        draft.timex?.positive_timexes.splice(idx, 1);
      })
    );
  };

  const handleChangeToPositive = (idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timex?.positive_timexes.push(draft.timex.negative_timexes[idx]);
        draft.timex?.negative_timexes.splice(idx, 1);
      })
    );
  };

  const handleChangePositiveTime = (timeItem: TimexItem, idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        if (draft.timex) {
          draft.timex.positive_timexes[idx] = timeItem;
        }
      })
    );
  };
  const handleChangeNegativeTime = (timeItem: TimexItem, idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        if (draft.timex) {
          draft.timex.negative_timexes[idx] = timeItem;
        }
      })
    );
  };

  const handleRemovePositiveTimexItem = (idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timex?.positive_timexes.splice(idx, 1);
      })
    );
  };

  const handleRemoveNegativeTimexItem = (idx: number) => {
    onUpdateTimex(
      produce(timex, (draft) => {
        draft.timex?.negative_timexes.splice(idx, 1);
      })
    );
  };

  return (
    <>
      {timex.timezone && (
        <>
          {readOnly ? (
            <Tag large className={tagClassName} intent={UIIntent.PRIMARY}>
              {timex.timezone}
            </Tag>
          ) : (
            <TimezoneSelect
              disabled={readOnly}
              value={timex.timezone}
              onChange={handleChageTimezone}
            />
          )}
        </>
      )}

      {timex.timex?.positive_timexes.map((time, i) => (
        <TimeItemInput
          label={'Time'}
          key={i}
          time={time}
          positive
          readOnly={readOnly}
          uiIntent={UIIntent.PRIMARY}
          className={tagClassName}
          timezone={timex.timezone}
          hasDate={timex.has_date}
          onChangeHasDate={handleChangeHasDate}
          onChangeTime={(timeItem) => handleChangePositiveTime(timeItem, i)}
          onRemove={() => handleRemovePositiveTimexItem(i)}
          onChangeIsPositive={() => handleChangeToNegative(i)}
        />
      ))}
      {timex.timex?.negative_timexes.map((time, i) => (
        <TimeItemInput
          label={'NOT'}
          key={i}
          time={time}
          positive={false}
          readOnly={readOnly}
          uiIntent={UIIntent.WARNING}
          className={tagClassName}
          timezone={timex.timezone}
          hasDate={timex.has_date}
          onChangeHasDate={handleChangeHasDate}
          onChangeTime={(timeItem) => handleChangeNegativeTime(timeItem, i)}
          onRemove={() => handleRemoveNegativeTimexItem(i)}
          onChangeIsPositive={() => handleChangeToPositive(i)}
        />
      ))}
      {!readOnly && (
        <>
          <Button
            text="Add time"
            intent={UIIntent.PRIMARY}
            className={tagClassName}
            icon="add"
            onClick={handleAddTime}
          />
          <Button
            minimal
            icon="clipboard"
            onClick={handleAddTimeFromClipboard}
          />
        </>
      )}
      {!readOnly && (
        <div style={{ display: 'flex' }}>
          <TextArea
            growVertically={true}
            large={true}
            onChange={(e) => setTimexWizardPhrase(e.target.value)}
            value={timexWizardPhrase}
          />
          <Button
            text="Time Wizard"
            loading={timexWizardIsLoading}
            intent={UIIntent.PRIMARY}
            className={tagClassName}
            icon="time"
            onClick={() =>
              requestTimexWizard({
                timexPhrase: timexWizardPhrase,
                timezone: timex.timezone ?? '',
              })
            }
          />
        </div>
      )}
    </>
  );
}
