import { useAuth0 } from '@auth0/auth0-react';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import AuthUser from '../../auth/AuthUser';
import { useMaybeUser } from '../../auth/hooks';
import { SelectIcon } from '../../components/Icons/SelectIcon';
import { useOnOutsideClick } from '../../useOnOutsideClick';
import {
  getHorizontalPopoverStylesFromRefs,
  getVerticalPopoverStyles,
} from '../../vanillaelise/getPopoverStyles';

import styles from './VanillaScssHeader.module.scss';

interface VanillaScssHeaderProps {
  content?: React.ReactNode;
  isInternalPage?: boolean;
}

export const VanillaScssHeader: FunctionComponent<VanillaScssHeaderProps> = ({
  content,
  isInternalPage,
}) => {
  const maybeUser = useMaybeUser();

  if (!maybeUser) {
    return <></>;
  }

  return (
    <>
      <div className={styles.headerWrapper}>
        <div className={styles.headerContentWrapper}>{content}</div>
        <UserActions user={maybeUser} isInternalPage={isInternalPage} />
      </div>
      <div className={styles.divider} />
    </>
  );
};

const UserActions: FunctionComponent<{
  user: AuthUser;
  isInternalPage?: boolean;
}> = ({ user, isInternalPage }) => {
  const { logout } = useAuth0();
  const history = useHistory();
  const userActionsWrapperRef = useRef<HTMLDivElement | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverStyles, setPopoverStyles] = useState({});

  useEffect(() => {
    setPopoverStyles({
      ...getHorizontalPopoverStylesFromRefs(userActionsWrapperRef, popoverRef),
      ...getVerticalPopoverStyles(userActionsWrapperRef, popoverRef),
    });
  }, [isPopoverOpen]);

  const onOutsideClick = useCallback((event: Event) => {
    const isInPopover =
      popoverRef.current &&
      event.target &&
      popoverRef.current.contains(event.target as Node);
    const isInUserActionsWrapperRef =
      userActionsWrapperRef.current &&
      event.target &&
      userActionsWrapperRef.current.contains(event.target as Node);
    if (isInPopover || isInUserActionsWrapperRef) {
      return;
    }

    setIsPopoverOpen(false);
  }, []);

  useOnOutsideClick(onOutsideClick);

  return (
    <div className={styles.flexContainer}>
      <div className={styles.userActionsWrapper} ref={userActionsWrapperRef}>
        <div
          className={styles.userWrapper}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          <div className={styles.user}>{user.email}</div>
          <SelectIcon />
        </div>
        {isPopoverOpen && (
          <div
            className={styles.popover}
            ref={popoverRef}
            style={popoverStyles}
          >
            <div className={styles.popoverContent}>
              <div className={styles.disabledEmail}>{user.email}</div>
              <div
                className={styles.link}
                onClick={() => history.push('/profile')}
              >
                Account
              </div>
              <div
                className={styles.link}
                onClick={() => window.open('//support.meetelise.com')}
              >
                Support
              </div>
              <div
                className={styles.signOut}
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                Sign out
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
