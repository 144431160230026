/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgentPersonalizedMessageUpdateDto = {
    type?: AgentPersonalizedMessageUpdateDto.type;
    body?: string;
    message_type?: string;
    update_id?: number;
}

export namespace AgentPersonalizedMessageUpdateDto {

    export enum type {
        WALK_IN = 'WALK_IN',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
        SHOW = 'SHOW',
        AGENT_TAKEOVER = 'AGENT_TAKEOVER',
        INACTIVATION = 'INACTIVATION',
        NO_SHOW = 'NO_SHOW',
        AGENT_NOTE = 'AGENT_NOTE',
        LEAD_NOTE = 'LEAD_NOTE',
        ELISE_REACTIVATED = 'ELISE_REACTIVATED',
        AGENT_PERSONALIZED_MESSAGE = 'AGENT_PERSONALIZED_MESSAGE',
        ADDITIONAL_INTENTS = 'ADDITIONAL_INTENTS',
    }


}
