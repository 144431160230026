import { AnchorButton, AnchorButtonProps } from '@blueprintjs/core';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

type Props = AnchorButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

/**
 * A hyperlink that looks like a button.
 *
 * Uses react-router-dom's <Link /> component to integrate
 * with routing (avoiding a full-page reload on navigation)
 * and uses Blueprint's <AnchorButton /> for styles.
 */
export default function LinkButton(props: LinkProps & Props): JSX.Element {
  return <Link component={NavigateButton} {...props} />;
}

interface NavigateButtonProps extends Props {
  navigate(): void;
  href: string;
}

function NavigateButton({ navigate, ...props }: NavigateButtonProps) {
  return (
    <AnchorButton
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        if (props.onClick) props.onClick(e);
        if (
          !e.defaultPrevented && // onclick prevented default
          e.button === 0 && // ignore if it's not left-click
          (!props.target || props.target === '_self') && // let browser handle target=_blank etc.
          !isModifiedEvent(e) // ignore ctrl click, etc.
        ) {
          e.preventDefault();
          navigate();
        }
      }}
      {...props}
    />
  );
}

function isModifiedEvent(e: React.MouseEvent) {
  return !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);
}
