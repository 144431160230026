import { Scope } from '../state-tagger/state/Scope';
import StateDTO from '../state-tagger/state/StateDTO';

export interface StateContextType {
  state: StateDTO;
  actions: StateContextActionRegistry;
}

export interface StateContextActionRegistry {
  updateStateId: (newStateId: string) => void;
}

export const defaultStateContextRegistry = {
  updateStateId: (newStateId: string) => {
    throw new Error(`Cannot call updateStateId before initialization`);
  },
};

export const defaultState = {
  id: '',
  scope: Scope.LeasingWebchat,
  events: [],
  parties: [],
};
