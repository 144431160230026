import {
  Tag,
  Intent as UIIntent,
  TagInput,
  Button,
  FormGroup,
  TextArea,
} from '@blueprintjs/core';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './IntentTextsInput.module.scss';

interface IntentTextsInputProps {
  texts: string[];
  scriptStr: string;
  onChangeTexts?(texts: string[]): void;
  className?: string;
  disabled?: boolean;
}

const textsWithIndexPlaceholderRegex = /\{texts:[0-9]*}/g;
const textsPlaceholderRegex = /\{texts\}/g;

/**
 * A tag to display and edit the texts property for an Intent.
 */
export default function IntentTextsInput(props: IntentTextsInputProps) {
  const { texts, scriptStr, onChangeTexts, className, disabled } = props;
  const [currentTexts, setCurrentTexts] = useState(texts);

  const textsWithIndexPlaceholders = Array.from(
    scriptStr.matchAll(textsWithIndexPlaceholderRegex),
    (m) => m[0].replace('{', '').replace('}', '')
  );
  const isPlainTextsPlaceholder = !scriptStr
    .matchAll(textsPlaceholderRegex)
    .next().done;

  const textsPlaceholderIndices = textsWithIndexPlaceholders.map(
    (textsPlaceholder) => parseInt(textsPlaceholder.split(':')[1])
  );

  if (disabled) {
    return (
      <Tag large intent={UIIntent.PRIMARY} className={className}>
        Texts: <strong>{texts.join(', ')}</strong>
      </Tag>
    );
  }

  if (isPlainTextsPlaceholder) {
    return (
      <div className={styles.plainTextsContainer}>
        <TagInput
          onAdd={(e) => onChangeTexts?.([...texts, ...e])}
          onRemove={(_, idx) => {
            const updatedTexts = [...texts];
            updatedTexts.splice(idx, 1);
            onChangeTexts?.(updatedTexts);
          }}
          placeholder="Texts"
          rightElement={
            <Button
              disabled={texts.length === 0}
              icon={'cross'}
              minimal={true}
              onClick={() => onChangeTexts?.([])}
            />
          }
          values={texts}
        />
      </div>
    );
  }

  if (disabled) {
    return (
      <>
        {textsPlaceholderIndices.map((i) => (
          <Tag
            key={`text-${i}`}
            large
            intent={UIIntent.PRIMARY}
            className={className}
          >
            Texts:{i} <strong>{texts[i]}</strong>
          </Tag>
        ))}
      </>
    );
  }

  return (
    <>
      {textsPlaceholderIndices.map((i) => (
        <FormGroup
          key={`text-${i}`}
          label={`Texts:${i}`}
          inline
          style={{ marginBottom: 0 }}
        >
          <TextArea
            rows={1}
            className={clsx('d-inline-block', className)}
            value={currentTexts[i]}
            onChange={(e) => {
              if (e.target.scrollHeight > e.target.clientHeight) {
                e.target.rows += 1;
              }
              const textsCopy = [...texts];
              textsCopy[i] = e.target.value;
              setCurrentTexts(textsCopy);
            }}
            onBlur={() => onChangeTexts?.(currentTexts)}
            onKeyPress={(e) =>
              e.key === 'Enter' && onChangeTexts?.(currentTexts)
            }
            // leftElement={<Tag intent={UIIntent.PRIMARY}>Texts:{i}</Tag>}
            intent={UIIntent.PRIMARY}
          />
        </FormGroup>
      ))}
    </>
  );
}
