import clsx from 'clsx';
import { FunctionComponent, useMemo, useState } from 'react';

import { CheckIcon } from '../../components/Icons/CheckIcon';
import { assertIsValidColor } from '../color-fns';

import styles from './Checkbox.module.scss';

export interface CheckboxProps {
  onChangeChecked?(value: boolean): void;
  /**
   * For uncontrolled behavior, whether the default
   * state is checked or unchecked
   * @default false
   */
  isCheckedByDefault?: boolean;
  /**
   * For controlled behavior, whether the
   * checkbox is checked
   */
  isChecked?: boolean;
  /**
   * Class name for custom styling on the entire
   * checkbox, including the label
   */
  className?: string;
  /**
   * Class name for applying styles directly
   * to the checkbox
   */
  checkboxClassName?: string;
  /**
   * Whether the checkbox is disabled, as in,
   * whether you shouldnt be able to interact with
   * the checkbox
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Label to display with the checkbox
   */
  label?: string;
  /**
   * A hex value determining what color the checkmark
   * should be in the isChecked = true case.
   *
   * @default defaultGreen
   */
  checkmarkColor?: string;
  /**
   * A hex value determining what color the checkbox
   * border color should be in the isChecked = true case.
   *
   * @default defaultGreen
   */
  checkedBorderColor?: string;
  /**
   * A hex value determining what color the checkbox
   * background color should be in the isChecked = true case.
   *
   * @default defaultWhite
   */
  checkedFillColor?: string;

  /**
   * Class name for applying custom styles
   * to the label
   */
  labelClassName?: string;
  /**
   * Id to be passed in if necessary for
   * testing purposes
   */
  testId?: string;
}

const defaultGreen = '#00AC11';
const defaultWhite = '#FFFFFF';

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  onChangeChecked,
  isChecked,
  isCheckedByDefault = false,
  isDisabled = false,
  className,
  checkboxClassName,
  checkmarkColor = defaultGreen,
  checkedBorderColor = defaultGreen,
  checkedFillColor = defaultWhite,
  label,
  labelClassName,
  testId,
}) => {
  const [internalIsChecked, setInternalIsChecked] = useState(
    !!(isChecked ?? isCheckedByDefault)
  );

  const isCheckedValueToUse = isChecked ?? internalIsChecked;

  assertIsValidColor(checkmarkColor);
  assertIsValidColor(checkedBorderColor);
  assertIsValidColor(checkedFillColor);

  const isCheckedStyles = useMemo(
    () =>
      isCheckedValueToUse
        ? {
            background: checkedFillColor,
            borderColor: checkedBorderColor,
          }
        : undefined,
    [checkedBorderColor, checkedFillColor, isCheckedValueToUse]
  );

  return (
    <div
      role="checkbox"
      aria-checked={isCheckedValueToUse}
      aria-label={label}
      data-testid={testId}
      className={getCheckboxWrapperClassNames(isDisabled, className)}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        setInternalIsChecked(!isCheckedValueToUse);
        onChangeChecked?.(!isCheckedValueToUse);
      }}
    >
      <div
        style={isCheckedStyles}
        className={getCheckboxClassNames(isDisabled, checkboxClassName)}
      >
        {isCheckedValueToUse && <CheckIcon color={checkmarkColor} />}
      </div>
      {label && (
        <label className={getLabelClassName(isDisabled, labelClassName)}>
          {label}
        </label>
      )}
    </div>
  );
};

const getCheckboxWrapperClassNames = (
  isDisabled?: boolean,
  className?: string
) =>
  clsx(styles.checkboxWrapper, className, {
    [styles.isDisabled]: isDisabled,
  });

const getCheckboxClassNames = (isDisabled?: boolean, className?: string) =>
  clsx(styles.checkbox, className, {
    [styles.isDisabled]: isDisabled,
  });

const getLabelClassName = (isDisabled?: boolean, labelClassName?: string) =>
  clsx(
    styles.checkboxLabel,
    {
      [styles.isDisabled]: isDisabled,
    },
    labelClassName
  );
