import { Intent, Toaster } from '@blueprintjs/core';
import { FC, ReactNode } from 'react';

const blueprintToaster = Toaster.create();

const appToaster = {
  clear: blueprintToaster.clear.bind(blueprintToaster),
  show: blueprintToaster.show.bind(blueprintToaster),

  /**
   * Display a success toast.
   */
  success: (message: ReactNode) => {
    blueprintToaster.show({ message, intent: Intent.SUCCESS });
  },

  /**
   * Display an error to the screen temporarily.
   *
   * Good as a .catch() handler for promises, especially
   * user-initiated mutations.
   */
  toastError: (e: unknown) => {
    blueprintToaster.show({
      message: <ErrorMsg error={e} />,
      intent: Intent.WARNING,
    });
  },
};

export default appToaster;

const ErrorMsg: FC<{ error: any }> = ({ error: e }) => {
  const serverResponse = e?.response?.data;
  if (!serverResponse) return e?.message ?? 'An unknown error occurred.';
  const serverMessage = serverResponse?.message;

  return (
    <>
      <p className="m-0 mb-3">The server sent back the following message:</p>
      <code>{serverMessage ?? JSON.stringify(serverResponse, null, 2)}</code>
    </>
  );
};
