import { DateTime } from 'luxon';
import {
  MutationConfig,
  QueryConfig,
  QueryResult,
  useMutation,
  useQuery,
} from 'react-query';

import api from '../../api/axios';
import FeatureDTO from '../../feature-form/FeatureDTO';
import BuildingsServedDTO from '../buildings_served/BuildingsServedDTO';
import ClassificationDTO from '../classification/ClassificationDTO';
import { EscalationFilterTag } from '../escalations/EscalatedTagsQueue';
import IntentDTO from '../intent/IntentDTO';
import MessageDTO from '../message/MessageDTO';
import { MessageType } from '../message/MessageType';
import PartyDTO from '../party/PartyDTO';
import { PartyPropertyDTO } from '../party/PartyPropertyDTO';
import TimexDTO from '../timex/TimexDTO';
import ToursVM from '../tours/ToursVM';
import UnitsVM from '../units/UnitsVM';

import StateDTO from './StateDTO';
import { getTaggingApi, setTagSessionId } from './taggingApi';

export enum TaggingApiMethods {
  FETCH_CURRENT_TAG_ID = 'FETCH_CURRENT_TAG_ID',
  FETCH_NEXT_TAG_STATE = 'FETCH_NEXT_TAG_STATE',
  FETCH_CURRENT_TAG_STATE = 'FETCH_CURRENT_TAG_STATE',
  FETCH_TAG_STATE_BY_ID = 'FETCH_TAG_STATE_BY_ID',
  MARK_EVENT_CONFIRMED = 'MARK_EVENT_CONFIRMED',
  MARK_EVENT_UNCONFIRMED = 'MARK_EVENT_UNCONFIRMED',
  ADD_EVENT = 'ADD_EVENT',
  REMOVE_EVENT = 'REMOVE_EVENT',
  MARK_EVENT_AMBIGUOUS = 'MARK_EVENT_AMBIGUOUS',
  MARK_EVENT_UNAMBIGUOUS = 'MARK_EVENT_UNAMBIGUOUS',
  FETCH_AVAILABILITIES = 'FETCH_AVAILABILITIES',
  FETCH_UNITS = 'FETCH_UNITS',
  FETCH_BUILDINGS_SERVED = 'FETCH_BUILDINGS_SERVED',
  RETURN_STATE = 'RETURN_STATE',
  GRAVEYARD_STATE = 'GRAVEYARD_STATE',
  AUTO_PREDICT_EVENT = 'AUTO_PREDICT_EVENT',
  SUBMIT_STATE = 'SUBMIT_STATE',
  RESTART_TAG_STATE = 'RESTART_TAG_STATE',
  FETCH_UNIT_MATCHES = 'FETCH_UNIT_MATCHES',
  FETCH_DEFAULT_EVENT_TYPE = 'FETCH_DEFAULT_EVENT_TYPE',
  GET_NEW_PARTY_PROPERTY = 'GET_NEW_PARTY_PROPERTY',
  UPDATE_PARTY_PROPERTIES = 'UPDATE_PARTY_PROPERTIES',
  GET_UPDATED_DIALOGUE_AND_INTENTS = 'GET_UPDATED_DIALOGUE_AND_INTENTS',
  GET_NEW_INTENT = 'GET_NEW_INTENT',
  UPDATE_INTENTS = 'UPDATE_INTENTS',
  GET_NEW_TIMEX = 'GET_NEW_TIMEX',
  GET_NEW_CLASSIFICATION = 'GET_NEW_CLASSIFICATION',
  GET_NEW_RELATIVE_TIMEX_CLASSIFICATIONS = 'GET_NEW_RELATIVE_TIMEX_CLASSIFICATIONS',
  GET_FINAL_DIALOGUE_V2 = 'GET_FINAL_DIALOGUE_V2',
  ESCALATE_TAG_STATE = 'ESCALATE_TAG_STATE',
  LIST_ESCALATED_STATES = 'LIST_ESCALATED_STATES',
  ASSIGN_LATEST_SANDBOX_STATE = 'ASSIGN_LATEST_SANDBOX_STATE',
  ASSIGN_BY_TAG_JOB_ID = 'ASSIGN_BY_TAG_JOB_ID',
  ASSIGN_BY_TAG_BATCH_ID = 'ASSIGN_BY_TAG_BATCH_ID',
  GET_UPDATED_PREDICTION = 'GET_UPDATED_PREDICTION',
  START_DEBUG_SESSION = 'START_DEBUG_SESSION',
  RESUME_TAG_SESSION = 'RESUME_TAG_SESSION',
  TIMEX_WIZARD = 'TIMEX_WIZARD',
  GET_TRAINING_FEEDBACK = 'GET_TRAINING_FEEDBACK',
  CREATE_SNAPSHOT_TEST = 'CREATE_SNAPSHOT_TEST',
  SUGGEST_INTENT = 'SUGGEST_INTENT',
  DEESCALATE_TAG_STATE = 'DEESCALATE_TAG_STATE',
  GET_AIT_FROM_ID = 'GET_AIT_FROM_ID',
  GET_ONLINE_AITS = 'GET_ONLINE_AITS',
  GET_POOL_SIZES = 'GET_POOL_SIZES',
  UPDATE_ESCALATION_COMMENT = 'UPDATE_ESCALATION_COMMENT',
  UPDATE_ESCALATION_FILTER_TAGS = 'UPDATE_ESCALATION_FILTER_TAGS',
}

export type TagStateDTO = {
  job_id: number;
  tag_job_id: number;
  tag_id: number;
  allow_escalation: boolean;
  state: StateDTO;
  ambiguous_event_ids: string[];
  label_event_ids: string[];
  generate_prediction: boolean;
  generate_message_from_intents: boolean;
  confirmed_event_ids: string[];
  added_event_ids: string[];
  task: {
    name: string;
    description: string;
  };
  scope_name: string;
  features?: FeatureDTO[];
  error_messages?: string[];
  is_live_state: boolean;
  escalation_comment?: string;
  speed_mode?: boolean;
};

export type AutoPredictEventDTO = {
  added_events: MessageDTO[];
};

export type FetchUnitMatchesRequestDTO = {
  layouts: string[];
  leadMoveInStartDate?: string;
  leadMoveInEndDate?: string;
};

export type FetchUnitMatchesResponseDTO = {
  layout_to_matches: Record<
    string,
    {
      hard_matches: string[];
      early_soft_matches: string[];
      late_soft_matches: string[];
    }
  >;
};

export const getNewIntent = async ({
  intentName,
  scopeName,
}: {
  intentName: string;
  scopeName: string;
}): Promise<IntentDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_NEW_INTENT,
    args: {
      intent_name: intentName,
      scope_name: scopeName,
    },
  });
  return response.data.intent;
};

export const getNewTimex = async (): Promise<TimexDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_NEW_TIMEX,
  });
  return response.data.timex;
};

export const useGetNewTimex = (
  opts?: MutationConfig<TimexDTO, unknown, undefined, unknown>
) => useMutation(async () => await getNewTimex(), opts);

export const updateIntents = async ({
  eventId,
  intents,
}: {
  eventId: string;
  intents: IntentDTO[];
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.UPDATE_INTENTS,
    args: {
      event_id: eventId,
      intent_dicts: intents,
    },
  });
};

export const fetchDefaultEventType = async (): Promise<MessageType> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_DEFAULT_EVENT_TYPE,
  });
  return MessageType[response.data as keyof typeof MessageType];
};

export const fetchUnitMatches = async (
  fetchUnitMatchesRequestDTO: FetchUnitMatchesRequestDTO
): Promise<FetchUnitMatchesResponseDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_UNIT_MATCHES,
    args: fetchUnitMatchesRequestDTO,
  });
  return response.data;
};

export type TagStateScore = {
  name: string;
  value: number;
};

export const submitState = async ({
  yellowEscalationComment,
  yellowEscalationUrgency,
  scores,
}: {
  yellowEscalationComment?: string;
  yellowEscalationUrgency?: string;
  scores?: TagStateScore[];
}): Promise<{ next_batch_id?: number }> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.SUBMIT_STATE,
    args: {
      escalation_comment: yellowEscalationComment,
      escalation_urgency: yellowEscalationUrgency,
      scores,
    },
  });
  return response.data;
};

export const restartTagState = async (): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.RESTART_TAG_STATE,
  });
};

export const fetchCurrentTagId = async (): Promise<null | number> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_CURRENT_TAG_ID,
  });
  return response.data;
};

export const fetchNextTagState = async (
  scopeChoice: string[]
): Promise<TagStateDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_NEXT_TAG_STATE,
    args: { scope_choice: scopeChoice },
  });
  if (response.status === 204) {
    throw new Error('204');
  }
  setTagSessionId(response.data.session_id);
  return response.data;
};

export const fetchCurrentTagState = async (): Promise<TagStateDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_CURRENT_TAG_STATE,
  });
  if (response.status === 204) {
    throw new Error('204');
  }
  setTagSessionId(response.data.session_id);
  return response.data;
};

export const createSnapshotTest = async ({
  description,
  name,
}: {
  description: string;
  name: string;
}): Promise<number> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.CREATE_SNAPSHOT_TEST,
    args: {
      description,
      name,
    },
  });
  return response.data;
};

export const fetchTagStateById = async (
  tagId: string
): Promise<TagStateDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_TAG_STATE_BY_ID,
    args: {
      tag_id: tagId,
    },
  });
  setTagSessionId(response.data.session_id);
  return response.data;
};

export const getNewPartyProperty = async ({
  propertyName,
}: {
  propertyName: string;
}): Promise<PartyPropertyDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_NEW_PARTY_PROPERTY,
    args: {
      property_name: propertyName,
    },
  });
  return response.data.party_property;
};

export const updatePartyProperties = async ({
  partyName,
  partyProperties,
}: {
  partyName: string;
  partyProperties: PartyPropertyDTO[];
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.UPDATE_PARTY_PROPERTIES,
    args: {
      party_name: partyName,
      party_properties: partyProperties,
    },
  });
};

export const markEventConfirmed = async (eventId: string): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.MARK_EVENT_CONFIRMED,
    args: {
      event_id: eventId,
    },
  });
};

export const markEventUnconfirmed = async (eventId: string): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.MARK_EVENT_UNCONFIRMED,
    args: {
      event_id: eventId,
    },
  });
};

// TODO(FRANKIE): change MessageType to Eventtype
export const addEvent = async (
  eventType: MessageType
): Promise<{ event: MessageDTO }> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.ADD_EVENT,
    args: {
      event_type: eventType,
    },
  });
  return response.data;
};

export const removeEvent = async (eventId: string): Promise<void> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.REMOVE_EVENT,
    args: {
      event_id: eventId,
    },
  });
  return response.data;
};

export const markEventAmbiguous = async (eventId: string): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.MARK_EVENT_AMBIGUOUS,
    args: {
      event_id: eventId,
    },
  });
};

export const markEventUnambiguous = async (eventId: string): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.MARK_EVENT_UNAMBIGUOUS,
    args: {
      event_id: eventId,
    },
  });
};

export const fetchUnits = async (): Promise<UnitsVM> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_UNITS,
  });
  return response.data;
};

export const fetchBuildingsServed = async (): Promise<BuildingsServedDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_BUILDINGS_SERVED,
  });
  return response.data;
};

export const fetchAvailabilities = async (): Promise<ToursVM> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.FETCH_AVAILABILITIES,
  });
  Object.values(response.data).forEach((timex: any) => {
    timex.items.forEach((t: any) => {
      t.start = DateTime.fromISO(t.start ?? '', { zone: timex.timezone });
      t.end = DateTime.fromISO(t.end ?? '', { zone: timex.timezone });
    });
  });
  return response.data;
};

export const returnState = async (): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.RETURN_STATE,
  });
};

export const graveyardState = async (): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GRAVEYARD_STATE,
  });
};

export const autoPredictEvent = async (): Promise<AutoPredictEventDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.AUTO_PREDICT_EVENT,
  });
  return response.data;
};

export const getUpdatedDialogueAndIntents = async (
  eventDTO: MessageDTO
): Promise<{
  dialogue: string;
  intents: IntentDTO[];
  intent_order_changed: boolean;
}> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_UPDATED_DIALOGUE_AND_INTENTS,
    args: {
      event_dict: eventDTO,
    },
  });
  return response.data;
};

export const getFinalDialogue = async (): Promise<{
  message: string;
  type: string;
}> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_FINAL_DIALOGUE_V2,
  });
  return response.data;
};

export const getNewClassification = async (
  classificationName: string
): Promise<ClassificationDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_NEW_CLASSIFICATION,
    args: {
      classification_name: classificationName,
    },
  });
  return response.data;
};

export const fetchNewRelativeTimexClassifications = async (): Promise<
  ClassificationDTO[]
> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_NEW_RELATIVE_TIMEX_CLASSIFICATIONS,
  });
  return response.data;
};

export const escalateTagState = async (comment?: string): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.ESCALATE_TAG_STATE,
    args: {
      comment,
    },
  });
};

export const fetchEscalatedTags = async (): Promise<EscalatedTagDTO[]> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.LIST_ESCALATED_STATES,
  });
  return response.data;
};

// Fetch and assign escalated state
export const assignByTagJobId = async (tag_job_id: number): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.ASSIGN_BY_TAG_JOB_ID,
    args: {
      tag_job_id,
    },
  });
};

export const assignByTagBatchId = async (
  tag_batch_id: number
): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.ASSIGN_BY_TAG_BATCH_ID,
    args: {
      tag_batch_id,
    },
  });
};

export const resumeTagSession = async (tag_id: number): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.RESUME_TAG_SESSION,
    args: {
      tag_id,
    },
  });
};

export const getTimexInfoFromTimeWizard = async ({
  timexPhrase,
  timezone,
}: {
  timexPhrase: string;
  timezone: string;
}): Promise<{
  timex: TimexDTO | null;
  classifications: ClassificationDTO[];
}> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.TIMEX_WIZARD,
    args: {
      timex_phrase: timexPhrase,
      timezone,
    },
  });
  return response.data;
};

export const fetchUpdatedPrediction = async ({
  features,
  logPrediction,
  usedPredictionBuilder,
}: {
  features: FeatureDTO[];
  logPrediction?: boolean;
  usedPredictionBuilder?: boolean;
}): Promise<{
  added_events: MessageDTO[];
  removed_event_ids: string[];
  features: FeatureDTO[];
  error_messages: string[];
  parties: PartyDTO[];
  log_id?: number;
}> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_UPDATED_PREDICTION,
    args: {
      features,
      log: logPrediction,
      used_prediction_builder: usedPredictionBuilder,
    },
  });
  return response.data;
};

export type ScopeConfigDTO = {
  intents: IntentDTO[];
  classifications: { name: string; description: string }[];
  properties: { name: string; description: string }[];
};

export type EscalatedTagDTO = {
  ait_escalating: string;
  comment?: string;
  escalation_time: string;
  input_time: string;
  job_name: string;
  scope_name: string;
  state_id: string;
  tag_job_id: number;
  tag_id: number;
  assigned_ait: string | null;
  filter_tags: EscalationFilterTag[];
};

export const useScopeConfigs = (
  scopeName: string,
  config?: QueryConfig<ScopeConfigDTO, Error>
): QueryResult<ScopeConfigDTO, Error> => {
  return useQuery(
    'FETCH_SCOPE_CONFIG',
    () => fetchScopeConfigs(scopeName),
    config
  );
};

export const fetchScopeConfigs = async (
  scopeName: string
): Promise<ScopeConfigDTO> => {
  const response: any = await api.get(
    `../../conversationApi/scope/${scopeName}/star/latest`
  );

  if ('intents' in response.data) {
    const classifications = Object.keys(response.data.intents)
      .map((intentName: string) => response.data.intents[intentName])
      .map((intent) => intent.classifications)
      .flat(2)
      .filter((value, index, self) => self.indexOf(value) === index);

    return {
      intents: Object.values(response.data.intents),
      classifications,
      properties: Object.values(response.data.properties),
    };
  } else {
    return {
      intents: Object.values(response.data.intent_configs),
      classifications: Object.values(response.data.classification_configs),
      properties: Object.values(response.data.property_configs),
    };
  }
};

export const startDebuggingSession = async ({
  tagId,
  tagJobId,
}: {
  tagId?: string;
  tagJobId?: string;
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.START_DEBUG_SESSION,
    args: {
      tag_id: tagId,
      tag_job_id: tagJobId,
    },
  });
};

export const assignLatestSandboxState = async ({
  stateId,
  email,
}: {
  stateId: string;
  email?: string;
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.ASSIGN_LATEST_SANDBOX_STATE,
    args: {
      state_id: stateId,
      email,
    },
  });
};

export type TrainingFeedbackDTO = {
  expected: MessageDTO;
  tagged: MessageDTO;
  equal: boolean;
};

export const getTrainingFeedback = async (): Promise<TrainingFeedbackDTO[]> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_TRAINING_FEEDBACK,
  });
  return response.data;
};

export const suggestNewIntent = async ({
  event_id,
  intent_name,
  description,
}: {
  event_id: string;
  intent_name: string;
  description: string;
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.SUGGEST_INTENT,
    args: { event_id, intent_name, description },
  });
};

export const deescalateTagState = async (tagJobId: number): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.DEESCALATE_TAG_STATE,
    args: {
      tag_job_id: tagJobId,
    },
  });
};

export const updateEscalationComment = async ({
  tagJobId,
  comment,
}: {
  tagJobId: number;
  comment: string;
}): Promise<void> => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.UPDATE_ESCALATION_COMMENT,
    args: {
      tag_job_id: tagJobId,
      comment: comment,
    },
  });
};

export const updateEscalationFilterTags = async ({
  tagJobId,
  filterTags,
}: {
  tagJobId: number;
  filterTags: EscalationFilterTag[];
}) => {
  await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.UPDATE_ESCALATION_FILTER_TAGS,
    args: {
      tag_job_id: tagJobId,
      filter_tags: filterTags,
    },
  });
};

type AitDTO = {
  approved_jobs: object[];
  approved_scopes: string[];
  email: string;
  id: string;
  last_heartbeat: string;
  name: string;
};
export const getAitFromID = async (aitId: string): Promise<AitDTO> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_AIT_FROM_ID,
    args: { id: aitId },
  });

  return response.data;
};

export const getOnlineAits = async (
  withinLastNMinutes: number
): Promise<AitDTO[]> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_ONLINE_AITS,
    args: { within_last_n_minutes: withinLastNMinutes },
  });

  return response.data;
};

type Pool = {
  ESCALATION: { [scopeName: string]: number };
  OFFLINE: { [scopeName: string]: number };
  ONLINE: { [scopeName: string]: number };
};
type Pools = {
  in_progress: Pool;
  tagged: Pool;
  untagged: Pool;
};
export const getPoolSizes = async (): Promise<Pools> => {
  const response = await getTaggingApi().post('/v1/tagStateController', {
    method: TaggingApiMethods.GET_POOL_SIZES,
  });

  return response.data;
};
