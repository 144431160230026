import { CSSProperties, RefObject } from 'react';

export const getHorizontalPopoverStylesFromRefs = (
  containerRef: RefObject<HTMLElement>,
  popoverRef: RefObject<HTMLElement>,
  customStyles?: CSSProperties
) => {
  const screenPadding = 16;

  const containerRect = containerRef.current?.getBoundingClientRect();
  const popoverRect = popoverRef.current?.getBoundingClientRect();

  if (!containerRect || !popoverRect) {
    return {};
  }

  const dropdownRightX = popoverRect.x + popoverRect.width;
  const placeholderRightX = containerRect.x + containerRect.width;

  if (popoverRect.x < 0) {
    return {
      top: containerRect.height,
      left: '0',
      right: 'auto',
      transform: `translateX(${-containerRect.x + screenPadding}px)`,
    };
  } else if (dropdownRightX > window.outerWidth) {
    return {
      top: containerRect.height,
      left: 'auto',
      right: '0',
      transform: `translateX(${
        window.outerWidth - placeholderRightX - screenPadding
      }px)`,
    };
  }

  return { ...customStyles };
};

export const getVerticalPopoverStyles = (
  containerRef: RefObject<HTMLElement>,
  popoverRef: RefObject<HTMLElement>
) => {
  if (!containerRef.current || !popoverRef.current) {
    return {};
  }
  const position = getComputedStyle(containerRef.current).position;

  switch (position?.toLowerCase()) {
    case 'relative': {
      return getAboveOrBelowSelectPopoverStyles(containerRef, popoverRef);
    }
    case 'absolute':
    default: {
      return getAboveOrBelowSelectPopoverStylesForNonRelativePosition(
        containerRef,
        popoverRef
      );
    }
  }
};

export const getAboveOrBelowSelectPopoverStyles = (
  containerRef: RefObject<HTMLElement>,
  popoverRef: RefObject<HTMLElement>
) => {
  const containerRect = containerRef.current?.getBoundingClientRect();
  const popoverRect = popoverRef.current?.getBoundingClientRect();
  if (!containerRect || !popoverRect) {
    return {};
  }

  const dropdownTopY =
    popoverRect.y + popoverRect.height + containerRect.height * 1.5;

  if (dropdownTopY > window.outerHeight) {
    return {
      bottom: containerRect.height,
      top: undefined,
    };
  }

  return { top: containerRect.height, bottom: undefined };
};

export const getAboveOrBelowSelectPopoverStylesForNonRelativePosition = (
  containerRef: RefObject<HTMLElement>,
  popoverRef: RefObject<HTMLElement>
) => {
  const containerRect = containerRef.current?.getBoundingClientRect();
  const popoverRect = popoverRef.current?.getBoundingClientRect();

  if (
    !containerRect ||
    !popoverRect ||
    !containerRef.current ||
    !popoverRef.current
  ) {
    return {};
  }

  const offsetTop = getRelativeContextOffsetTop(containerRef.current);

  return {
    top: isWithinRelativeContext(containerRef.current, containerRect.top)
      ? undefined
      : containerRect.y + containerRect.height - offsetTop,
  };
};

export const getPopoverStylesFromElements = (
  container: HTMLElement | null,
  popover: HTMLElement | null
) => {
  if (!container || !popover) {
    return {};
  }
  const screenPadding = 16;

  const containerRect = container.getBoundingClientRect();
  const popoverRect = popover.getBoundingClientRect();

  if (!containerRect || !popoverRect) {
    return {};
  }

  const dropdownRightX = popoverRect.x + popoverRect.width;
  const placeholderRightX = containerRect.x + containerRect.width;

  if (popoverRect.x < 0) {
    return {
      top: containerRect.height,
      left: '0',
      right: 'auto',
      transform: `translateX(${-containerRect.x + screenPadding}px)`,
    };
  } else if (dropdownRightX > window.outerWidth) {
    return {
      top: containerRect.height,
      left: 'auto',
      right: '0',
      transform: `translateX(${
        window.outerWidth - placeholderRightX - screenPadding
      }px)`,
    };
  }

  return {};
};

const isWithinRelativeContext = (
  element: HTMLElement | null,
  originalTopValue: number
): boolean => {
  if (!element) {
    return false;
  }

  const computedStyles = getComputedStyle(element);
  const position = computedStyles.position;
  if (position?.toLowerCase() === 'relative') {
    const boundingRect = element.getBoundingClientRect();
    return originalTopValue === boundingRect.top;
  }

  return isWithinRelativeContext(element.parentElement, originalTopValue);
};

const getRelativeContextOffsetTop = (element: HTMLElement | null): number => {
  if (!element) {
    return 0;
  }

  const computedStyles = getComputedStyle(element);
  const position = computedStyles.position;
  if (
    position?.toLowerCase() === 'relative' ||
    position.toLowerCase() === 'absolute'
  ) {
    const boundingRect = element.getBoundingClientRect();
    return boundingRect.top - element.scrollTop;
  }

  return getRelativeContextOffsetTop(element.parentElement);
};
