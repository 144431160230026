import Portal from '@material-ui/core/Portal';
import clsx from 'clsx';
import { PureComponent } from 'react';
import ReactDOM from 'react-dom';

import { Toast, ToastProps } from './Toast';
import styles from './Toaster.module.scss';
import { isBottom, isLeft, isRight, ToasterPosition } from './ToasterPosition';

export interface ToasterProps {
  /**
   * Position of `Toaster` within its container
   *
   * @default ToasterPosition.TOP
   */
  position?: ToasterPosition;

  /**
   * The maximum number of active toasts that can be displayed at once.
   * When the limit is about to be exceeded, the oldest active toast is removed
   */
  maxToasts?: number;

  /**
   * Whether the toaster should be rendered into a new element attached to `document.body`.
   * If `false`, then positioning will be relative to the parent element
   *
   * This prop is ignored by `Toaster.create()` as that method always appends a new element
   * to the container
   *
   * @default true
   */
  usePortal?: boolean;
}

interface ToasterState {
  toasts: (ToastProps & { key: string })[];
}

export interface Toaster {
  /**
   * Shows a new toast to the user, or updates an existing toast corresponding to the provided key (optional).
   *
   * Returns the unique key of the toast.
   */
  show(props: ToastProps, key?: string): string;

  /**
   * Dismiss the given toast instantly.
   */
  dismiss(key: string): void;

  /**
   * Dismiss all toasts instantly.
   */
  clear(): void;
}

// Using a class component here because ReactDOM.render
// returns null
export class Toaster
  extends PureComponent<ToasterProps, ToasterState>
  implements Toaster
{
  // For toasts without an id, we keep track of a counter
  // to provide them one
  private toastIdCounter = 0;
  public state: ToasterState = {
    toasts: [],
  };

  show = (props: ToastProps, key?: string): string => {
    if (this.props.maxToasts) {
      this.dismissIfAtLimit();
    }
    const options = this.createToastOptions(props, key);
    if (key === undefined || this.isNewToastKey(key)) {
      this.setState((prevState) => ({
        toasts: [options, ...prevState.toasts].map((t, i) => ({
          ...t,
          index: i,
        })),
      }));
    } else {
      this.setState((prevState) => ({
        toasts: prevState.toasts.map((t) => (t.key === key ? options : t)),
      }));
    }
    return options.key;
  };

  dismiss(key: string, timeoutExpired = false): void {
    this.setState(({ toasts }) => ({
      toasts: toasts
        .filter((t) => {
          const matchesKey = t.key === key;
          if (matchesKey) {
            t.onDismiss?.(timeoutExpired);
          }
          return !matchesKey;
        })
        .map((t, i) => ({
          ...t,
          index: i,
          didYoungerSiblingDismiss: false,
        })),
    }));
  }

  clear = (): void => {
    this.state.toasts.forEach((t) => t.onDismiss?.(false));
    this.setState({ toasts: [] });
  };

  render = () => {
    if (!this.props.usePortal) {
      return (
        <div className={this.getToasterClassNames()}>
          <div className={styles.toaster}>
            {this.state.toasts.map(this.renderToast, this)}
          </div>
        </div>
      );
    }
    return (
      <Portal>
        <div className={this.getToasterClassNames()}>
          <div className={styles.toaster}>
            {this.state.toasts.map(this.renderToast, this)}
          </div>
        </div>
      </Portal>
    );
  };

  private dismissIfAtLimit = () => {
    if (
      this.state.toasts.length === this.props.maxToasts &&
      this.state.toasts[this.state.toasts.length - 1].key
    ) {
      // dismiss the oldest toast to stay within the maxToasts limit
      this.dismiss(this.state.toasts[this.state.toasts.length - 1].key);
    }
  };

  private createToastOptions = (
    props: ToastProps,
    key = `toast-${this.toastIdCounter++}`
  ) => {
    return { ...props, key };
  };

  private getDismissHandler =
    (toast: ToastProps & { key: string }) => (timeoutExpired: boolean) => {
      this.setState(({ toasts }) => {
        let hasToastBeenFound = false;
        const isToastBottom = isBottom(
          this.props.position ?? ToasterPosition.Top
        );
        return {
          toasts: toasts.map((t, i) => {
            if (hasToastBeenFound && !isToastBottom) {
              return {
                ...t,
                didYoungerSiblingDismiss: true,
              };
            }
            const currentToast =
              isToastBottom && !hasToastBeenFound
                ? {
                    ...t,
                    didYoungerSiblingDismiss: true,
                  }
                : t;
            const toastToReturn =
              t.key === toast.key ? { ...t, forceClose: true } : currentToast;
            hasToastBeenFound = hasToastBeenFound || t.key === toast.key;
            return toastToReturn;
          }),
        };
      });
      setTimeout(
        () => this.dismiss(toast.key, timeoutExpired),
        300 *
          1.1 /* time of the animation plus some time to allow effects to take place */
      );
    };

  private renderToast = (toast: ToastProps & { key: string }) => {
    return <Toast {...toast} onDismiss={this.getDismissHandler(toast)} />;
  };

  private isNewToastKey = (key: string) => {
    return this.state.toasts.every((toast) => toast.key !== key);
  };

  private getToasterClassNames = () =>
    clsx(styles.toasterWrapper, {
      [styles.inPortal]: this.props.usePortal,
      [styles.inline]: !this.props.usePortal,
      [styles.bottom]: isBottom(this.props.position ?? ToasterPosition.Top),
      [styles.top]: !isBottom(this.props.position ?? ToasterPosition.Top),
      [styles.left]: isLeft(this.props.position ?? ToasterPosition.Top),
      [styles.right]: isRight(this.props.position ?? ToasterPosition.Top),
    });
}

export const createToaster = (
  props?: ToasterProps,
  container = document.body
): Toaster => {
  const containerElement = document.createElement('div');
  container.appendChild(containerElement);
  const toaster = ReactDOM.render<ToasterProps>(
    <Toaster {...props} usePortal />,
    containerElement
  ) as unknown as Toaster;
  if (toaster == null) {
    throw new Error('Failed to create toaster');
  }
  return toaster;
};
