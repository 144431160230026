import { Dialog, Icon, IconName } from '@blueprintjs/core';
import { useState } from 'react';

import { Tab } from '../../components/Tabs/Tab';
import { Tabs } from '../../components/Tabs/Tabs';

import styles from './TimexChart.module.scss';
import TimexMoveInChart from './TimexMoveInChart';
import TimexSchedulingChart from './TimexSchedulingChart';

const TimexHelpPopover = ({ iconName }: { iconName: IconName }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Icon icon={iconName} onMouseEnter={() => setIsOpen(true)} />
      <Dialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <TimexHelpContent />
      </Dialog>
    </>
  );
};

const TimexHelpContent = () => {
  return (
    <Tabs id="TimexHelpPopover" isSticky>
      <Tab
        id="scheduling"
        title="Scheduling"
        component={<TimexSchedulingChart />}
        className={styles.chartContainer}
      />
      <Tab
        id="moveIn"
        title="Move In"
        component={<TimexMoveInChart />}
        className={styles.chartContainer}
      />
    </Tabs>
  );
};

export default TimexHelpPopover;
