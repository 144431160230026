import { H4 } from '@blueprintjs/core';

type Props = {
  stateId: string;
  scopeLabelForStateViewer?: string;
};

export default function ConversationStateHeader({
  stateId,
  scopeLabelForStateViewer,
}: Props) {
  return (
    <H4 className="d-flex flex-wrap">
      <span>
        state {stateId}{' '}
        {<span className="text-muted">• {scopeLabelForStateViewer}</span>}
      </span>
    </H4>
  );
}
