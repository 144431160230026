import { FunctionComponent } from 'react';

export const SelectIcon: FunctionComponent = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M-3.05176e-05 1.1928L1.16372 0.0290527L4.65497 3.5203L8.14622 0.0290527L9.30997 1.1928L4.65497 5.8478L-3.05176e-05 1.1928Z"
        fill="#83818E"
      />
    </svg>
  );
};
