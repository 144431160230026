/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BookTourByStateIdDto = {
    mcId?: string;
    stateId?: string;
    buildingId?: number;
    tourType?: BookTourByStateIdDto.tourType;
    startTime?: string;
    endTime?: string;
    useLegacyContext?: boolean;
    sms?: boolean;
}

export namespace BookTourByStateIdDto {

    export enum tourType {
        UNKNOWN = 'UNKNOWN',
        SELF_GUIDED = 'SELF_GUIDED',
        VIRTUAL_SHOWING = 'VIRTUAL_SHOWING',
        WITH_AGENT = 'WITH_AGENT',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
