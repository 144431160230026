import { H1 } from '@blueprintjs/core';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import { Container, Row } from 'reactstrap';

import Navbar from './Navbar';
import styles from './page.module.scss';

interface Props {
  children?: React.ReactNode;
  title?: string;
  showNav?: boolean;
  fluid?: boolean;
  className?: string;
  style?: CSSProperties;
}

/**
 * The default page layout, a bootstrap <Container /> beneath a navbar,
 * with an optional title.
 *
 * Could probably be expanded to accept breadcrumbs props, but it's JSX,
 * so either the parent component passes an array or adds jsx to the
 * children, and both approaches are entirely declarative.
 */
export default function BlueprintPage({
  children,
  title,
  showNav = true,
  fluid = false,
  className,
  style,
}: Props) {
  // Currently renders children in a Reactstrap <Container />. If this
  // needs to be made more flexible in the future while keeping
  // backward compatibility, <BlueprintPage /> can accept a
  // `withContainer` prop that defaults to true, or a `container` prop
  // that defaults to Reactstrap's <Container />.
  return (
    <>
      {showNav ? <Navbar /> : null}
      <Container
        fluid={fluid}
        className={clsx('pt-3', 'pb-5', 'flex-grow-1', styles.page, className)}
        style={style}
      >
        {title && (
          <Row noGutters className="justify-content-center">
            <H1>{title}</H1>
          </Row>
        )}
        {children}
      </Container>
    </>
  );
}
