/**
 * Permissions a user can have.
 *
 * Use [the Auth0 config](https://manage.auth0.com/dashboard/us/meetelise/apis/6036b32e56aca50040fd6085/permissions) as the source of truth.
 */
enum Permission {
  // For internal users only
  READ_ORGS = 'read:orgs',
  // For internal users and org-level admins only
  READ_BUILDINGS = 'read:buildings',

  READ_REPORTS = 'read:reports',
  READ_ORG = 'read:org',
  UPDATE_ORG = 'update:org',
  CREATE_ORGS = 'create:orgs',
  UPDATE_ORGS = 'update:orgs',
  DELETE_ORGS = 'delete:orgs',
  READ_AI_ASSISTANTS = 'read:ai_assistants',
  CREATE_AI_ASSISTANTS = 'create:ai_assistants',
  UPDATE_AI_ASSISTANTS = 'update:ai_assistants',
  DELETE_AI_ASSISTANTS = 'delete:ai_assistants',
  READ_BUILDING = 'read:building',
  UPDATE_BUILDING = 'update:building',
  UPDATE_BUILDING_THEME = 'update:building_theme',
  CREATE_BUILDINGS = 'create:buildings',
  UPDATE_BUILDINGS = 'update:buildings',
  DELETE_BUILDINGS = 'delete:buildings',
  READ_DOOR_KEYS = 'read:door_keys',
  CREATE_DOOR_KEYS = 'create:door_keys',
  UPDATE_DOOR_KEYS = 'update:door_keys',
  DELETE_DOOR_KEYS = 'delete:door_keys',
  READ_RESPONSE_TEMPLATES = 'read:response_templates',
  CREATE_RESPONSE_TEMPLATES = 'create:response_templates',
  UPDATE_RESPONSE_TEMPLATES = 'update:response_templates',
  DELETE_RESPONSE_TEMPLATES = 'delete:response_templates',
  READ_CALENDAR_EVENTS = 'read:calendar_events',
  CREATE_CALENDAR_EVENTS = 'create:calendar_events',
  UPDATE_CALENDAR_EVENTS = 'update:calendar_events',
  DELETE_CALENDAR_EVENTS = 'delete:calendar_events',
  READ_INTERNAL_USERS = 'read:internal_users',
  CREATE_INTERNAL_USERS = 'create:internal_users',
  UPDATE_INTERNAL_USERS = 'update:internal_users',
  DELETE_INTERNAL_USERS = 'delete:internal_users',
  READ_EXTERNAL_USERS = 'read:external_users',
  CREATE_EXTERNAL_USERS = 'create:external_users',
  UPDATE_EXTERNAL_USERS = 'update:external_users',
  DELETE_EXTERNAL_USERS = 'delete:external_users',
  READ_REDESIGN = 'read:redesign',
  READ_DASHBOARD = 'read:dashboard',
  READ_TAGGING_DEV_MODE = 'read:tagging_dev_mode',
  READ_TAGGING_DEBUGGER = 'read:tagging_app_debugger',
  READ_TAGGING_ESCALATION_POOL = 'read:tagging_escalation_pool',
  READ_REPORTS_V2 = 'read:reports_v2',
  READ_AIT_INTENT_CREATOR = 'read:ait_intent_creator',
}

export default Permission;
