import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Hook to listen to the current URL search params.
 *
 * It structures the search params using URLSearchParams, while
 * react-router-dom only returns them as a string.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 */
export function useQueryParams() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
