import { Colors, H6 } from '@blueprintjs/core';
import { useClipboard } from '@mantine/hooks';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import parseISO from 'date-fns/parseISO';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import api from '../../../api/axios';
import MessageDTO from '../../../state-tagger/message/MessageDTO';
import { Brainfreeze } from '../../../state-viewer/brainfreezeTypes';
import { ModelIO } from '../../../state-viewer/TimetravelPage';
import { useStateContext } from '../../../StateContext/StateContext';
import { appToaster } from '../../../vanillaelise/appToaster';
import EventView from '../../EventView/EventView';
import { findParty } from '../../utils/conversationObjectUtils';
import { useFullConversationContext } from '../../utils/useFullConversationContext';
import DebuggingInfoCard from '../DebuggingInfoCard';

import { EventFilterMap } from './EventFilter';

interface EventViewContainerProps {
  filter: EventFilterMap;
  viewFullContext?: boolean;
}
type StateViewerItem = Brainfreeze | ModelIO | MessageDTO;
export type DebuggingItem = Brainfreeze | ModelIO;
export type StateViewerCardItem = MessageDTO | DebuggingItem[];

const isBrainfreeze = (item: StateViewerItem): item is Brainfreeze =>
  'thought' in item;
const isTimetravel = (item: StateViewerItem): item is ModelIO =>
  'output_time' in item;
const isEvent = (
  item: StateViewerItem | DebuggingItem[]
): item is MessageDTO => {
  return 'time_sent' in item;
};

const getTimestamp = (item: StateViewerItem) => {
  if (isBrainfreeze(item)) {
    return parseISO(item.thought.time);
  } else if (isTimetravel(item)) {
    return parseISO(item.output_time);
  } else if (isEvent(item)) {
    return parseISO(item.time_sent);
  }
};

/**
 * Container for events displayed on the StateView page. Implements sorting and filtering logic.
 * @returns
 */
export default function EventViewContainer({
  filter,
  viewFullContext,
}: EventViewContainerProps) {
  const { state } = useStateContext();
  const { data: fullContextEvents } = useFullConversationContext(state.id, {
    enabled: viewFullContext,
  });
  const eventsToUse = useMemo(
    () => (viewFullContext ? fullContextEvents ?? [] : state.events),
    [fullContextEvents, viewFullContext, state.events]
  );
  const { data: brainfreezes } = useQuery(
    ['brainfreezesForState', state.id],
    async () => {
      return (
        await api.get<Brainfreeze[]>(
          `../../platformApi/brainfreeze/state/${state.id}`
        )
      ).data;
    }
  );

  const fetchInputOutput = (stateId: string): Promise<ModelIO[]> => {
    return api
      .post('../../conversationApi/extra', {
        method: 'GetModelIOsSlim',
        state_id: stateId,
      })
      .then((res) => res.data)
      .then((res) => res.ios);
  };
  const { data: timetravels } = useQuery(['modelIosForState', state.id], () =>
    fetchInputOutput(state.id)
  );

  const allItems = useMemo(
    () => [...eventsToUse, ...(brainfreezes || []), ...(timetravels || [])],
    [brainfreezes, eventsToUse, timetravels]
  );
  const allItemsSorted = useMemo(
    () => orderBy(allItems, (el) => getTimestamp(el)?.getTime(), filter.sort),
    [allItems, filter.sort]
  );

  // Separate allItems into groups.
  // Each event will be its own group.
  // Events will be separated by groups of Timetravel/Brainfreeze
  // items that occurred between the events
  const groupedItems = useMemo(
    () =>
      allItemsSorted.reduce(
        (
          acc: StateViewerCardItem[],
          cur: MessageDTO | DebuggingItem
        ): StateViewerCardItem[] => {
          if (isEvent(cur)) {
            return [...acc, cur];
          } else {
            const lastItem = acc[acc.length - 1];

            if (lastItem && isEvent(lastItem)) {
              return [...acc, [cur]];
            } else if (lastItem) {
              return [...acc.slice(0, -1), [...lastItem, cur]];
            } else {
              return [...acc.slice(0, -1), [cur]];
            }
          }
        },
        []
      ),
    [allItemsSorted]
  );

  const { copy } = useClipboard();
  // If you hit the bottom of the page while scrolling, the math
  // gets unhappy and so you end up with some wonky behavior.
  const [preventFurtherAutoScrolling, setPreventFurtherAutoScrolling] =
    useState(false);

  const defaultTimezone =
    findParty(state.parties, 'building')?.timezone ?? 'America/New_York';

  const groupedByDate = groupBy(groupedItems, (group) => {
    if (isEvent(group)) {
      const date = new Date(group.time_sent);
      return format(
        utcToZonedTime(date, defaultTimezone),
        'EEEE LLLL d, y',
        {}
      );
    } else {
      if (isTimetravel(group[0])) {
        const date = new Date(group[0].output_time);
        return format(utcToZonedTime(date, defaultTimezone), 'EEEE LLLL d, y');
      }
      if (isBrainfreeze(group[0])) {
        const date = new Date(group[0].thought.time);
        return format(utcToZonedTime(date, defaultTimezone), 'EEEE LLLL d, y');
      }
    }
  });
  return (
    <div style={{ marginTop: '1rem' }}>
      {Object.entries(groupedByDate).map(([date, items]) => {
        return (
          <>
            <H6
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: Colors.GRAY1,
                fontStyle: 'italic',
              }}
            >
              {date}
            </H6>
            {items.map((item) => {
              if (isEvent(item)) {
                if (
                  !filter.checkboxGroups?.eventTypes.checkboxes[item.type]
                    .checked ||
                  (filter.toggleGroups?.debugging.toggles.hidePredicted
                    .checked &&
                    item.predicted)
                ) {
                  return null;
                }
                return (
                  <EventView
                    key={item.id}
                    event={item}
                    defaultTimezone={defaultTimezone}
                    className="mb-3"
                    onEventIdClick={(eventId) => {
                      setPreventFurtherAutoScrolling(true);
                      copy(
                        `${window.location.origin}/state/${state.id}#${eventId}`
                      );
                      appToaster.success('Link to event copied');
                    }}
                    preventAutoScrolling={preventFurtherAutoScrolling}
                    stateId={state.id}
                  />
                );
              }
              if (
                filter.toggleGroups?.debugging.toggles.hideDebuggingInfo.checked
              ) {
                return null;
              }
              return (
                <DebuggingInfoCard
                  debuggingItems={item}
                  key={item[0].id}
                  timezone={defaultTimezone}
                  stateId={state.id}
                />
              );
            })}
          </>
        );
      })}
    </div>
  );
}
