import axios, { AxiosTransformer } from 'axios';
import pako from 'pako';

import { getTokenOrThrow } from '../../auth/auth0';

const LOCAL_STORAGE_DEV_MODE_KEY = 'isTaggingDevMode';
const SESSION_STORAGE_TAGGING_SESSION_ID = 'taggingSessionId';

export const isTaggingDevMode = () => {
  return localStorage.getItem(LOCAL_STORAGE_DEV_MODE_KEY) === 'true';
};

export const switchTaggingDevMode = (turnOn: boolean) => {
  if (turnOn) {
    localStorage.setItem(LOCAL_STORAGE_DEV_MODE_KEY, 'true');
  } else {
    localStorage.removeItem(LOCAL_STORAGE_DEV_MODE_KEY);
  }
};

export const setTagSessionId = (sessionId: string) => {
  sessionStorage.setItem(SESSION_STORAGE_TAGGING_SESSION_ID, sessionId);
};

export const getTaggingApi = () => {
  const taggingBaseUrl = isTaggingDevMode() ? '/devTaggingApi' : '/taggingApi';
  const defaultTransformers = (): AxiosTransformer[] => {
    const { transformRequest } = axios.defaults;
    if (!transformRequest) {
      return [];
    } else if (transformRequest instanceof Array) {
      return transformRequest;
    } else {
      return [transformRequest];
    }
  };

  const instance = axios.create({
    baseURL: taggingBaseUrl,
    transformRequest: [
      ...defaultTransformers(),
      (data, headers) => {
        // hacky solution - compress and send payload as b64 string in json
        // API gateway would decompress plain gzip before lambda invocation exceeding size limit
        // if (typeof data === 'string' && data.length > 1024) {
        //   const b64payload = Buffer.from(pako.gzip(data)).toString('base64');
        //   return JSON.stringify({ payload: b64payload });
        // } else {
        return data;
        // }
      },
    ],
  });

  instance.interceptors.request.use(async (config) => {
    config.headers.Authorization = `Bearer ${await getTokenOrThrow()}`;
    config.headers['tagging-session-id'] = sessionStorage.getItem(
      SESSION_STORAGE_TAGGING_SESSION_ID
    );

    config.headers['tagging-action-timestamp'] = new Date().getTime();
    return config;
  });
  instance.interceptors.response.use(undefined, function (error) {
    // If the session_id doesn't match the backend, we get a "I am toaster" error.
    // In this situation, we can reload the page (fetching the new state in progress)
    if (error?.response?.status === 418) {
      window.alert(
        'Warning: you have a state open in another tab. Please try to avoid this. This page will reload.'
      );
      window.location.reload();
    } else {
      return Promise.reject(error);
    }
  });
  return instance;
};
