import { FunctionComponent } from 'react';

import { LoadingIndicator } from '../../vanillaelise/LoadingIndicator/LoadingIndicator';
import { NonIdealState } from '../../vanillaelise/NonIdealState/NonIdealState';

import VanillaScssPage, { VanillaScssPageProps } from './VanillaScssPage';

export const VanillaScssLoadingPage: FunctionComponent<
  { title?: string } & VanillaScssPageProps
> = ({ title, ...props }) => {
  return (
    <VanillaScssPage {...props}>
      <NonIdealState title={title} attentionGrabber={<LoadingIndicator />} />
    </VanillaScssPage>
  );
};
