import { useCallback, useRef } from 'react';

export function useRefWithCallback<T extends HTMLElement>(
  callback?: (node: T) => void
) {
  const ref = useRef<T | null>(null);
  const setRef = useCallback(
    (node: T) => {
      if (!node) {
        return;
      }

      callback?.(node);
      ref.current = node;
    },
    [callback]
  );

  return { ref, setRef };
}
