/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MEHomeLead } from './MEHomeLead';

export type MEHomeTourInfo = {
    lead: MEHomeLead;
    hostVirtualTourUrl?: string;
    publicVirtualTourUrl?: string;
    tourType?: MEHomeTourInfo.tourType;
    leadStatusForContext?: number;
    notes?: string;
    conversationType?: MEHomeTourInfo.conversationType;
}

export namespace MEHomeTourInfo {

    export enum tourType {
        UNKNOWN = 'UNKNOWN',
        SELF_GUIDED = 'SELF_GUIDED',
        VIRTUAL_SHOWING = 'VIRTUAL_SHOWING',
        WITH_AGENT = 'WITH_AGENT',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }

    export enum conversationType {
        EMAIL = 'EMAIL',
        PHONE = 'PHONE',
    }


}
