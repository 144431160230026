import { HandlerType, datadogLogs, StatusType } from '@datadog/browser-logs';

type LogFn = (msg: string, ...ctx: any[]) => void;

interface Logger extends LogFn {
  debug: LogFn;
  log: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
  /**
   * Extend logger to a namespace.
   *
   * Returns another logger. All messages to this logger will
   * be prefixed with the namespace.
   */
  scope: (namespace: string) => Logger;
}

type Level = 'debug' | 'info' | 'warn' | 'error';

function makeLogger(namespace: string): Logger {
  const ddLogger = datadogLogs.createLogger(namespace);
  ddLogger.addContext('scope', namespace);

  if (process.env.NODE_ENV !== 'production') {
    ddLogger.setHandler(HandlerType.console);
  }
  if (process.env.NODE_ENV === 'test') {
    ddLogger.setLevel(StatusType.warn);
  }

  const logLvl =
    (lvl: Level) =>
    (msg: string, ...ctx: any[]) => {
      ddLogger[lvl](msg, ctx[0]);
    };

  return Object.assign(logLvl('info'), {
    debug: logLvl('debug'),
    info: logLvl('info'),
    log: logLvl('info'),
    warn: logLvl('warn'),
    error: logLvl('error'),
    scope: (subNamespace: string) => makeLogger(`${namespace}:${subNamespace}`),
  });
}

/**
 * The default logger for the whole app.
 *
 * Has methods `debug`, `log`, `info`, `warn`, and `error` to log
 * unstructured data at various levels. This can be called as a
 * function to log any sort of data, at the default level, which is
 * `log`.
 *
 * The logger can also be extended to a namespace, using
 * `const featureLog = log.scope('myFeature')` in order to get a logger
 * specific to a namespace.
 */
const log: Logger = makeLogger('elise');

export default log;
