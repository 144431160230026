import { useHistory } from 'react-router-dom';

import {
  VanillaScssButton,
  VanillaScssButtonType,
} from '../components/VanillaScssButton/VanillaScssButton';

import { VanillaScssErrorPage } from './VanillaScssPage/VanillaScssErrorPage';

export default function NotFoundPage() {
  const history = useHistory();
  return (
    <VanillaScssErrorPage
      title="Unfortunately, this page is not available."
      action={
        <VanillaScssButton
          text="Back to MeetElise"
          buttonType={VanillaScssButtonType.Primary}
          onClick={() => history.push('/')}
        />
      }
    />
  );
}
