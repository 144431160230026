/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FirstContactOption = {
    value: FirstContactOption.value;
    label: string;
}

export namespace FirstContactOption {

    export enum value {
        CALL_FROM_PROSPECT = 'CALL_FROM_PROSPECT',
        CALL_TO_PROSPECT = 'CALL_TO_PROSPECT',
        EMAIL = 'EMAIL',
        WALK_IN = 'WALK_IN',
        TEXT = 'TEXT',
        WEB_SERVICE = 'WEB_SERVICE',
        CHAT = 'CHAT',
        OTHER = 'OTHER',
    }


}
