import { FC, ReactNode } from 'react';

import { useMaybeUser } from './hooks';
import Permission from './Permission';

interface AuthorizerProps {
  /**
   * Content to render when the user is authorized.
   */
  children: ReactNode;
  /**
   * Content to render when the user is not authorized. By default, nothing.
   */
  fallback?: ReactNode;
  /**
   * Permission required to view the content. If neither permission nor
   * roles are provided, restricts its content to authenticated users.
   */
  permission?: Permission;
  /**
   * List of allowed permissions. If the user has any, they are authorized.
   */
  somePermission?: Permission[];
}

/**
 * A component to restrict access to child components based on role.
 *
 * By default, renders null if the user is anonymous, otherwise renders
 * its children. The allowed roles and fallback can both be customized.
 *
 * A NOTE ON AUTHORIZATION IN THE BROWSER:
 *
 * The user has complete control over the browser, so authorization in
 * frontend code has no effect on the security of the app. If you want
 * to protect something, put it behind an API with proper authn/authz
 * controls, and query it from the frontend.
 *
 * Frontend authorization is for UX, not security. It handles the use
 * case of "show or hide these controls", not "show or hide this
 * sensitive info". That way, if the user circumvents the frontend
 * authorization and makes the "Fire missiles" button appear, they'll
 * still get a 403 Unauthorized when they click it.
 */
export default function Authorizer({
  children,
  fallback = null,
  permission,
  somePermission,
}: AuthorizerProps) {
  const user = useMaybeUser();
  const authorized =
    user &&
    (!permission || user.permissions.includes(permission)) &&
    (!somePermission ||
      somePermission.some((p) => user.permissions.includes(p)));

  return <>{authorized ? children : fallback}</>;
}

export const InternalOnly: FC<Omit<AuthorizerProps, 'roles'>> = (props) => (
  <Authorizer permission={Permission.READ_ORGS} {...props} />
);
