import { Classes, Colors } from '@blueprintjs/core';
import {
  Classes as TClasses,
  Tooltip2 as Tooltip,
} from '@blueprintjs/popover2';
import clsx from 'clsx';

import PartyDTO from '../../../state-tagger/party/PartyDTO';
import {
  getDisplayName,
  pluckPartyProperty,
} from '../../utils/conversationObjectUtils';

interface PartyViewTextProps {
  party: PartyDTO | string;
  isFrom?: boolean;
}

export default function PartyViewText({ party, isFrom }: PartyViewTextProps) {
  if (typeof party === 'string') return <span>{party}</span>;
  const displayName = getDisplayName(party);
  const { properties, timezone } = party;

  // For presentational purposes, it makes sense to look through
  // the `properties` array and treat some of them differently,
  // then display the rest. If special properties like 'email' are
  // found, we want to remove them from the 'rest' list that we display.
  const mutablePropertiesCopy = [...properties];

  const email = pluckPartyProperty('email-address', mutablePropertiesCopy);
  const phone = pluckPartyProperty('phone-number', mutablePropertiesCopy);

  const content = (
    <div>
      <p className="m-0">
        <strong>{displayName}</strong>
      </p>
      {email && <p className="m-0">Email: {email}</p>}
      {phone && <p className="m-0">Phone: {phone}</p>}
      {timezone && <p className="m-0">Timezone: {timezone}</p>}
      {<p className="m-0">Party ID: {party.name}</p>}
      {mutablePropertiesCopy.map((property, i) => (
        <div key={`${property.name}-${i}`} className="my-2">
          <p className="m-0">
            {property.name.replace('property-', '')}
            {property.text ? `: ${property.text}` : null}
          </p>
          {property.classifications?.map((c, i) => {
            const text = `${c.name}: ${c.selected?.join(', ')}`;
            return (
              <p
                key={`${text}-${i}`}
                className={clsx('m-0', 'pl-3', Classes.TEXT_SMALL)}
              >
                {text}
              </p>
            );
          })}
        </div>
      ))}
    </div>
  );
  return (
    <div className="d-inline-flex align-items-center">
      <Tooltip content={content} className={TClasses.TOOLTIP2_INDICATOR}>
        <strong>{displayName}</strong>
      </Tooltip>
      {party.name.startsWith('lead') && isFrom && (
        <div
          style={{
            backgroundColor: Colors.LIGHT_GRAY1,
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            marginLeft: '.5rem',
          }}
        />
      )}
      {party.name.startsWith('elise') && isFrom && (
        <div
          style={{
            backgroundColor: Colors.BLUE3,
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            marginLeft: '.5rem',
          }}
        />
      )}
    </div>
  );
}
