import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';

import AuthUser from './AuthUser';

/**
 * Hook to get the logged in user, or null if anonymous.
 */
export const useMaybeUser = () => {
  const auth0 = useAuth0();
  const user: AuthUser | null = useMemo(
    () => (auth0.user ? new AuthUser(auth0.user) : null),
    [auth0.user]
  );
  return user;
};

/**
 * Hook to get the logged in user.
 *
 * Throws if the user isn't logged in.
 */
export const useAuthUser = () => {
  const user = useMaybeUser();
  if (!user) throw new Error('Do not use useAuthUser outside of withAuth()');
  return user;
};
