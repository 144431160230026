import { AnchorButton } from '@blueprintjs/core';
import { useLocalStorage } from '@mantine/hooks';
import mapValues from 'lodash/mapValues';
import { useState } from 'react';
import { useQuery } from 'react-query';

import api from '../../api/axios';
import Navbar from '../../layouts/BlueprintPage/Navbar';
import { MessageType } from '../../state-tagger/message/MessageType';
import ViewRawStateButton from '../../state-tagger/state/ViewRawStateButton';
import ToursDrawerButton from '../../state-tagger/tours/ToursDrawerButton';
import UnitsDrawerButton from '../../state-tagger/units/UnitsDrawerButton';
import { useStateContext } from '../../StateContext/StateContext';
import { eventColors } from '../EventView/EventView';

import ConversationStateHeader from './components/ConversationStateHeader';
import EventFilter, { EventFilterMap } from './components/EventFilter';
import EventViewContainer from './components/EventViewContainer';
import PartyButtonGroup from './components/PartyButtonGroup';
import styles from './StateView.module.css';

const useFetchConversationUrl = (stateId: string) => {
  return useQuery(['fetchConversationUrl', stateId], () =>
    api
      .get(`../../platformApi/state/${stateId}/convLink`)
      .then((res) => res.data)
  );
};

/**
 * A read only page that displays a conversation state.
 * This page is intended to be used as an internal utility for debugging that is full decoupled from the tagging app
 *
 */

export default function StateView() {
  const { state } = useStateContext();
  const [viewFullContext, setViewFullContext] = useState(false);
  const {
    isLoading,
    data: conversationUrl,
    error,
  } = useFetchConversationUrl(state.id);

  const initialFilter: EventFilterMap = {
    sort: 'desc',
    toggleGroups: {
      debugging: {
        displayName: 'Debugging',
        toggles: {
          hidePredicted: {
            label: 'Hide Predicted',
            checked: false,
          },
          hideDebuggingInfo: {
            label: 'Hide Debugging Info',
            checked: false,
          },
        },
      },
    },
    checkboxGroups: {
      eventTypes: {
        displayName: 'Event Types',
        checkboxes: {},
      },
    },
  };

  if (initialFilter.checkboxGroups) {
    initialFilter.checkboxGroups['eventTypes'].checkboxes = mapValues(
      MessageType,
      (type) => ({
        label: type,
        checked: true,
        color: eventColors[type],
      })
    );
  }

  const [filter, setFilter] = useLocalStorage({
    key: 'state_viewer_filter',
    defaultValue: initialFilter,
  });

  //Header Stuff
  //TODO: Pull this logic into the sub component

  let scopeLabelForStateViewer;
  if ('scope_keys' in state) {
    const {
      name: scopeName = '(missing)',
      version: scopeVersion = '(missing)',
    } = (state?.scope_keys as Array<Array<any>>)?.[0]?.[0] || {};
    scopeLabelForStateViewer = `scope: ${scopeName} v${scopeVersion}`;
  } else {
    scopeLabelForStateViewer = `scope: ${state.scope} `;
  }

  return (
    <div style={{ margin: '0 5%' }}>
      <Navbar />
      <div className={styles.stickyHeader}>
        <p className="d-flex align-items-center my-1">
          <span className="mr-1">
            <ConversationStateHeader
              stateId={state.id}
              scopeLabelForStateViewer={scopeLabelForStateViewer}
            />
          </span>
          <span className="ml-auto">
            <UnitsDrawerButton stateId={state.id} />
            <ToursDrawerButton stateId={state.id} />
            <AnchorButton
              href={`/state/${state.id}/brainfreeze`}
              text={'Brainfreezes'}
              minimal
              icon="predictive-analysis"
              target="_blank"
              rel="noopener noreferrer"
            />
            <AnchorButton
              href={`/state/${state.id}/timetravel`}
              text={'Time travel'}
              minimal
              icon="time"
              target="_blank"
              rel="noopener noreferrer"
            />
            <ViewRawStateButton state={state} className="mr-2" />
            <AnchorButton
              text="Markers"
              icon="code"
              href={`/state/${state.id}/markers`}
              minimal
              target="_blank"
              rel="noopener noreferrer"
            />
            <AnchorButton
              text="Guest Card"
              icon="code"
              href={`/state/${state.id}/guestCard`}
              minimal
              target="_blank"
              rel="noopener noreferrer"
            />
            <AnchorButton
              text="Conv link"
              icon="chat"
              href={!isLoading || !error ? conversationUrl : ''}
              minimal
              disabled={!!isLoading || !!error}
              target="_blank"
              rel="noopener noreferrer"
            />
          </span>
        </p>
      </div>
      <div className={styles.pageContainer}>
        <div className={styles.sideBar}>
          <div className={styles.stickyHeader}>
            <PartyButtonGroup
              parties={state.parties}
              scopeName={state.scope}
              readOnly
              vertical
            />
            <EventFilter
              setFilter={setFilter}
              filter={filter}
              viewFullContext={viewFullContext}
              onSetViewFullContext={(value) => setViewFullContext(value)}
            />
          </div>
        </div>
        <div className={styles.mainPage}>
          <section>
            <EventViewContainer
              filter={filter}
              viewFullContext={viewFullContext}
            />
          </section>
        </div>
      </div>
    </div>
  );
}
