/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommunityFormView } from '../models/CommunityFormView';
import type { CommunityPageBuilding } from '../models/CommunityPageBuilding';
import type { CommunitySearchTableResult } from '../models/CommunitySearchTableResult';
import type { UiBuildingDetails } from '../models/UiBuildingDetails';
import type { UpdateEmailsRequest } from '../models/UpdateEmailsRequest';
import { request as __request } from '../core/request';

export class BuildingControllerService {

    /**
     * @param id
     * @returns UiBuildingDetails OK
     * @throws ApiError
     */
    public static async get4(
        id: number,
    ): Promise<UiBuildingDetails> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/building/${id}`,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns UiBuildingDetails OK
     * @throws ApiError
     */
    public static async update5(
        id: number,
        requestBody: CommunityFormView,
    ): Promise<UiBuildingDetails> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param primaryColor
     * @param backgroundColor
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async putSstTheme(
        id: number,
        primaryColor: string,
        backgroundColor: string,
        requestBody?: any,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/${id}/sst-theme`,
            query: {
                'primaryColor': primaryColor,
                'backgroundColor': backgroundColor,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param title
     * @param subtitle
     * @param bannerColor
     * @param bannerTextColor
     * @param messageColor
     * @param messageTextColor
     * @param launchButtonColor
     * @param launchButtonIconColor
     * @param launchButtonSize
     * @param avatarType
     * @param avatarInitials
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async putChatTheme(
        id: number,
        title: string,
        subtitle: string,
        bannerColor: string,
        bannerTextColor: string,
        messageColor: string,
        messageTextColor: string,
        launchButtonColor: string,
        launchButtonIconColor: string,
        launchButtonSize: string,
        avatarType: string,
        avatarInitials: string,
        requestBody?: any,
    ): Promise<any> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/${id}/chat-theme`,
            query: {
                'title': title,
                'subtitle': subtitle,
                'bannerColor': bannerColor,
                'bannerTextColor': bannerTextColor,
                'messageColor': messageColor,
                'messageTextColor': messageTextColor,
                'launchButtonColor': launchButtonColor,
                'launchButtonIconColor': launchButtonIconColor,
                'launchButtonSize': launchButtonSize,
                'avatarType': avatarType,
                'avatarInitials': avatarInitials,
            },
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async updateWeeklyReportEmails(
        id: number,
        requestBody: UpdateEmailsRequest,
    ): Promise<string> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/weeklyReportEmails/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async updateHandoffForwardEmails(
        id: number,
        requestBody: UpdateEmailsRequest,
    ): Promise<string> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/handoffForwardEmails/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async updateDailyReportEmails(
        id: number,
        requestBody: UpdateEmailsRequest,
    ): Promise<string> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/building/dailyReportEmails/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async getBuildingTimezoneMap(
        requestBody: Array<number>,
    ): Promise<Record<string, string>> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/building/buildingTimeZones`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns boolean OK
     * @throws ApiError
     */
    public static async getActiveBuildingsMap(
        requestBody: Array<number>,
    ): Promise<Record<string, boolean>> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/building/buildingStatuses`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async getBuildingNameMap(
        requestBody: Array<number>,
    ): Promise<Record<string, string>> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/building/buildingNames`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async getBuildingAiAgentNameMap(
        requestBody: Array<number>,
    ): Promise<Record<string, string>> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/building/buildingAiAgents`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @returns CommunityPageBuilding OK
     * @throws ApiError
     */
    public static async getAll1(): Promise<Array<CommunityPageBuilding>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/building/`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns UiBuildingDetails OK
     * @throws ApiError
     */
    public static async create2(
        requestBody: CommunityFormView,
    ): Promise<UiBuildingDetails> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/building/`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param page
     * @param searchQuery
     * @returns CommunitySearchTableResult OK
     * @throws ApiError
     */
    public static async getPage(
        page: number,
        searchQuery?: string,
    ): Promise<CommunitySearchTableResult> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/building/page`,
            query: {
                'page': page,
                'searchQuery': searchQuery,
            },
        });
        return result.body;
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static async deleteLogo(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/building/${id}/logo`,
        });
        return result.body;
    }

    /**
     * @param id
     * @returns any OK
     * @throws ApiError
     */
    public static async deleteAvatar(
        id: number,
    ): Promise<any> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/v1/building/${id}/avatar`,
        });
        return result.body;
    }

}