import { FC } from 'react';

import { assertIsValidColor } from '../../vanillaelise/color-fns';

interface CheckIconProps {
  /**
   * A CSS value determining what color the checkmark should be
   *
   * @default defaultGreen
   */
  color?: string;
}

const defaultGreen = '#00AC11';

export const CheckIcon: FC<CheckIconProps> = ({ color = defaultGreen }) => {
  assertIsValidColor(color);

  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5972 0.315751C12.0926 0.774398 12.1372 1.56449 11.6969 2.08047L5.29689 9.58046C5.06917 9.84732 4.74277 10 4.4 10C4.05724 10 3.73083 9.84732 3.50311 9.58046L0.303121 5.83046C-0.13718 5.31448 -0.0925628 4.52439 0.402775 4.06575C0.898113 3.6071 1.6566 3.65358 2.0969 4.16955L4.4 6.86851L9.9031 0.419558C10.3434 -0.0964196 11.1019 -0.142896 11.5972 0.315751Z"
        fill={color}
      />
    </svg>
  );
};
