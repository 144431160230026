/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EliseCalendarEvent = {
    id?: number;
    leadId?: number;
    userId?: number;
    calendarId?: number;
    contextId?: number;
    recurringEventId?: number;
    timeCreated?: string;
    description?: string;
    status?: string;
    summary?: string;
    eventLocation?: string;
    startTime?: string;
    endTime?: string;
    startTimeString?: string;
    endTimeString?: string;
    startTimeInUtc?: number;
    endTimeInUtc?: number;
    lastTimeUpdated?: string;
    recurringEvent?: boolean;
    recurringEventLatestTimestampStoredUntil?: string;
    preserveOnReset?: number;
    internalEvent?: number;
    skipExternalBooking?: number;
    ignoreOtherEvents?: number;
    htmlLink?: string;
    geteTag?: string;
    getiCalUID?: string;
    eventId?: string;
    creatorName?: string;
    creatorEmail?: string;
    organizerName?: string;
    organizerEmail?: string;
    transparency?: string;
    visibility?: string;
    colorId?: string;
    availabilityStatus?: number;
    relevantMessageId?: string;
    isPredicted?: number;
    isArchived?: number;
    smartLockAccessType?: EliseCalendarEvent.smartLockAccessType;
    traineeEmail?: string;
    notes?: string;
    tourType?: EliseCalendarEvent.tourType;
    cancelled?: boolean;
    busy?: boolean;
    new?: boolean;
}

export namespace EliseCalendarEvent {

    export enum smartLockAccessType {
        WITH_AGENT = 'WITH_AGENT',
        EXTERNALLY_MANAGED = 'EXTERNALLY_MANAGED',
        SMART_KEY_ACCESS_MANAGED = 'SMART_KEY_ACCESS_MANAGED',
    }

    export enum tourType {
        UNKNOWN = 'UNKNOWN',
        NONE = 'NONE',
        ANY = 'ANY',
        NOT_ESCORTED = 'NOT_ESCORTED',
        ESCORTED = 'ESCORTED',
        NOT_SELF_GUIDED = 'NOT_SELF_GUIDED',
        SELF_GUIDED = 'SELF_GUIDED',
        NOT_VIRTUAL_TOUR = 'NOT_VIRTUAL_TOUR',
        VIRTUAL_TOUR = 'VIRTUAL_TOUR',
        NOT_MEDIA_TOUR = 'NOT_MEDIA_TOUR',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
