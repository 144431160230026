/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RecurringEventUpdateRequestDto = {
    startTime?: string;
    endTime?: string;
    daysOfWeek?: Array<number>;
    daysOfMonth?: Array<number>;
    startOfRecurrence?: string;
    endOfRecurrence?: string;
    frequency?: RecurringEventUpdateRequestDto.frequency;
    frequencyMultiplier?: number;
    summary?: string;
}

export namespace RecurringEventUpdateRequestDto {

    export enum frequency {
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
    }


}
