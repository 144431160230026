import clsx from 'clsx';
import { FunctionComponent } from 'react';

import { TabProps } from './Tab';
import styles from './TabTitle.module.scss';

export interface TabTitleProps extends TabProps {
  /** Handler invoked when this tab is clicked. */
  onClick: (id: string | number, event: React.MouseEvent<HTMLElement>) => void;

  /** ID of the parent `Tabs` to which this tab belongs. Used to generate ID for ARIA attributes. */
  parentId: string | number;

  /** Whether the tab is currently selected. */
  isSelected: boolean;
}

export const TabTitle: FunctionComponent<TabTitleProps> = ({
  className,
  children,
  disabled,
  id,
  parentId,
  isSelected,
  title,
  onClick,
}) => {
  return (
    <div
      aria-controls={generateTabComponentId(parentId, id)}
      aria-disabled={disabled}
      aria-expanded={isSelected}
      aria-selected={isSelected}
      className={getTabTitleClassName(isSelected, className)}
      data-tab-id={id}
      id={generateTabTitleId(parentId, id)}
      onClick={(e) => onClick(id, e)}
      role="tab"
      tabIndex={disabled ? undefined : 0}
    >
      {title}
      {children}
    </div>
  );
};

export const generateTabComponentId = (
  parentId: string | number,
  tabId: string | number
) => {
  return `tab-component_${parentId}_${tabId}`;
};

export const generateTabTitleId = (
  parentId: string | number,
  tabId: string | number
) => {
  return `tab-title_${parentId}_${tabId}`;
};

const getTabTitleClassName = (isSelected: boolean, className?: string) =>
  clsx(className, styles.tabTitle, 'tab-title', {
    [styles.isSelected]: isSelected,
  });
