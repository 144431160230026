/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlertQueueInfo = {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    handoffStatus?: number;
    dateUpdated?: string;
    conversationType?: AlertQueueInfo.conversationType;
    alertType?: string;
    contextId?: number;
    conversationKey?: string;
    failureReason?: string;
}

export namespace AlertQueueInfo {

    export enum conversationType {
        EMAIL = 'EMAIL',
        PHONE = 'PHONE',
    }


}
