import isArray from 'lodash/isArray';

import PartyDTO from '../../state-tagger/party/PartyDTO';

import PartyViewText from './components/PartyViewText';

interface PartyViewProps {
  selectedParties: PartyDTO | string | (PartyDTO | string)[];
  isFrom?: boolean;
}

/**
 * A react component to represent a Party
 * @param param0
 *
 */
export default function PartyView({ selectedParties, isFrom }: PartyViewProps) {
  if (isArray(selectedParties)) {
    return (
      <>
        {selectedParties.map((partyOrName) => (
          <PartyViewText
            key={partyOrName.toString()}
            party={partyOrName}
            isFrom={isFrom}
          />
        ))}
      </>
    );
  } else {
    return <PartyViewText party={selectedParties} isFrom={isFrom} />;
  }
}
