import { Auth0Client } from '@auth0/auth0-spa-js';
import defaultTo from 'lodash/fp/defaultTo';

import { assertString } from '../assert';

assertString(process.env.REACT_APP_OAUTH_DOMAIN);
assertString(process.env.REACT_APP_OAUTH_CLIENT_ID);

const client = new Auth0Client({
  domain: process.env.REACT_APP_OAUTH_DOMAIN,
  client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  redirect_uri: window.location.origin,
  audience: process.env.REACT_APP_OAUTH_AUDIENCE,
  issuer: process.env.REACT_APP_OAUTH_ISSUER,
  cacheLocation: 'localstorage',
});

/**
 * Get a valid JWT token to authenticate to an API.
 *
 * Throws if not authenticated.
 */
export const getTokenOrThrow: () => Promise<string> = async () => {
  return await client.getTokenSilently();
};

/**
 * Get a valid JWT token to authenticate to an API.
 *
 * Returns null if not authenticated.
 */
export const getTokenOrNull: () => Promise<string | null> = async () => {
  try {
    const token = await client.getTokenSilently();
    return token;
  } catch (e) {
    const isAuthed = await client.isAuthenticated();
    if (!isAuthed) return null;
    // If the error is for a reason other than not being
    // authenticated, raise it to the caller.
    throw e;
  }
};

/**
 * Get a valid JWT token to authenticate to an API.
 *
 * Returns an empty string if not authenticated.
 */
export const getTokenOrEmptyString: () => Promise<string> = () =>
  getTokenOrNull().then(defaultTo(''));
