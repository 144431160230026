import { FunctionComponent } from 'react';

import { ReactComponent as DefaultCloseIcon } from './svgs/close/close-default.svg';
import { ReactComponent as InversedCloseIcon } from './svgs/close/close-inverse.svg';

export const CloseIcon: FunctionComponent<{ isInverse?: boolean }> = ({
  isInverse,
}) => {
  if (isInverse) {
    return <InversedCloseIcon />;
  }
  return <DefaultCloseIcon />;
};
