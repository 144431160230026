import { QueryConfig, useQuery } from 'react-query';

import api from '../../api/axios';
import MessageDTO from '../../state-tagger/message/MessageDTO';

export const useFullConversationContext = (
  stateId: string,
  opts?: QueryConfig<MessageDTO[], unknown>
) =>
  useQuery(
    ['useFullConversationContext', stateId],
    async () => {
      return await getFullConversationContext(stateId);
    },
    opts
  );

export const getFullConversationContext = async (stateId: string) => {
  const result = await api.get<MessageDTO[]>(
    `../../platformApi/state/${stateId}/merged_events`
  );
  return result.data;
};
