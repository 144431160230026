import { ReportHandler } from 'web-vitals';

import baseLog from './log';

const log = baseLog.scope('web-vitals');

const reportMetric: ReportHandler = (metric) => {
  log(`Web-vitals: ${metric.name}:`, metric);
};

const reportWebVitals = () => {
  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(reportMetric);
    getFID(reportMetric);
    getFCP(reportMetric);
    getLCP(reportMetric);
    getTTFB(reportMetric);
  });
};

export default reportWebVitals;
