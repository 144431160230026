export enum Colors {
  Alert = '#CE0000',

  DarkGold = '#B77900',

  BlackText = '#202020',

  PrimaryBlue = '#24358D',

  SecondaryOrange = '#FF8B00',

  GoldenOrange = '#E9BB01',

  LighterGoldenOrange = '#fce6bb',

  SkyBlue = '#65eaf6',

  LighterSkyBlue = '#cafaff',

  InactiveGrey = '#83818E',

  White = '#FFFFFF',

  None = 'none',

  SolidInPersonTour = '#6E3DE6',

  SolidVirtualTour = '#4A5EB5',

  SolidSelfGuidedTour = '#009257',

  SolidInternalEventNonBlocking = '#9B2500',

  SolidInternalEventBlocking = '#83818E',

  BackgroundColor = '#f5f7f9',

  GoodGreen = '#00AC11',

  LightGrey = '#E3E3E3',

  Lavender = '#EDD7FB',

  MagentaIsh = '#754F8D',

  MellowYellow = '#b86d12',

  PrimaryColor2 = '#8C58E5',
}
