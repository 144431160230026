import { NonIdealState } from '@blueprintjs/core';
import { ReactChild } from 'react';

import { ExclamationCircleFill } from '../components/Icons';

import BlueprintPage from './BlueprintPage';

interface ErrorPageProps {
  title: string;
  action?: JSX.Element;
  description?: ReactChild;
}

export default function ErrorPage({
  title,
  action,
  description,
}: ErrorPageProps) {
  return (
    <BlueprintPage>
      <NonIdealState
        title={title}
        icon={<ExclamationCircleFill />}
        action={action}
        description={description}
      />
    </BlueprintPage>
  );
}
