/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateScriptRequest = {
    organizationId?: number;
    media: CreateScriptRequest.media;
    type: CreateScriptRequest.type;
    category: string;
    subIntent?: string;
    text: string;
    handoffIntentState?: CreateScriptRequest.handoffIntentState;
    communityId?: number;
}

export namespace CreateScriptRequest {

    export enum media {
        BOTH = 'BOTH',
        EMAIL = 'EMAIL',
        SMS = 'SMS',
    }

    export enum type {
        ALL = 'ALL',
        TEMPLATE = 'TEMPLATE',
        TEMPLATE_ITEM = 'TEMPLATE_ITEM',
    }

    export enum handoffIntentState {
        NONE = 'NONE',
        YELLOW_STATUS = 'YELLOW_STATUS',
        RED_ALERT_SEND = 'RED_ALERT_SEND',
        RED_ALERT_DO_NOT_SEND = 'RED_ALERT_DO_NOT_SEND',
    }


}
