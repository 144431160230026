/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConversationMessageV2 = {
    type?: ConversationMessageV2.type;
    id?: string;
    timeSent?: string;
    body?: string;
    sender?: string;
    subject?: string;
    threadId?: string;
}

export namespace ConversationMessageV2 {

    export enum type {
        WEB_CHAT = 'WebChat',
        EMAIL = 'Email',
        TEXT = 'Text',
    }


}
