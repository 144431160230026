/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelActionDto } from '../models/CancelActionDto';
import type { NoShowActionDto } from '../models/NoShowActionDto';
import type { RescheduleConfirmedDto } from '../models/RescheduleConfirmedDto';
import type { RescheduleProposedDto } from '../models/RescheduleProposedDto';
import type { ScheduleDto } from '../models/ScheduleDto';
import type { SendAgentMessageDto } from '../models/SendAgentMessageDto';
import type { ShowActionDto } from '../models/ShowActionDto';
import type { WalkInAndShowActionDto } from '../models/WalkInAndShowActionDto';
import type { WalkInOrCallActionDto } from '../models/WalkInOrCallActionDto';
import { request as __request } from '../core/request';

export class ActionConsumerApiControllerService {

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async walkInOrCallCreate(
        requestBody: WalkInOrCallActionDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/walkInOrCall`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async walkInAndShowCreate(
        requestBody: WalkInAndShowActionDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/walkInAndShow`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param contextId
     * @param leasingAgentId
     * @returns string OK
     * @throws ApiError
     */
    public static async updateAgentOnContext(
        contextId: number,
        leasingAgentId: number,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/updateAgentOnContext/${contextId}/${leasingAgentId}`,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async createShowAction(
        requestBody: ShowActionDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/show`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async sendAgentMessage(
        requestBody: SendAgentMessageDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/sendAgentMessage`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async scheduleAction(
        requestBody: ScheduleDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/schedule`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async createRescheduleProposedAction(
        requestBody: RescheduleProposedDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/rescheduleProposed`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async createRescheduleConfirmedAction(
        requestBody: RescheduleConfirmedDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/rescheduleConfirmed`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async createNoShowAction(
        requestBody: NoShowActionDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/noShow`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns string OK
     * @throws ApiError
     */
    public static async cancel(
        requestBody: CancelActionDto,
    ): Promise<string> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/actions/cancel`,
            body: requestBody,
        });
        return result.body;
    }

}