import { FunctionComponent } from 'react';

import { DisplayIconProps } from './DisplayIconProps';
import { ReactComponent as DefaultAlertIcon } from './svgs/alert/alert.svg';
import { ReactComponent as InverseAlertIcon } from './svgs/alert/icon-alert-inverse.svg';

export const AlertIcon: FunctionComponent<DisplayIconProps> = ({
  isInverse,
  isActive,
  isRight,
  ...restProps
}) => {
  if (isInverse) {
    return <InverseAlertIcon {...restProps} />;
  }
  return <DefaultAlertIcon {...restProps} />;
};
