import { Button, ControlGroup, HTMLSelect } from '@blueprintjs/core';
import { useEffect, useState } from 'react';
import { TableInstance } from 'react-table';

import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from '../Icons';

interface PaginationControlsProps<D extends object> {
  className: string;
  instance: TableInstance<D>;
}

export default function PaginationControls<D extends object>({
  className,
  instance,
}: PaginationControlsProps<D>) {
  const initialPageText = String(instance.state.pageIndex + 1);
  const [pageText, setPageText] = useState(initialPageText);
  useEffect(() => {
    setPageText(initialPageText);
  }, [initialPageText]);

  return (
    <ControlGroup className={className}>
      <Button
        icon={<ChevronDoubleLeft title="Go to first page" />}
        disabled={!instance.canPreviousPage}
        onClick={() => instance.gotoPage(0)}
      />
      <Button
        icon={<ChevronLeft title="Go to previous page" />}
        disabled={!instance.canPreviousPage}
        onClick={() => {
          instance.previousPage();
        }}
      />
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <label htmlFor="page-input" className="m-0">
          Page
        </label>
        <input
          id="page-input"
          className="mx-2"
          type="number"
          value={pageText}
          min={1}
          max={instance.pageCount}
          onChange={(e) => {
            setPageText(e.target.value);
            const newPage = parseInt(e.target.value, 10) - 1;
            if (newPage) instance.gotoPage(newPage);
          }}
        />
        <span>of {instance.pageCount}</span>
        <HTMLSelect
          className="ml-3"
          value={instance.state.pageSize}
          onChange={(e) => instance.setPageSize(parseInt(e.target.value))}
        >
          <option value="10">10 Rows</option>
          <option value="20">20 Rows</option>
          <option value="50">50 Rows</option>
          <option value="100">100 Rows</option>
        </HTMLSelect>
      </div>
      <Button
        icon={<ChevronRight title="Go to next page" />}
        disabled={!instance.canNextPage}
        onClick={() => {
          instance.nextPage();
        }}
      />
      <Button
        icon={<ChevronDoubleRight title="Go to last page" />}
        disabled={!instance.canNextPage}
        onClick={() => instance.gotoPage(instance.pageCount - 1)}
      />
    </ControlGroup>
  );
}
