import {
  Card,
  Elevation,
  Intent as UIIntent,
  Colors,
  Tag,
  Classes,
  Button,
} from '@blueprintjs/core';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useState } from 'react';

import appToaster from '../../appToaster';
import MessageDTO from '../../state-tagger/message/MessageDTO';
import { MessageType } from '../../state-tagger/message/MessageType';
import PartyDTO from '../../state-tagger/party/PartyDTO';
import { useStateContext } from '../../StateContext/StateContext';
import { safelyRenderMessageHtml } from '../../utils';
import { TextClassNames } from '../../vanillaelise/TextClassNames';
import IntentViewContainer from '../IntentView/IntentViewContainer';
import PartyView from '../PartyView/PartyView';

import styles from './EventView.module.css';
import ToggleableEmailBodyView from './ToggleableEmailBodyView';
import ToggleableOracleBodyView from './ToggleableOracleBodyView';
import { useRelatedEntityIds } from './useRelatedEntityIds';

/**
 * Component for rendering an Event object
 * Currently a wrapper around MessageCard component from existing app (should decouple from tagging app)
 * Later -> to be updated and customized
 */

interface EventViewProps {
  /** Event to render */
  event: MessageDTO;
  className?: string;
  defaultTimezone?: string;
  onEventIdClick: (eventId: string) => void;
  preventAutoScrolling?: boolean;
  stateId: string;
}

export const eventColors = {
  [MessageType.ConversationSystem]: Colors.BLUE3,
  [MessageType.SmsEvent]: Colors.GREEN3,
  [MessageType.Email]: Colors.GREEN3,
  [MessageType.MEChatMessage]: Colors.GREEN3,
  [MessageType.ReminderEvent]: Colors.ORANGE3,
  [MessageType.OracleEvent]: Colors.RED3,
  [MessageType.PmsEvent]: Colors.VIOLET3,
  [UIIntent.NONE]: undefined,
};

export default function EventView({
  event,
  className,
  defaultTimezone,
  onEventIdClick,
  preventAutoScrolling = false,
  stateId,
}: EventViewProps) {
  const queriedEventId = window.location.hash
    ? window.location.hash.substring(1)
    : null;
  const [hasDoneInitialScroll, setHasDoneInitialScroll] = useState(false);
  /** Local variables using DTO */
  const { parties } = useStateContext().state;
  const sender: PartyDTO | string | undefined | null =
    parties.find((p) => p.name === event.sender_party_name) ||
    event.sender_party_name;
  const recipients = event.receiver_party_names?.map(
    (name) => parties.find((p) => p.name === name) ?? name
  );
  const buildingPartyId = parties?.find((p) => p.name.includes('building'));
  const { isLoading: isGettingRelatedEntityIds, refetch: getRelatedEntityIds } =
    useRelatedEntityIds(buildingPartyId?.name ?? '', { enabled: false });
  const senderTimeZone =
    sender && typeof sender !== 'string' ? sender.timezone : undefined;
  const displayTimezone = defaultTimezone ? defaultTimezone : senderTimeZone;
  const timestamp = DateTime.fromISO(event.time_sent, {
    setZone: !displayTimezone, // If there's no timezone, fall back to the offset
    zone: displayTimezone,
  });

  const equalSpacingNeeded = event.type === 'OracleEvent' && !event.predicted;
  const messageStyle = equalSpacingNeeded
    ? { fontFamily: 'courier', fontSize: '14px' }
    : {};
  const isEventTaggedByAIT = event.intents.filter((intent) => {
    return intent.intent_creator && intent.intent_creator.startsWith('AIT');
  }).length;

  const color = eventColors[event.type as MessageType];

  const shouldShowCalendarSnapshotRangeButton =
    parties &&
    buildingPartyId &&
    event.intents.some(
      (intent) => intent.name === 'request-proximate-tour-availability'
    ) &&
    event.sender_party_name &&
    event.sender_party_name.toLowerCase().includes('oracle');
  return (
    <Card
      className={clsx(styles.card, className)}
      style={color ? { borderTop: `.25rem solid ${color}` } : undefined}
      elevation={Elevation.TWO}
    >
      <div
        ref={(node) => {
          if (!node) {
            return;
          }
          if (
            !queriedEventId ||
            event.id !== queriedEventId ||
            preventAutoScrolling
          ) {
            return;
          }
          if (hasDoneInitialScroll) {
            return;
          }

          const boundingRect = node.getBoundingClientRect();
          if (window.scrollY > boundingRect.top - boundingRect.height) {
            setHasDoneInitialScroll(true);
            return;
          }

          node.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }}
      >
        <div>
          <>
            <Tag
              intent={UIIntent.NONE}
              className={styles.eventTypeTag}
              large={true}
              style={{ backgroundColor: `${color}` }}
            >
              {event.type}
            </Tag>
            <br />
          </>
        </div>
        {event.predicted ? (
          <Tag
            intent={UIIntent.NONE}
            className={styles.predictedTag}
            large={true}
          >
            Predicted
          </Tag>
        ) : null}
        {isEventTaggedByAIT ? (
          <Tag intent={UIIntent.NONE} className={styles.aitTag} large={true}>
            AIT
          </Tag>
        ) : null}
        <p className="d-flex align-items-center my-0">
          <span className="mr-1">From </span>
          {sender ? (
            <PartyView selectedParties={sender} isFrom />
          ) : (
            <div>-</div>
          )}
          <span className="ml-auto">
            {`${timestamp.toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )} - ${timestamp.toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)}`}
          </span>
        </p>

        <p className="d-flex align-items-center my-0">
          {recipients && recipients.length ? (
            <p className="mb-0">
              To <PartyView selectedParties={recipients || ''} />
            </p>
          ) : null}
          <span className="ml-auto">
            Event ID:{' '}
            <span
              className={clsx(TextClassNames.LinkText, styles.eventId)}
              onClick={() => {
                onEventIdClick(event.id);
              }}
            >
              {event.id}
            </span>
          </span>
        </p>
        {shouldShowCalendarSnapshotRangeButton && (
          <div className="d-flex w-100 justify-content-end mt-2">
            <Button
              text="Calendar snapshot"
              loading={isGettingRelatedEntityIds}
              onClick={async () => {
                const relatedEntityIds = await getRelatedEntityIds();
                if (!relatedEntityIds || relatedEntityIds.length !== 1) {
                  appToaster.toastError(new Error('No related entities found'));
                  return;
                }
                const calendarEntityId = relatedEntityIds[0];
                window.open(
                  `https://app.meetelise.com/entity/${calendarEntityId}/snapshots/range?timeTo=${event.time_sent}`
                );
              }}
            />
          </div>
        )}
        {event.type !== MessageType.Email &&
          (event.message ? (
            <div
              className={styles.displayLinebreak}
              style={messageStyle}
              dangerouslySetInnerHTML={{
                __html: safelyRenderMessageHtml(event.message || ''),
              }}
            />
          ) : (
            <div className={styles.displayLinebreak} style={messageStyle}>
              <span className={Classes.TEXT_MUTED}>(No message text)</span>
            </div>
          ))}
        {event.type === MessageType.Email && (
          <ToggleableEmailBodyView
            message={event.message}
            body={event.body}
            iframeTitle={event.id}
          />
        )}
        {event.type === MessageType.OracleEvent && (
          <ToggleableOracleBodyView stateId={stateId} eventId={event.id} />
        )}

        <IntentViewContainer
          maxHeight="400px"
          horizontal
          event={event}
          intents={event.intents}
        />
      </div>
    </Card>
  );
}
