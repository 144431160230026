/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BuildingPublicViewModel } from '../models/BuildingPublicViewModel';
import { request as __request } from '../core/request';

export class PublicBuildingControllerService {

    /**
     * @param uuid
     * @returns number OK
     * @throws ApiError
     */
    public static async getOrgIdFromUuid(
        uuid: string,
    ): Promise<number> {
        const result = await __request({
            method: 'GET',
            path: `/api/pub/v1/organization/${uuid}`,
        });
        return result.body;
    }

    /**
     * @param organizationSlug
     * @param buildingSlug
     * @returns BuildingPublicViewModel OK
     * @throws ApiError
     */
    public static async getPublicBuildingInfo(
        organizationSlug: string,
        buildingSlug: string,
    ): Promise<BuildingPublicViewModel> {
        const result = await __request({
            method: 'GET',
            path: `/api/pub/v1/organization/${organizationSlug}/building/${buildingSlug}`,
        });
        return result.body;
    }

}