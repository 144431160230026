export enum MessageType {
  ConversationSystem = 'ConversationSystem',
  MEChatMessage = 'MEChatMessage',
  SmsEvent = 'SmsEvent',
  Email = 'Email',
  OracleEvent = 'OracleEvent',
  ReminderEvent = 'ReminderEvent',
  PmsEvent = 'PmsEvent',
}

export const getMessageTypeText = (eventType: string) => {
  switch (eventType) {
    case MessageType.ConversationSystem: {
      return 'System';
    }
    case MessageType.MEChatMessage: {
      return 'Webchat';
    }
    case MessageType.SmsEvent: {
      return 'SMS';
    }
    case MessageType.Email: {
      return 'Email';
    }
    case MessageType.ReminderEvent: {
      return 'Reminder';
    }
    case MessageType.OracleEvent: {
      return 'Oracle event';
    }
    case MessageType.PmsEvent: {
      return 'PMS event';
    }
    default: {
      throw new Error(`${eventType} is not a recognized event type`);
    }
  }
};
