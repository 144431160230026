import {
  Tag,
  Intent as UIIntent,
  FormGroup,
  TextArea,
} from '@blueprintjs/core';
import clsx from 'clsx';
import { useState } from 'react';

interface IntentTextInputProps {
  text: string;
  onChangeText?(newText: string): void;
  className?: string;
  disabled?: boolean;
}

/**
 * A tag to display and edit the text property for an Intent.
 */
export default function IntentTextInput(props: IntentTextInputProps) {
  const { text, onChangeText, className, disabled } = props;
  const [currentText, setCurrentText] = useState(text);

  if (disabled) {
    return (
      <Tag large intent={UIIntent.PRIMARY} className={className}>
        Text: <strong>{text}</strong>
      </Tag>
    );
  }

  return (
    <FormGroup style={{ marginBottom: 0 }} label={'Text'} inline>
      <TextArea
        rows={1}
        className={clsx('d-inline-block', className)}
        value={currentText}
        onChange={(e) => {
          if (e.target.scrollHeight > e.target.clientHeight) {
            e.target.rows += 1;
          }
          setCurrentText(e.target.value);
        }}
        onBlur={() => onChangeText?.(currentText)}
        onKeyPress={(e) => e.key === 'Enter' && onChangeText?.(currentText)}
        // leftElement={<Tag intent={UIIntent.PRIMARY}>Text</Tag>}
        intent={UIIntent.PRIMARY}
      />
    </FormGroup>
  );
}
