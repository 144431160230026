import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { initialize } from 'launchdarkly-js-client-sdk';
import LogRocket from 'logrocket';
import { Suspense, StrictMode, useEffect } from 'react';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';

import { useMaybeUser } from '../auth/hooks';
import { VanillaScssLoadingPage } from '../layouts/VanillaScssPage/VanillaScssLoadingPage';

import AppErrorBoundary from './AppErrorBoundary';
import { AppRouteSwitch } from './AppRouteSwitch';

export const launchDarklyClient = initialize(
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? '',
  { key: '' }
);

export default function App() {
  const queryCache = new QueryCache();
  useAddHeapAnalytics();
  useUserInTelemetry();

  return (
    <StrictMode>
      <AppErrorBoundary>
        <Suspense
          fallback={
            <VanillaScssLoadingPage
              showNav={false}
              title="Preparing your MEHome experience"
            />
          }
        >
          <ReactQueryCacheProvider queryCache={queryCache}>
            <AppRouteSwitch />
            <ReactQueryDevtools />
          </ReactQueryCacheProvider>
        </Suspense>
      </AppErrorBoundary>
    </StrictMode>
  );
}

/**
 * Hook to identify user to Datadog and Logrocket, when one is first provided.
 *
 * This doesn't really need to be implemented as a hook (or in React at all),
 * but the auth0 SDK doesn't give us callbacks to register. If it did, we could
 * instead register a callback in **initialize.ts** like
 * `auth0.onUserLogin(logging.setUser);` or such.
 */
const useUserInTelemetry = () => {
  const user = useMaybeUser();
  useEffect(() => {
    if (!user) return;

    datadogLogs.addLoggerGlobalContext('user', user);
    datadogRum.setUser({ id: user.id, name: user.name, email: user.email });
    if (process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID) {
      launchDarklyClient.identify({
        key: user.id,
        name: user.name,
        email: user.email,
      });
    }

    if (
      user.email &&
      !window.navigator.userAgent.includes('DatadogSynthetics') &&
      process.env.REACT_APP_LOGROCKET_KEY
    ) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
      LogRocket.identify(user.email, {
        email: user.email,
        organizationId: user.organizationId,
        role: user.permissions.join(','),
        communityIds: JSON.stringify(user.communityIds),
      });
    }

    const heap = (window as any).heap;
    if (heap) {
      heap.identify(user.id);
      heap.addUserProperties({ Email: user.email });
    }
  }, [user]);
  return null;
};

/**
 * Add heap when running in production.
 */
const useAddHeapAnalytics = () => {
  useEffect(() => {
    if (window.location.hostname.includes('localhost')) {
      return;
    }

    const script = document.createElement('script');

    const heapProdId = process.env.REACT_APP_HEAP_ANALYTICS_PROD_ID;
    if (!heapProdId) {
      return;
    }

    script.innerText = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${heapProdId}");`;
    document.head.appendChild(script);
  }, []);
};
