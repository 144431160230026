/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type WhiteListTestStepDto = {
    displayText: string;
    step: WhiteListTestStepDto.step;
    orderInTest: number;
    failureReason?: string;
    warnings?: Array<string>;
    complete?: boolean;
    nextStep?: boolean;
}

export namespace WhiteListTestStepDto {

    export enum step {
        INITIATED = 'INITIATED',
        CONFIGURE_SETTINGS = 'CONFIGURE_SETTINGS',
        TIME_ZONE_VERIFICATION = 'TIME_ZONE_VERIFICATION',
        CONFIRM_AND_OR_FIX_API_VERSION = 'CONFIRM_AND_OR_FIX_API_VERSION',
        ACTIVATE_BUILDING_AUTO_SEND = 'ACTIVATE_BUILDING_AUTO_SEND',
        ADD_AITS_TO_BUILDING = 'ADD_AITS_TO_BUILDING',
        RERUN_AVAILABILITIES = 'RERUN_AVAILABILITIES',
        INSERT_GUEST_CARD = 'INSERT_GUEST_CARD',
        INITIAL_EMAIL_RECEIVED = 'INITIAL_EMAIL_RECEIVED',
        REQUEST_TOUR_DAY_EMAIL_SENT = 'REQUEST_TOUR_DAY_EMAIL_SENT',
        AI_SENT_TIME_SUGGESTION = 'AI_SENT_TIME_SUGGESTION',
        ACCEPT_TOUR_TIME_EMAIL_SENT = 'ACCEPT_TOUR_TIME_EMAIL_SENT',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        APPOINTMENT_ON_GUEST_CARD = 'APPOINTMENT_ON_GUEST_CARD',
        REQUEST_CANCEL_TOUR = 'REQUEST_CANCEL_TOUR',
        APPOINTMENT_CANCELED = 'APPOINTMENT_CANCELED',
        APPOINTMENT_REMOVED_FROM_GUEST_CARD = 'APPOINTMENT_REMOVED_FROM_GUEST_CARD',
    }


}
