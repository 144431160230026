import DOMPurify from 'dompurify';
import linkifyHtml from 'linkify-html';

export const safelyRenderMessageHtml = (messageString: string): string => {
  const linkified = () => {
    try {
      return linkifyHtml(messageString, { target: '_blank' });
    } catch {
      return messageString;
    }
  };

  return DOMPurify.sanitize(linkified(), {
    ADD_ATTR: ['target'],
  });
};

export const alphabetize = (items: string[]): string[] =>
  items.sort((a, b) => a.localeCompare(b));
