import {
  Button,
  Classes,
  Drawer,
  Spinner,
  useHotkeys,
} from '@blueprintjs/core';
import isValid from 'date-fns/isValid';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import DateRangeFilter from '../../components/table/DateRangeFilter';
import NumericRangeFilter from '../../components/table/NumericRangeFilter';
import SelectColumnFilter from '../../components/table/SelectColumnFilter';
import Table from '../../components/table/Table';
import { fetchUnits, TaggingApiMethods } from '../state/taggingApiRepository';

import styles from './UnitsDrawerButton.module.css';
import { UnitVM } from './UnitsVM';

interface UnitsDrawerButtonProps {
  className?: string;
  stateId: string;
  iconOnly?: boolean;
}

const isNumber = (val: string | boolean | number) => {
  if (typeof val === 'number') {
    return true;
  }
  if (typeof val !== 'string') {
    return false;
  }
  return val === parseInt(val).toString();
};

const getFilter = (columnName: string, data: UnitVM[]) => {
  const exampleData = data.find(
    (datum) =>
      Object.keys(datum).includes(columnName) && datum[columnName] !== 'N/A'
  );
  if (exampleData && exampleData[columnName]) {
    if (columnName === 'unit') {
      return {
        Filter: SelectColumnFilter,
        filter: 'inSet',
      };
    }
    if (isNumber(exampleData[columnName])) {
      return {
        Filter: NumericRangeFilter,
        filter: 'between',
      };
    }
    if (
      typeof exampleData[columnName] !== 'boolean' &&
      isValid(new Date(exampleData[columnName] as string | number))
    ) {
      return {
        Filter: DateRangeFilter,
        filter: 'inDateRange',
      };
    }
  }

  return {
    Filter: SelectColumnFilter,
    filter: 'inSet',
  };
};

export default function UnitsDrawerButton({
  className,
  stateId,
  iconOnly,
}: UnitsDrawerButtonProps) {
  const { data: units, refetch } = useQuery(
    [TaggingApiMethods.FETCH_UNITS, stateId],
    () => fetchUnits(),
    { enabled: false }
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [unitsFilters, setUnitsFilters] = useState<
    { id: string; value: any }[] | null
  >([
    {
      id: 'active',
      value: new Set(['Yes']),
    },
    //   {
    //     id: 'availability-stage',
    //     value: //everything other than lease-signed,
    // },
  ]);

  const onClick = useCallback(() => {
    if (!units) {
      refetch();
    }
    setIsOpen(!isOpen);
  }, [isOpen, refetch, units]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + u',
        global: true,
        label: 'Units',
        onKeyDown: onClick,
      },
    ],
    [onClick]
  );
  useHotkeys(hotkeys);

  return (
    <>
      <Button
        onClick={onClick}
        text={!iconOnly && 'Units'}
        className={className}
        icon="office"
        minimal
        active={isOpen}
      />

      <Drawer
        title="Units"
        isOpen={isOpen}
        position="bottom"
        size={isFullScreen ? '100%' : '50%'}
        onClose={() => setIsOpen(false)}
        canOutsideClickClose={false}
        hasBackdrop={false}
        enforceFocus={false}
        portalClassName={styles.portal}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            {units?.unitDetails ? (
              <>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      marginBottom: '10px',
                    }}
                  >
                    <Button
                      minimal
                      text={
                        isFullScreen ? 'View Half Screen' : 'View Full Screen'
                      }
                      icon={
                        isFullScreen
                          ? 'double-chevron-down'
                          : 'double-chevron-up'
                      }
                      onClick={() => setIsFullScreen(!isFullScreen)}
                    />
                  </div>
                </div>
                <Table
                  columns={units.unitColumns.map((columnName, idx) => ({
                    Header: columnName,
                    accessor: columnName,
                    ...getFilter(columnName, units.unitDetails),
                    ...(idx === 0 ? { sticky: 'left' } : {}),
                  }))}
                  data={units.unitDetails}
                  title="Units"
                  copyCellOnClick
                  persistFilters={(filters) => setUnitsFilters(filters)}
                  filters={unitsFilters}
                />
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}
