/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMEHomeDashboardInfoDto } from '../models/GetMEHomeDashboardInfoDto';
import type { MEHomeDashboardInfo } from '../models/MEHomeDashboardInfo';
import { request as __request } from '../core/request';

export class MeHomeControllerService {

    /**
     * @param requestBody
     * @returns MEHomeDashboardInfo OK
     * @throws ApiError
     */
    public static async getDashboardInfo(
        requestBody: GetMEHomeDashboardInfoDto,
    ): Promise<MEHomeDashboardInfo> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/mehome/dashboardInfo`,
            body: requestBody,
        });
        return result.body;
    }

}