import { FunctionComponent } from 'react';

import { NavigationIconProps } from './NavigationIconProps';
import { ReactComponent as ConversationsOff } from './svgs/conversations/conversations-off.svg';
import { ReactComponent as ConversationsOn } from './svgs/conversations/conversations-on.svg';

export const ConversationsNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
  isDisplayOnly,
}) => {
  if (isDisplayOnly) {
    return <ConversationsOn />;
  }
  if (isActive) {
    return <ConversationsOn />;
  }
  return <ConversationsOff />;
};
