import { Button, Classes, Dialog } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useState } from 'react';
import ReactJson, { CollapsedFieldProps } from 'react-json-view';

import StateDTO from './StateDTO';

interface ViewRawStateButtonProps {
  className?: string;
  state: StateDTO;
  iconOnly?: boolean;
}

const COLLAPSABLE_FIELDS = ['events', 'parties'];

/**
 * A button that shows a dialog of a state's full JSON on click.
 */
export default function ViewRawStateButton(props: ViewRawStateButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Tooltip2 content={props.iconOnly ? 'JSON' : ''}>
        <Button
          onClick={() => {
            setIsOpen(true);
            navigator.clipboard.writeText(JSON.stringify(props.state, null, 2));
          }}
          text={!props.iconOnly && 'JSON'}
          className={props.className}
          icon="code"
          minimal
        />
      </Tooltip2>
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`State ${props.state.id} raw JSON`}
        style={{ width: '100%', maxWidth: '800px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <pre style={{ maxHeight: '80vh' }}>
            <ReactJson
              src={props.state}
              shouldCollapse={(field: CollapsedFieldProps) =>
                field.name ? COLLAPSABLE_FIELDS.includes(field.name) : false
              }
            />
          </pre>
        </div>
      </Dialog>
    </>
  );
}
