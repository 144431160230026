import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { setConsole } from 'react-query';

import { OpenAPI } from './api/rest-sdk';
import { assertString } from './assert';
import { getTokenOrEmptyString as getToken } from './auth/auth0';

OpenAPI.BASE = window.location.origin;
OpenAPI.TOKEN = getToken;

assertString(process.env.REACT_APP_DATADOG_CLIENT_TOKEN);
assertString(process.env.REACT_APP_DATADOG_APPLICATION_ID);

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  allowedTracingOrigins: [window.location.origin],
  site: 'datadoghq.com',
  sampleRate: 100,
  trackInteractions: true,
  service: process.env.REACT_APP_SERVICE,
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
});

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
  sampleRate: 100,
  service: process.env.REACT_APP_SERVICE,
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
});

datadogLogs.logger.info(
  `Starting elise-react ${process.env.REACT_APP_VERSION}`
);
setConsole(datadogLogs.createLogger('react-query'));
