import { Button } from '@blueprintjs/core';
import { useState } from 'react';
import { useQuery } from 'react-query';

import api from '../../api/axios';

const ToggleableOracleBodyView = ({
  stateId,
  eventId,
}: {
  stateId: string;
  eventId: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['oracleBody', stateId, eventId],
    async () => {
      return (
        await api.get(
          `../../platformApi/state/${stateId}/event/${eventId}/table`
        )
      ).data;
    },
    { enabled: false }
  );

  return (
    <div className="mb-3">
      <Button
        text={isOpen ? 'Hide Oracle' : 'Show Oracle'}
        icon={isOpen ? 'eye-off' : 'eye-open'}
        onClick={() => {
          if (!data) {
            refetch();
          }
          setIsOpen(!isOpen);
        }}
        loading={isLoading}
      ></Button>
      {isOpen && data && (
        <div
          style={{
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            fontFamily: 'monospace',
          }}
        >
          {data}
        </div>
      )}
    </div>
  );
};

export default ToggleableOracleBodyView;
