/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RescheduleConfirmedDto = {
    contextId?: number;
    buildingId?: number;
    unitId?: number;
    tourType?: RescheduleConfirmedDto.tourType;
    calendarEventId?: number;
    leasingAgentId?: number;
    newStartTime?: number;
    newEndTime?: number;
}

export namespace RescheduleConfirmedDto {

    export enum tourType {
        UNKNOWN = 'UNKNOWN',
        SELF_GUIDED = 'SELF_GUIDED',
        VIRTUAL_SHOWING = 'VIRTUAL_SHOWING',
        WITH_AGENT = 'WITH_AGENT',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
