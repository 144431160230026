import { Component } from 'react';

import log from '../log';

import { AppErrorPage } from './AppErrorPage';

export default class AppErrorBoundary extends Component<{}, { error?: Error }> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: {}) {
    super(props);
    this.state = { error: undefined };
  }

  componentDidCatch(error: any, errorInfo: any) {
    log.error('Unhandled render or lifecycle error, showing error page', error);
    log.error('Error info:', errorInfo);
  }

  render() {
    if (this.state.error) {
      return <AppErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}
