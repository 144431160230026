import { FC, SVGProps } from 'react';

/**
 * A blank icon that renders nothing but takes up space.
 *
 * For use in list items with optional icons, to make aligned list items
 * have aligned elements inside them, regardless of if they have an icon.
 */
export const Blank: FC<SVGProps<SVGSVGElement>> = ({
  width = 16,
  height = 16,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="currentColor"
    path=""
  />
);

export { ReactComponent as BoxArrowUpRight } from 'bootstrap-icons/icons/box-arrow-up-right.svg';
export { ReactComponent as Building } from 'bootstrap-icons/icons/building.svg';
export { ReactComponent as Cash } from 'bootstrap-icons/icons/cash.svg';
export { ReactComponent as ChatLeftText } from 'bootstrap-icons/icons/chat-left-text.svg';
export { ReactComponent as Check } from 'bootstrap-icons/icons/check.svg';
export { ReactComponent as CheckCircleFill } from 'bootstrap-icons/icons/check-circle-fill.svg';
export { ReactComponent as ChevronDoubleLeft } from 'bootstrap-icons/icons/chevron-double-left.svg';
export { ReactComponent as ChevronDoubleRight } from 'bootstrap-icons/icons/chevron-double-right.svg';
export { ReactComponent as ChevronLeft } from 'bootstrap-icons/icons/chevron-left.svg';
export { ReactComponent as ChevronRight } from 'bootstrap-icons/icons/chevron-right.svg';
export { ReactComponent as ChevronExpand } from 'bootstrap-icons/icons/chevron-expand.svg';
export { ReactComponent as Clipboard } from 'bootstrap-icons/icons/clipboard.svg';
export { ReactComponent as CreditCard } from 'bootstrap-icons/icons/credit-card.svg';
export { ReactComponent as DoorClosed } from 'bootstrap-icons/icons/door-closed.svg';
export { ReactComponent as DoorOpen } from 'bootstrap-icons/icons/door-open.svg';
export { ReactComponent as Envelope } from 'bootstrap-icons/icons/envelope.svg';
export { ReactComponent as ExclamationCircleFill } from 'bootstrap-icons/icons/exclamation-circle-fill.svg';
export { ReactComponent as Funnel } from 'bootstrap-icons/icons/funnel.svg';
export { ReactComponent as FunnelFill } from 'bootstrap-icons/icons/funnel-fill.svg';
export { ReactComponent as Globe } from 'bootstrap-icons/icons/globe.svg';
export { ReactComponent as Key } from 'bootstrap-icons/icons/key.svg';
export { ReactComponent as Lightning } from 'bootstrap-icons/icons/lightning.svg';
export { ReactComponent as Pen } from 'bootstrap-icons/icons/pen.svg';
export { ReactComponent as People } from 'bootstrap-icons/icons/people.svg';
export { ReactComponent as Person } from 'bootstrap-icons/icons/person.svg';
export { ReactComponent as PlusCircle } from 'bootstrap-icons/icons/plus-circle.svg';
export { ReactComponent as Search } from 'bootstrap-icons/icons/search.svg';
export { ReactComponent as SortDown } from 'bootstrap-icons/icons/sort-down.svg';
export { ReactComponent as SortDownAlt } from 'bootstrap-icons/icons/sort-down-alt.svg';
export { ReactComponent as ThreeDots } from 'bootstrap-icons/icons/three-dots.svg';
export { ReactComponent as QuestionCircle } from 'bootstrap-icons/icons/question-circle.svg';
export { ReactComponent as X } from 'bootstrap-icons/icons/x.svg';
export { ReactComponent as Phone } from 'bootstrap-icons/icons/phone.svg';
export { ReactComponent as Chat } from 'bootstrap-icons/icons/chat.svg';
