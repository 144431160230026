import {
  Button,
  ControlGroup,
  Tag,
  Intent as UIIntent,
  Intent,
  FormGroup,
  Icon,
  IconProps,
  ButtonGroup,
} from '@blueprintjs/core';
import { DateRangeShortcut } from '@blueprintjs/datetime';
import { DateRange, DateRangeInput2 } from '@blueprintjs/datetime2';
import { useClipboard } from '@mantine/hooks';
import { zonedTimeToUtc } from 'date-fns-tz';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfToday from 'date-fns/startOfToday';
import startOfWeek from 'date-fns/startOfWeek';
import subDays from 'date-fns/subDays';
import subHours from 'date-fns/subHours';
import subMinutes from 'date-fns/subMinutes';

import { appToaster } from '../../vanillaelise/appToaster';

import styles from './TimeItemInput.module.scss';
import { TimexItem } from './TimexDTO';

const NotIcon = (props: IconProps) => {
  const size = props.size ?? 16;

  return (
    <div>
      <Icon {...props} />
      <div
        style={{
          width: `${1.414 * size}px`,
          transform: 'rotate(45deg)',
          transformOrigin: 'top left',
          borderTop: '2px solid red',
          position: 'relative',
          top: `-${size}px`,
          boxSizing: 'border-box',
        }}
      ></div>
    </div>
  );
};

interface TimeItemInputProps {
  className?: string;
  /** The time or range to display */
  time: TimexItem;
  positive: boolean;
  label: string;
  uiIntent: UIIntent;
  readOnly?: boolean;
  onChangeTime(newTime: TimexItem): void;
  onChangeHasDate: (hasDate: boolean) => void;
  onChangeIsPositive: () => void;
  onRemove(): void;
  timezone?: string | null;
  hasDate?: boolean | null;
}

/**
 * A form control group to input a datetime or range.
 *
 * A list of these tags are used to display a timex.
 */
export default function TimeItemInput({
  className,
  label,
  time,
  positive,
  uiIntent,
  readOnly,
  onRemove,
  onChangeTime,
  timezone,
  hasDate,
  onChangeHasDate,
  onChangeIsPositive,
}: TimeItemInputProps) {
  const dateFormat = hasDate ? 'LL/dd/yyyy, hh:mmaaa' : 'hh:mmaaa';
  // const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const clipboard = useClipboard();
  const start = time[0];
  const end = time[1];

  const Actions = () => (
    <ButtonGroup minimal>
      <Button
        text="Copy"
        icon="duplicate"
        onClick={() =>
          clipboard.copy(
            JSON.stringify({
              timeItem: [start, end],
              positive: positive,
            })
          )
        }
      />
      <Button
        text={end ? 'No end time' : 'Add end time'}
        icon={end ? <NotIcon icon="flow-end" /> : 'flow-end'}
        onClick={() => {
          if (!start) {
            return;
          }
          onChangeTime([
            start,
            end
              ? null
              : formatISO(
                  zonedTimeToUtc(
                    addDays(startOfDay(new Date(start)), 1),
                    timezone ?? ''
                  )
                ),
          ]);
        }}
      />
      <Button
        text={positive ? 'Exclude time' : 'Include time'}
        icon={positive ? <NotIcon icon="time" /> : 'time'}
        onClick={onChangeIsPositive}
      />
      <Button
        text={hasDate ? 'Remove Date' : 'Add Date'}
        icon={hasDate ? <NotIcon icon="calendar" /> : 'calendar'}
        onClick={() => onChangeHasDate(!hasDate)}
      />
    </ButtonGroup>
  );

  const zonedNow =
    (timezone && utcToZonedTime(new Date(), timezone)) || undefined;

  const zonedStart =
    (start && timezone && utcToZonedTime(new Date(start), timezone)) ||
    undefined;

  const shortcuts: DateRangeShortcut[] = [
    ...(zonedNow && zonedStart
      ? [
          {
            label: 'Now',
            dateRange: [zonedNow, null] as DateRange,
            includeTime: true,
          },
          {
            label: 'ASAP',
            dateRange: [
              startOfDay(zonedStart),
              addDays(startOfDay(zonedStart), 8),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Entire Day',
            dateRange: [
              startOfDay(zonedStart),
              addDays(startOfDay(zonedStart), 1),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Day',
            dateRange: [
              addHours(startOfDay(zonedStart), 11),
              addHours(startOfDay(zonedStart), 13),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Later in The Day',
            dateRange: [
              addHours(startOfDay(zonedStart), 15),
              addHours(startOfDay(zonedStart), 23),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early',
            dateRange: [
              addHours(startOfDay(zonedStart), 7),
              addHours(startOfDay(zonedStart), 10),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Late',
            dateRange: [
              addHours(startOfDay(zonedStart), 19),
              addHours(startOfDay(zonedStart), 23),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Daytime',
            dateRange: [
              addHours(startOfDay(zonedStart), 8),
              addHours(startOfDay(zonedStart), 18),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Dusk',
            dateRange: [
              addHours(startOfDay(zonedStart), 18),
              addHours(startOfDay(zonedStart), 20),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Before Work',
            dateRange: [
              addHours(startOfDay(zonedStart), 7),
              addHours(startOfDay(zonedStart), 9),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Lunch Time',
            dateRange: [
              addMinutes(addHours(startOfDay(zonedStart), 11), 30),
              addHours(startOfDay(zonedStart), 13),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'After Work',
            dateRange: [
              addHours(startOfDay(zonedStart), 18),
              addHours(startOfDay(zonedStart), 23),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Morning',
            dateRange: [
              addHours(startOfDay(zonedStart), 8),
              addHours(startOfDay(zonedStart), 12),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early Morning',
            dateRange: [
              addHours(startOfDay(zonedStart), 7),
              addHours(startOfDay(zonedStart), 9),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Morning',
            dateRange: [
              addHours(startOfDay(zonedStart), 9),
              addHours(startOfDay(zonedStart), 11),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Late Morning',
            dateRange: [
              addMinutes(addHours(startOfDay(zonedStart), 10), 30),
              addHours(startOfDay(zonedStart), 12),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Afternoon',
            dateRange: [
              addHours(startOfDay(zonedStart), 12),
              addHours(startOfDay(zonedStart), 18),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early Afternoon',
            dateRange: [
              addHours(startOfDay(zonedStart), 12),
              addMinutes(addHours(startOfDay(zonedStart), 14), 30),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Afternoon',
            dateRange: [
              addHours(startOfDay(zonedStart), 14),
              addHours(startOfDay(zonedStart), 16),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Late Afternoon',
            dateRange: [
              addHours(startOfDay(zonedStart), 16),
              addHours(startOfDay(zonedStart), 18),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Evening',
            dateRange: [
              addHours(startOfDay(zonedStart), 17),
              addHours(startOfDay(zonedStart), 21),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early Evening',
            dateRange: [
              addHours(startOfDay(zonedStart), 17),
              addHours(startOfDay(zonedStart), 18),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Evening',
            dateRange: [
              addHours(startOfDay(zonedStart), 18),
              addHours(startOfDay(zonedStart), 20),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Late Evening',
            dateRange: [
              addHours(startOfDay(zonedStart), 20),
              addHours(startOfDay(zonedStart), 21),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Before X Time',
            dateRange: [
              startOfDay(zonedStart),
              subMinutes(zonedStart, 1),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'After X Time',
            dateRange: [
              addMinutes(zonedStart, 1),
              startOfDay(addDays(zonedStart, 1)),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Around X Time',
            dateRange: [
              subHours(zonedStart, 1),
              addHours(zonedStart, 1),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Before X Date',
            dateRange: [
              startOfDay(new Date()),
              startOfDay(zonedStart),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'After X Date',
            dateRange: [
              startOfDay(addDays(zonedStart, 1)),
              startOfDay(addDays(zonedStart, 14)),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Around X Date',
            dateRange: [
              startOfDay(subDays(zonedStart, 7)),
              startOfDay(addDays(zonedStart, 7)),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'This Week',
            dateRange: [
              startOfDay(zonedStart),
              addDays(startOfWeek(zonedStart), 8),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early This Week',
            dateRange: [
              addDays(startOfWeek(zonedStart), 1),
              addDays(startOfWeek(zonedStart), 3),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Week',
            dateRange: [
              addDays(startOfWeek(zonedStart), 3),
              addDays(startOfDay(zonedStart), 4),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Later This Week',
            dateRange: [
              addDays(startOfWeek(zonedStart), 5),
              addDays(startOfWeek(zonedStart), 7),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Next Week',
            dateRange: [
              addDays(startOfWeek(zonedStart), 1),
              addDays(startOfWeek(zonedStart), 8),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Month',
            dateRange: [
              startOfMonth(zonedStart),
              addMonths(startOfMonth(zonedStart), 1),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Early Month',
            dateRange: [
              startOfMonth(zonedStart),
              addDays(startOfMonth(zonedStart), 15),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Mid Month',
            dateRange: [
              addDays(startOfMonth(zonedStart), 9),
              addDays(startOfMonth(zonedStart), 20),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Late/End Month',
            dateRange: [
              addDays(startOfMonth(zonedStart), 14),
              addMonths(startOfMonth(zonedStart), 1),
            ] as DateRange,
            includeTime: true,
          },
          {
            label: 'Next Month',
            dateRange: [
              addMonths(startOfMonth(zonedStart), 1),
              addMonths(startOfMonth(zonedStart), 2),
            ] as DateRange,
            includeTime: true,
          },
        ]
      : []),
  ];

  if (readOnly) {
    return (
      <Tag large className={className} intent={uiIntent}>
        {label}:{' '}
        {start && (
          <strong>
            {format(
              utcToZonedTime(new Date(start), timezone ?? ''),
              dateFormat,
              { timeZone: timezone ?? '' }
            )}
          </strong>
        )}
        {end && ' to '}
        {end && (
          <strong>
            {format(utcToZonedTime(new Date(end), timezone ?? ''), dateFormat, {
              timeZone: timezone ?? '',
            })}
          </strong>
        )}
      </Tag>
    );
  }

  return (
    <div style={{ border: '1px solid black' }}>
      <ControlGroup className={className} style={{ flexWrap: 'wrap' }}>
        <FormGroup
          label={
            <>
              {!positive && (
                <span style={{ color: '#C87619', fontWeight: 'bold' }}>
                  {'NOT '}
                </span>
              )}
            </>
          }
          inline
          style={{
            marginBottom: 0,
          }}
        >
          <DateRangeInput2
            className={styles.shortcutsContainer}
            allowSingleDayRange
            highlightCurrentDay
            closeOnSelection={false}
            value={[
              start ? utcToZonedTime(new Date(start), timezone ?? '') : null,
              end ? utcToZonedTime(new Date(end), timezone ?? '') : null,
            ]}
            startInputProps={{
              intent: uiIntent,
              style: { borderRadius: '3px 0 0 3px' },
            }}
            endInputProps={{
              intent: uiIntent,
              style: { borderRadius: '3px 0 0 3px' },
            }}
            formatDate={(date) => format(date, dateFormat)}
            parseDate={(str) => parse(str, dateFormat, new Date())}
            onChange={(dateRange) => {
              const start = dateRange[0];
              const end = dateRange[1];

              const startUTC = start
                ? zonedTimeToUtc(start, timezone ?? '')
                : null;
              const endUTC = end ? zonedTimeToUtc(end, timezone ?? '') : null;
              const newStart = startUTC ? startUTC.toISOString() : null;
              const newEnd = endUTC ? endUTC.toISOString() : null;
              if (newStart === null && newEnd === null) return;
              console.log({ newStart, newEnd });
              return onChangeTime?.([newStart, newEnd]);
            }}
            timePrecision="minute"
            maxDate={addYears(startOfToday(), 10)}
            timePickerProps={{ useAmPm: true, showArrowButtons: true }}
            shortcuts={shortcuts}
            onError={(errorRange) => {
              if (!errorRange[0] || !errorRange[1]) return;
              appToaster.toastError(
                {
                  message: `${format(errorRange[0], dateFormat)} - ${format(
                    errorRange[1],
                    dateFormat
                  )}`,
                },
                'Date Range Error'
              );
            }}
            footerElement={<Actions />}
          />
        </FormGroup>
        <Button
          minimal
          onClick={onRemove}
          icon="trash"
          intent={Intent.DANGER}
        />
      </ControlGroup>
      <Actions />
    </div>
  );
}
