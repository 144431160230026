import { FunctionComponent } from 'react';

import styles from './NavigationIcon.module.scss';
import { ReactComponent as ActivityIcon } from './svgs/activity-icon.svg';
import { ReactComponent as DashboardIcon } from './svgs/dashboard-icon.svg';
import { ReactComponent as IntegrationsIcon } from './svgs/integrations-icon.svg';
import { ReactComponent as LightningIcon } from './svgs/lightning-nav-icon.svg';
import { ReactComponent as ManageIcon } from './svgs/manage-icon.svg';
import { ReactComponent as ReportIcon } from './svgs/reports-icon.svg';
import { ReactComponent as ManageUsersIcon } from './svgs/users-icon.svg';

type SvgComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

interface NavigationIconProps {
  isActive?: boolean;
}

const NavigationIcon: FunctionComponent<{
  isActive?: boolean;
  icon: SvgComponent;
}> = ({ icon, isActive = false }) => {
  const Icon = icon;
  return <Icon className={isActive ? styles.active : styles.inactive} />;
};

export const CalendarNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={ActivityIcon} isActive={isActive} />;

export const IntegrationsNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={IntegrationsIcon} isActive={isActive} />;

export const ManageUsersNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={ManageUsersIcon} isActive={isActive} />;

export const ManageNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={ManageIcon} isActive={isActive} />;

export const ReportsNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={ReportIcon} isActive={isActive} />;

export const DashboardNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={DashboardIcon} isActive={isActive} />;

export const LightningNavIcon: FunctionComponent<NavigationIconProps> = ({
  isActive,
}) => <NavigationIcon icon={LightningIcon} isActive={isActive} />;
