/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AppointmentHours } from './AppointmentHours';

export type CommunityFormView = {
    id?: number;
    managementCompanyId?: number;
    organizationId?: number;
    active?: number;
    handoffForwardToEmails?: Array<string>;
    responseCcEmails?: Array<string>;
    responseBccEmails?: Array<string>;
    buildingName?: string;
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    crossStreets?: string;
    neighborhood?: string;
    nearbyTransportation?: string;
    totalUnits?: number;
    totalFloors?: number;
    greenBuilding?: boolean;
    elevator?: boolean;
    freightElevator?: boolean;
    petPolicy?: string;
    petFeeOrDeposit?: string;
    utilitiesIncluded?: string;
    utilitiesNotIncluded?: string;
    minLeaseTermAccepted?: number;
    furnishedOffered?: boolean;
    furnishedUnitsPolicy?: string;
    internationalStudentRequirements?: string;
    amenitiesFee?: string;
    corporateLeasesAccepted?: boolean;
    doorman?: number;
    intercom?: boolean;
    liveInSuper?: boolean;
    concierge?: string;
    laundryInBuilding?: boolean;
    packageReceiving?: boolean;
    gym?: boolean;
    storage?: boolean;
    bicycleRoom?: boolean;
    lounge?: boolean;
    clubHouse?: boolean;
    businessCenter?: boolean;
    outdoorSpace?: string;
    roofDeck?: boolean;
    courtyard?: boolean;
    childrenPlayroom?: boolean;
    nursery?: boolean;
    maidService?: boolean;
    pool?: boolean;
    wheelchairAccess?: boolean;
    thirdPartyGuarantorTypesAccepted?: string;
    noCreditQualificationAlternative?: string;
    virtualTourPlatforms?: string;
    flexWallPolicy?: string;
    leaseholderApplicationFee?: number;
    guarantorApplicationFee?: number;
    tenantIncomeRequirementRentMultiplier?: number;
    guarantorIncomeRequirementRentMultiplier?: number;
    leaseholderMinimumCreditScoreRequirement?: string;
    guarantorMinimumCreditScoreRequirement?: string;
    leaseholderApplicationDocumentsRequired?: string;
    guarantorsAccepted?: string;
    prepaymentPolicy?: string;
    rentRequiredOnLeaseSigning?: string;
    paymentFormsAccepted?: string;
    leasingBrochureUrl?: string;
    leasingOfficeHours?: string;
    appointmentHours?: Array<AppointmentHours>;
    occupiedUnitsShowingHours?: string;
    tourDuration?: string;
    leasingPhoneNumber?: string;
    callCenterNumber?: string;
    buildingWebsiteUrl?: string;
    applicationLink?: string;
    geoAddress?: string;
    googleMapsUrl?: string;
    appointmentMeetingLocation?: string;
    appointmentDirections?: string;
    appointmentAdditionalDirections?: string;
    rentComputeType?: number;
    autosendActive?: number;
    advancedMoveInAllowenceInDays?: number;
    showByOpenHouseOnly?: number;
    showUnitsWithPendingApplication?: number;
    showOccupiedUnitsWithPendingApplication?: number;
    confirmAppointmentsOccupiedUnits?: number;
    confirmAppointmentsRenovatingUnits?: number;
    occupiedUnitsAdvancedNoticeInHours?: number;
    scheduleToursWithNoViewableUnits?: number;
    alternativeBuildingNames?: Array<string>;
    externalIds?: Array<string>;
    externalCrmId?: string;
    defaultAvailabilityQuoteMatchMoveInDate?: number;
    leaseRenewalNoticeInDays?: number;
    rentQuoteFormat?: string;
    rentWithIncentivesQuoteFormat?: string;
    effectiveRentComputeType?: number;
    maxLeaseTermAccepted?: number;
    parkingInformation?: string;
    storageFee?: string;
    incentivesProperlyConnected?: number;
    prioritizeShowingVacantUnits?: number;
    electricCompany?: string;
    gasCompany?: string;
    telephoneCompany?: string;
    televisionCompany?: string;
    waterCompany?: string;
    internetCompany?: string;
    localHospital?: string;
    localSchoolDistrict?: string;
    localElementarySchool?: string;
    localMiddleSchool?: string;
    localHighSchool?: string;
    localShoppingCenter?: string;
    localGroceryStore?: string;
    closestInterstates?: string;
    buildingPicturesUrl?: string;
    buildingFloorPlansUrl?: string;
    nonexistentLayouts?: Array<number>;
    avoidQuotingPrices?: number;
    excludeNotRentReadyUnit?: number;
    flexWallsAllowed?: boolean;
    petsAccepted?: boolean;
    showOccupiedUnits?: boolean;
    guarantorsAcceptedSwitch?: boolean;
    thirdPartyGuarantorsAccepted?: boolean;
    leasingOfficeOnsite?: boolean;
    building3dMediaUrl?: string;
    inPersonToursAllowed?: boolean;
    selfGuidedToursEnabled?: boolean;
    noUnitSelfGuidedTourAllowed?: boolean;
    textForwardToEmails?: Array<string>;
    disableDynamicTto?: boolean;
    securityDepositText?: string;
    languageTranslationAllowed?: boolean;
    omitDesiredFloorPlan?: boolean;
    tourNotificationEmailsMode?: CommunityFormView.tourNotificationEmailsMode;
    virtualTourType?: CommunityFormView.virtualTourType;
    offeredLayouts?: Array<number>;
    weeklyReportEmails?: Array<string>;
    dailyReportEmails?: Array<string>;
    sgtUrl?: string;
    preRecordedVirtualTourUrl?: string;
    liveVirtualToursLink?: string;
    selfGuidedToursLink?: string;
}

export namespace CommunityFormView {

    export enum tourNotificationEmailsMode {
        NO_NOTIFICATION = 'NO_NOTIFICATION',
        ALL_VIEWINGS = 'ALL_VIEWINGS',
        SAME_DAY_VIEWINGS = 'SAME_DAY_VIEWINGS',
    }

    export enum virtualTourType {
        NOT_ENABLED = 'NOT_ENABLED',
        SELF_MANAGED = 'SELF_MANAGED',
        WHEREBY = 'WHEREBY',
    }


}
