/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertQueueInfo } from '../models/AlertQueueInfo';
import type { ResolveEscalationDto } from '../models/ResolveEscalationDto';
import type { RespondToMCConversationDTO } from '../models/RespondToMCConversationDTO';
import { request as __request } from '../core/request';

export class EscalationsControllerService {

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async resolveEscalation(
        requestBody: ResolveEscalationDto,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/escalations/resolve`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static async respondToMcConversation(
        requestBody: RespondToMCConversationDTO,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/escalations/mc/escalation/respond`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param mcId
     * @returns any OK
     * @throws ApiError
     */
    public static async ignoreMcConversation(
        mcId: string,
    ): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/api/v1/escalations/mc/escalation/ignore/${mcId}`,
        });
        return result.body;
    }

    /**
     * @param buildingId
     * @returns AlertQueueInfo OK
     * @throws ApiError
     */
    public static async escalationsForBuildingId(
        buildingId: number,
    ): Promise<Array<AlertQueueInfo>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/escalations/getByBuildingId/${buildingId}`,
        });
        return result.body;
    }

}