import { Button, Checkbox } from '@blueprintjs/core';
import { MenuItem2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';
import { useMemo, useState } from 'react';
import { FilterProps } from 'react-table';

import { Funnel, FunnelFill } from '../Icons';

import styles from './SelectColumnFilter.module.scss';

/**
 * A table filter component for showing/hiding values via checkbox.
 *
 * This is a React component to be passed as the `Filter` property of a
 * column options object. (An array of column options objects are
 * passed to react-table's `useTable()` hook).
 *
 * This component mimics the behavior of how Excel filters table values.
 * It renders a funnel icon, which renders a popup when clicked. The
 * popup has a list of checkboxes next to values which can be toggled to
 * show or hide all table columns matching the value.
 */
export default function SelectColumnFilter<T extends object>({
  column: { filterValue, setFilter, preFilteredRows, id },
}: FilterProps<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  // A sorted array of all the unique column values.
  const values = useMemo(() => {
    const valuesSet: Set<string> = new Set(
      preFilteredRows.map((row) => row.values[id])
    );
    const uniqValuesArray = Array.from(valuesSet);
    uniqValuesArray.sort();
    return uniqValuesArray;
  }, [id, preFilteredRows]);

  // The actual items rendered: 'Select All' plus column values.
  const items = useMemo(() => ['Select All', ...values], [values]);

  // The current set of allowed values to show. If filterValue is
  // undefined, default to allowing all values.
  const filterSet: Set<string> = useMemo(() => {
    return filterValue ?? new Set(values);
  }, [filterValue, values]);

  const FunnelIcon =
    filterSet && filterSet.size < values.length ? FunnelFill : Funnel;

  // Render a multi-select box
  return (
    <Select2
      activeItem={activeItem}
      items={items}
      itemPredicate={(q, i) => new RegExp(q, 'i').test(i)}
      itemRenderer={(value, props) => (
        <MenuItem2
          key={value}
          className="py-0"
          text={
            <div className={styles.menuItem}>
              <Checkbox
                className="my-0 py-2"
                checked={
                  value === 'Select All'
                    ? filterSet.size > 0
                    : filterSet.has(value)
                }
                label={value}
                indeterminate={
                  value === 'Select All' &&
                  !!filterSet.size &&
                  filterSet.size < values.length
                }
                onClick={props.handleClick}
              />
              {value !== 'Select All' && (
                <Button
                  className={styles.selectButton}
                  text={
                    filterSet.has(value) && filterSet.size === 1
                      ? 'All'
                      : 'Only'
                  }
                  onClick={() => {
                    if (filterSet.has(value) && filterSet.size === 1) {
                      setFilter(new Set(values));
                    } else {
                      setFilter(new Set([value]));
                    }
                  }}
                  minimal
                />
              )}
            </div>
          }
          active={props.modifiers.active}
        />
      )}
      onActiveItemChange={setActiveItem}
      onItemSelect={(filter) => {
        if (filter === 'Select All') {
          const newFilters =
            filterSet.size === values.length ? new Set() : new Set(values);
          setFilter(newFilters);
          return;
        }
        const newFilters = new Set(filterSet);
        if (filterSet.has(filter)) {
          newFilters.delete(filter);
        } else {
          newFilters.add(filter);
        }
        setActiveItem(filter);
        setFilter(newFilters);
      }}
      resetOnSelect={false}
      popoverProps={{
        isOpen,
        onClose: (e) => {
          // Detect if the user toggled a checkbox. If they did,
          // don't actually close the popover. The popover should only
          // close on 'Esc' key or by clicking an outside element.
          const clickedItem =
            e?.currentTarget.classList?.contains('bp4-popover2');
          if (!clickedItem) setIsOpen(false);
        },
      }}
    >
      <FunnelIcon
        title="filter"
        className="ml-2"
        onClick={() => setIsOpen(true)}
      />
    </Select2>
  );
}
