import { FunctionComponent, ReactChild } from 'react';

import { AlertIcon } from '../../components/Icons/AlertIcon';
import { NonIdealState } from '../../vanillaelise/NonIdealState/NonIdealState';

import VanillaScssPage, { VanillaScssPageProps } from './VanillaScssPage';

export const VanillaScssErrorPage: FunctionComponent<
  {
    title: string;
    action?: JSX.Element;
    description?: ReactChild;
  } & VanillaScssPageProps
> = ({ title, action, description, ...props }) => {
  return (
    <VanillaScssPage {...props}>
      <NonIdealState
        title={title}
        attentionGrabber={<AlertIcon />}
        action={action}
        description={description}
      />
    </VanillaScssPage>
  );
};
