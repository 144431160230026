/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ScriptSearchQuery = {
    organizationId?: number;
    communityId?: number;
    type: ScriptSearchQuery.type;
    media: ScriptSearchQuery.media;
    category?: string;
    subIntent?: string;
    searchText?: string;
}

export namespace ScriptSearchQuery {

    export enum type {
        ALL = 'ALL',
        TEMPLATE = 'TEMPLATE',
        TEMPLATE_ITEM = 'TEMPLATE_ITEM',
    }

    export enum media {
        BOTH = 'BOTH',
        EMAIL = 'EMAIL',
        SMS = 'SMS',
    }


}
