import {
  Button,
  Classes,
  Drawer,
  DrawerSize,
  H5,
  Spinner,
  Tab,
  Tabs,
  useHotkeys,
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import compareAsc from 'date-fns/compareAsc';
import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';
import { useState, useMemo, FC, useCallback } from 'react';
import { useQuery } from 'react-query';

import {
  fetchAvailabilities,
  TaggingApiMethods,
} from '../state/taggingApiRepository';
import TimexItemText from '../timex/TimexItemText';
import TimexVM from '../timex/TimexVM';

import styles from './ToursDrawerButton.module.css';
import ToursVM from './ToursVM';

interface AvailabilitiesDrawerButtonProps {
  className?: string;
  stateId: string;
  iconOnly?: boolean;
}

export default function AvailabilitiesDrawerButton({
  className,
  stateId,
  iconOnly,
}: AvailabilitiesDrawerButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { data: availabilities, refetch } = useQuery(
    [TaggingApiMethods.FETCH_AVAILABILITIES, stateId],
    () => fetchAvailabilities(),
    { enabled: false }
  );

  const onClick = useCallback(() => {
    if (!availabilities) {
      refetch();
    }
    setIsOpen(!isOpen);
  }, [availabilities, isOpen, refetch]);

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + a',
        global: true,
        label: 'Tours',
        onKeyDown: onClick,
      },
    ],
    [onClick]
  );

  useHotkeys(hotkeys);

  return (
    <>
      <Button
        onClick={onClick}
        text={!iconOnly && 'Tours'}
        className={className}
        icon="timeline-events"
        minimal
        active={isOpen}
      />

      <Drawer
        title="Tours"
        isOpen={isOpen}
        position="left"
        size={DrawerSize.SMALL}
        onClose={() => setIsOpen(false)}
        canOutsideClickClose={false}
        hasBackdrop={false}
        enforceFocus={false}
        portalClassName={styles.portal}
      >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY}>
            {availabilities ? (
              <AvailabilitiesDisplay availabilities={availabilities} />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
}

const AvailabilitiesDisplay: FC<{ availabilities: ToursVM }> = (p) => {
  const types = Object.keys(p.availabilities);
  if (types.length === 0) {
    return <p className={Classes.TEXT_MUTED}>No tour types are available.</p>;
  } else if (types.length === 1) {
    return (
      <>
        <H5>{types[0]} Availabilities</H5>
        <AvailableTimesDisplay timex={p.availabilities[types[0]]} />
      </>
    );
  } else {
    return (
      <Tabs defaultSelectedTabId={types[0]}>
        {Object.entries(p.availabilities).map(([type, timex]) => (
          <Tab
            key={type}
            id={type}
            title={type}
            panel={<AvailableTimesDisplay timex={timex} />}
          />
        ))}
      </Tabs>
    );
  }
};

const AvailableTimesDisplay: FC<{ timex: TimexVM }> = ({ timex }) => {
  const timesByDay = useMemo(
    () => groupBy(timex.items, (t) => t.start.startOf('day').toISODate()),
    [timex]
  );
  const [selectedDay, setDay] = useState<Date | null>(
    timex.items[0] ? timex.items[0].start.startOf('day').toJSDate() : null
  );
  const timesForSelectedDay = useMemo(() => {
    if (selectedDay) {
      /* Here's what is going on here: the method .toISOString() is dangerous. 
        If you are in a + timezone and your time portion is early in the day, then it could roll-back a day. 
        If you're in a - timezone and your time portion is late in the day, then it could roll forward a day.
        To solve this timezone issue, we do some timezone offset magic.
        See this stack overflow for further info: https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
        */
      const offset = selectedDay.getTimezoneOffset();
      const selectedDayWithOffset = new Date(
        selectedDay.getTime() - offset * 60 * 1000
      );
      const selectedDayKey = selectedDayWithOffset.toISOString().slice(0, 10);
      return timesByDay[selectedDayKey] ?? [];
    }
    return [];
  }, [timesByDay, selectedDay]);

  const days = timex.items
    .map((t) => t.start.startOf('day').toJSDate())
    .sort((a, b) => compareAsc(a, b));
  const minDate = days[0];
  const maxDate = days.at(-1);

  return (
    <>
      <DatePicker
        className="d-inline-block mb-2"
        value={selectedDay}
        onChange={setDay}
        minDate={minDate}
        maxDate={maxDate}
        canClearSelection={false}
        dayPickerProps={{
          disabledDays: (date) =>
            !timesByDay[date.toISOString().slice(0, 10)]?.length,
        }}
      />
      {timesForSelectedDay.length && selectedDay ? (
        <div>
          <p>
            Available times for{' '}
            {DateTime.fromJSDate(selectedDay).toLocaleString(DateTime.DATE_MED)}{' '}
            ({timex.timezone}):
          </p>
          {timesForSelectedDay.map((time) => (
            <TimexItemText
              key={time.start.toISO()}
              item={time}
              className="d-block mb-1"
            />
          ))}
        </div>
      ) : (
        <p>No times are available on this day</p>
      )}
    </>
  );
};
