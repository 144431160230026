import { getName } from '../../calendarV2/getName';

import PartyDTO from './PartyDTO';
import PartyPropertyVM from './PartyPropertyVM';

/**
 * A party participating in a conversation.
 *
 * May be a lead, an AI leasing agent, or a human agent.
 */
interface PartyVM {
  id: string;
  name: string;
  timezone?: string;
  isLead: boolean;
  displayName: string;
  properties: PartyPropertyVM[];
  firstName?: string | null;
  lastName?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
}

// Because we want PartyVM to be an interface with static methods.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const PartyVM = {
  fromDTO(dto: PartyDTO): PartyVM {
    const getProperty = (propName: string) =>
      dto.properties.find((p) => p.name === `property-${propName}`)?.text;

    const name = getProperty('lead-name');
    const email = getProperty('email-address');
    const fName = getProperty('first-name');
    const lName = getProperty('last-name');
    const phoneNumber = getProperty('phone-number') ?? null;
    const internalName = dto.name;

    return {
      id: dto.name,
      ...dto,
      isLead: dto.name ? dto.name.startsWith('lead') : false,
      displayName:
        fName || lName
          ? [fName, lName].join(' ')
          : name || email || internalName,
      firstName: fName,
      lastName: lName,
      email: email,
      phoneNumber: phoneNumber,
      properties: dto.properties.map((p) => PartyPropertyVM.fromDTO(p)),
    };
  },

  toDTO(vm: PartyVM): PartyDTO {
    return {
      name: vm.name,
      timezone: vm.timezone,
      properties: vm.properties.map((p) => PartyPropertyVM.toDTO(p)),
      type: 'Party',
    };
  },
};

export const getInitials = (party: PartyVM) => {
  const defaultInitial = party.isLead ? 'P' : 'S';
  if (isNaN(parseInt(party.displayName ?? ''))) {
    return (
      party.displayName
        ?.split(' ')
        .map((n) => n.replaceAll(/#/g, '').charAt(0))
        .join('') ?? defaultInitial
    );
  }
  if (!party.firstName && !party.lastName) {
    return party.email?.charAt(0) ?? defaultInitial;
  }
  return getName(party.firstName, party.lastName)
    .split(' ')
    .map((n) => n.replaceAll(/#/g, '').charAt(0))
    .join('');
};

export default PartyVM;
