import clsx from 'clsx';
import { FunctionComponent } from 'react';

import DefaultLoadingIndicator from './loading-indicator-default.gif';
import InvertedLoadingIndicator from './loading-indicator-inverted.gif';
import styles from './LoadingIndicator.module.scss';

export const LoadingIndicator: FunctionComponent<{
  isInverted?: boolean;
  className?: string;
  style?: {};
}> = ({ isInverted = false, className, style }) =>
  isInverted ? (
    <img
      className={clsx(styles.indicator, className)}
      src={InvertedLoadingIndicator}
      alt="loading indicator"
      style={style}
    />
  ) : (
    <img
      style={style}
      className={clsx(styles.indicator, className)}
      src={DefaultLoadingIndicator}
      alt="loading indicator"
    />
  );
