import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

import { useHasAccessToActivityPage } from '../../activity/useHasAccessToActivityPage';
import Authorizer from '../../auth/Authorizer';
import Permission from '../../auth/Permission';
import { ConversationsNavIcon } from '../../vanillaelise/NavigationIcons/ConversationsNavIcon';
import {
  CalendarNavIcon,
  DashboardNavIcon,
  IntegrationsNavIcon,
  LightningNavIcon,
  ManageUsersNavIcon,
  ReportsNavIcon,
} from '../../vanillaelise/NavigationIcons/NavigationIcons';

import { ReactComponent as HelpCenterIcon } from './help-center-icon.svg';
import { ReactComponent as MeetEliseLogo } from './ME_Logo-Default.svg';
import { ReactComponent as ReferralIcon } from './referral-icon.svg';
import { ReactComponent as SupportIcon } from './support-icon.svg';
import styles from './VanillaScssNavBar.module.scss';

interface NavItem {
  text: string;
  iconRenderer?: (isActive: boolean) => JSX.Element;
  to: string;
  permission?: Permission;
  baseRoutes?: string[];
}

const items: NavItem[] = [
  {
    text: 'Reports',
    iconRenderer: (isActive) => <ReportsNavIcon isActive={isActive} />,
    to: '/reporting',
    permission: Permission.READ_REPORTS,
  },
  {
    text: 'Integrations',
    iconRenderer: (isActive) => <IntegrationsNavIcon isActive={isActive} />,
    to: '/integrations',
    permission: Permission.READ_REPORTS,
  },
  {
    text: 'Communities',
    to: '/communities',
    iconRenderer: (isActive) => <LightningNavIcon isActive={isActive} />,
  },
  {
    text: 'Users',
    iconRenderer: (isActive) => <ManageUsersNavIcon isActive={isActive} />,
    to: '/users',
    permission: Permission.READ_EXTERNAL_USERS,
  },
  {
    text: 'Calendar',
    iconRenderer: (isActive) => <CalendarNavIcon isActive={isActive} />,
    to: '/calendar',
    permission: Permission.READ_CALENDAR_EVENTS,
  },
  {
    text: 'Dashboard',
    iconRenderer: (isActive) => <DashboardNavIcon isActive={isActive} />,
    to: '/dashboard',
    permission: Permission.READ_DASHBOARD,
  },
];

export const VanillaScssNavBar: FunctionComponent = () => {
  return (
    <div className={styles.navBarWrapper}>
      <div className={styles.navBar}>
        <div className={styles.linkContainer}>
          <div>
            <Link className={styles.meetEliseTextWrapper} to={'/'}>
              <MeetEliseLogo />
            </Link>
            <AuthorizedNavItems items={items} />
            <ConversationsPageNavItem />
            <InternalTools />
          </div>
          <div>
            <MiscellaneousItems />
          </div>
        </div>
      </div>
    </div>
  );
};

const AuthorizedNavItems = ({ items }: { items: NavItem[] }) => {
  const location = useLocation();
  const baseRoute = location.pathname.split('/')[1];

  return (
    <div>
      {items.map((i) => {
        const isActive =
          baseRoute === i.to.split('/')[1] ||
          !!i.baseRoutes?.includes(baseRoute);
        return (
          <Authorizer key={i.to} permission={i.permission}>
            <LinkButton
              to={i.to}
              iconRenderer={i.iconRenderer}
              text={i.text}
              isActive={isActive}
            />
          </Authorizer>
        );
      })}
    </div>
  );
};

interface NavItemContentProps {
  iconRenderer?: (isActive: boolean) => JSX.Element;
  text: string;
  isActive: boolean;
}

type LinkButtonProps = LinkProps & NavItemContentProps;

function LinkButton(props: LinkButtonProps): JSX.Element {
  return <Link component={NavItemContent} {...props} />;
}

const NavItemContent: FunctionComponent<
  NavItemContentProps & { navigate: () => void }
> = ({ iconRenderer, text, navigate, isActive }) => {
  return (
    <div
      className={clsx(styles.navItem, {
        [styles.selected]: isActive,
      })}
      onClick={(e) => {
        e.preventDefault();
        navigate();
      }}
    >
      <div
        className={clsx(styles.navItemContent, { [styles.active]: isActive })}
      >
        {iconRenderer && (
          <div className={styles.iconContainer}>{iconRenderer(isActive)}</div>
        )}
        {text}
      </div>
    </div>
  );
};

const ConversationsPageNavItem: FunctionComponent = () => {
  const location = useLocation();
  const baseRoute = location.pathname.split('/')[1];
  const hasAccessToActivityPage = useHasAccessToActivityPage();
  const isActive = 'activity' === baseRoute;
  const iconRenderer = (isActive: boolean) => {
    return <ConversationsNavIcon isActive={isActive} />;
  };

  if (!hasAccessToActivityPage.isEnabled) {
    return null;
  }

  return (
    <LinkButton
      component={NavItemContent}
      to="/activity"
      isActive={isActive}
      iconRenderer={iconRenderer}
      text="Conversations"
    />
  );
};

const InternalTools = () => {
  const internalTools: NavItem[] = [
    {
      text: 'AI Assistants',
      to: '/assistants',
      permission: Permission.UPDATE_AI_ASSISTANTS,
    },
    {
      text: 'Templates',
      to: '/templates',
      permission: Permission.UPDATE_RESPONSE_TEMPLATES,
    },
    {
      text: 'Scopes',
      to: '/scope',
      permission: Permission.READ_RESPONSE_TEMPLATES,
    },
    {
      text: 'Organizations',
      to: '/orgs',
      permission: Permission.READ_ORGS,
      baseRoutes: ['org'],
    },
    {
      text: 'AI Tagging',
      to: '/conversation-state',
      permission: Permission.READ_ORGS, // TODO: make a dedicated AIT permission and role
    },
    {
      text: 'Tools',
      to: '/tools',
      permission: Permission.READ_ORGS,
    },
    {
      text: 'Playground',
      to: '/playground/conversation',
      permission: Permission.READ_ORGS,
    },
    { text: 'Intent Library', to: '/state/intentLibrary' },
  ];
  return (
    <div className={styles.internalTools}>
      <h1 className={styles.navSectionHeader}>Internal Tools</h1>
      <div className={styles.hr} />
      <AuthorizedNavItems items={internalTools} />
    </div>
  );
};

const MiscellaneousItems = () => {
  const referralUrl = 'https://tknvxv07687.typeform.com/to/mccgULAi';
  const helpDeskUrl = 'http://support.meetelise.com';
  const supportUrl = 'https://support.meetelise.com/hc/en-us/requests/new';

  return (
    <div className={styles.miscellaneousItems}>
      <a
        className={clsx(styles.navItem)}
        href={referralUrl}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.navItemContent}>
          <div className={styles.iconContainer}>
            <ReferralIcon />
          </div>
          <span>Referral</span>
        </div>
      </a>
      <a
        className={clsx(styles.navItem)}
        href={helpDeskUrl}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.navItemContent}>
          <div className={styles.iconContainer}>
            <HelpCenterIcon />
          </div>
          <span>Help Center</span>
        </div>
      </a>
      <a
        className={clsx(styles.navItem)}
        href={supportUrl}
        target="_blank"
        rel="noreferrer"
      >
        <div className={styles.navItemContent}>
          <div className={styles.iconContainer}>
            <SupportIcon />
          </div>
          <span>Support</span>
        </div>
      </a>
    </div>
  );
};
