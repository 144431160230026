import axios from 'axios';

import { getTokenOrEmptyString, getTokenOrThrow } from '../auth/auth0';

/**
 * The authenticated axios instance.
 *
 * Assumes the user has authenticated through the UI at the time it's
 * used. Attaches a valid token to requests, or fails if it can't.
 *
 * @deprecated Use `@meetelise/rest-sdk` instead, this is from the time
 * before the REST API had OpenAPI 3.0 definitions.
 */
const instance = axios.create({ baseURL: `/api/v1` });

instance.interceptors.request.use(async (config) => {
  const { dontThrow } = config.params ?? {};
  config.headers.Authorization = `Bearer ${
    dontThrow ? getTokenOrEmptyString() : await getTokenOrThrow()
  }`;
  if (config.params && config.params.dontThrow) {
    delete config.params.dontThrow;
  }
  return config;
});

export const unauthedApi = axios.create({ baseURL: `/api/v1` });

export default instance;
