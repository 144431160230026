import { DateTime } from 'luxon';

import TimexVM from './TimexVM';

interface TimexItemTextProps {
  className?: string;
  item: TimexVM['items'][0];
}

export default function TimexItemText({ className, item }: TimexItemTextProps) {
  let text = item.start.toLocaleString(DateTime.TIME_SIMPLE);
  if (item.end) {
    text += ' – ';
    text += item.end.toLocaleString(DateTime.TIME_SIMPLE);
  }
  if (!item.positive) {
    text = 'NOT ' + text;
  }
  return <span className={className}>{text}</span>;
}
