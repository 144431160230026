import ClassificationDTO from './ClassificationDTO';

/**
 * Most classifications can be modeled exactly as their underlying DTO.
 */
export type SingleClassificationVM = ClassificationDTO;

/**
 * A timex classification is actually a collection of classifications,
 * presented as a single complex classification to the user.
 */
interface TimexClassificationVM {
  /** Constant-type string, for type determination */
  type: 'TimexClassification';
  /**
   * For unique identification in loops.
   *
   * SinlgeClassificationVM.name is type `string`, so this can't be used
   * for type discrimination, but either's `name` prop is used to identify
   * it in loops or titles and such.
   */
  name: 'TimexClassification'; // For keys in loops, same as
  startStop?: SingleClassificationVM;
  dayMonth?: SingleClassificationVM;
  dayWeek?: SingleClassificationVM;
  dayMod?: SingleClassificationVM;
  hour?: SingleClassificationVM;
  minute?: SingleClassificationVM;
  month?: SingleClassificationVM;
  negate?: SingleClassificationVM;
  timeMod?: SingleClassificationVM;
  time?: SingleClassificationVM;
  week?: SingleClassificationVM;
}

// Because we want TimexClassificationVM to be an interface with static methods.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const TimexClassificationVM = {
  toSinglesArray(tvm: TimexClassificationVM): SingleClassificationVM[] {
    return [
      tvm.startStop,
      tvm.dayMonth,
      tvm.dayWeek,
      tvm.dayMod,
      tvm.hour,
      tvm.minute,
      tvm.month,
      tvm.negate,
      tvm.timeMod,
      tvm.time,
      tvm.week,
    ].filter(TimexClassificationVM.isDefined);
  },

  fromSinglesArray(vms: SingleClassificationVM[]): TimexClassificationVM {
    const timexByName = new Map(vms.map((item) => [item.name, item]));

    return {
      type: 'TimexClassification',
      name: 'TimexClassification',
      startStop: timexByName.get('startstop'),
      dayMonth: timexByName.get('daymonth'),
      dayWeek: timexByName.get('dayweek'),
      dayMod: timexByName.get('day-mod'),
      hour: timexByName.get('hour'),
      minute: timexByName.get('minute'),
      month: timexByName.get('month'),
      negate: timexByName.get('negate-time'),
      timeMod: timexByName.get('time-mod'),
      time: timexByName.get('time'),
      week: timexByName.get('week'),
    };
  },

  isDefined(
    classification: SingleClassificationVM | undefined
  ): classification is SingleClassificationVM {
    return classification?.type === 'AttributeClassification';
  },
};

/**
 * Classification view model.
 */

export type TimexKey = keyof Omit<TimexClassificationVM, 'type' | 'name'>;
type ClassificationVM = SingleClassificationVM | TimexClassificationVM;

/**
 * A timex classification bundle has to be serialized and deserialized
 * from this _specific order_ of single classifications, for ML reasons.
 */
export const tvmNamesOrder = [
  'startstop',
  'daymonth',
  'dayweek',
  'day-mod',
  'hour',
  'minute',
  'month',
  'negate-time',
  'time-mod',
  'time',
  'week',
];

// Because we want ClassificationVM to be an interface with static methods.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const ClassificationVM = {
  fromDTO(dto: ClassificationDTO): SingleClassificationVM {
    return dto;
  },
  /**
   * Parse a list of VMs from a list of DTOs.
   *
   * If eleven DTOs are found in the correct order, they will be combined into
   * a single timex VM, so the input and output lengths may not be the same.
   */
  fromDTOs(dtos: ClassificationDTO[]): ClassificationVM[] {
    // `reduce()` is necessary because the DTOs need to be processed statefully,
    // able to keep track of a partial list of timex dtos, (named timexCandidateArray)
    // in case they appear in the order that means "Timex classification group".
    type Accumulator = [ClassificationDTO[], ClassificationVM[]];
    const initialAccumulator: Accumulator = [[], []];
    const finalAccumulator = dtos.reduce<Accumulator>((acc, dto) => {
      const [timexCandidateArray, parsedVMs] = acc;

      // Is this DTO the next one we need in the timex classifications list?
      const isTimexDTO = tvmNamesOrder.includes(dto.name);
      const shouldReset =
        (!isTimexDTO && timexCandidateArray.length > 0) ||
        timexCandidateArray.some((candidate) => candidate.name === dto.name);

      if (shouldReset) {
        const timexVM =
          TimexClassificationVM.fromSinglesArray(timexCandidateArray);
        if (isTimexDTO) {
          return [
            [dto], // Start new candidate list
            parsedVMs.concat(timexVM), // Add the new timex vm to the list
          ];
        } else {
          return [[], parsedVMs.concat(timexVM, dto)];
        }
      }

      if (isTimexDTO) {
        return [timexCandidateArray.concat(dto), parsedVMs];
      } else {
        return [timexCandidateArray, parsedVMs.concat(dto)];
      }
    }, initialAccumulator);

    const [finalCandidates, vms] = finalAccumulator;
    if (finalCandidates.length > 0) {
      return [...vms, TimexClassificationVM.fromSinglesArray(finalCandidates)];
    } else {
      return vms;
    }
  },
  /**
   * Transform a list of VMs into DTOs, to send to the backend.
   *
   * Each timex VM will be expanded into eleven DTOs, so the input
   * and output lengths may not be the same.
   */
  toDTOs(vms: ClassificationVM[]): ClassificationDTO[] {
    return vms.flatMap((vm) =>
      vm.type === 'TimexClassification'
        ? TimexClassificationVM.toSinglesArray(vm)
        : [vm]
    );
  },
};

export { TimexClassificationVM };
export default ClassificationVM;
