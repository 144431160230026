import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';

import ClassificationVM from '../classification/ClassificationVM';
import TimexVM from '../timex/TimexVM';

import IntentDTO from './IntentDTO';
import { IntentTableDTO } from './IntentTableInput';

/** Intent view model */
interface IntentVM {
  type: string;
  name: string;
  text?: null | string;
  texts?: null | string[];
  tables?: null | IntentTableDTO[];
  script_str?: string;
  classifications?: ClassificationVM[];
  description?: string;
  intent_creator?: string;
  timex?: TimexVM | null;
  last_updated?: Date | null;
  last_hash?: number | null;
  tagging_app_metadata?: {
    reactKey: string;
    disabled: boolean;
  };
}

// Because we want IntentVM to be an interface with static methods.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const IntentVM = {
  fromDTO(dto: IntentDTO): IntentVM {
    return {
      ...dto,
      classifications: dto.classifications
        ? ClassificationVM.fromDTOs(dto.classifications)
        : undefined,
      timex: dto.timex && TimexVM.fromDTO(dto.timex),
      last_updated: dto.last_updated ? parseISO(dto.last_updated) : null,
    };
  },

  toDTO(vm: IntentVM): IntentDTO {
    return {
      ...vm,
      classifications: vm.classifications
        ? ClassificationVM.toDTOs(vm.classifications)
        : undefined,
      timex: vm.timex && TimexVM.toDTO(vm.timex),
      last_updated: vm.last_updated ? formatISO(vm.last_updated) : null,
      type: vm.type,
    };
  },
};

export default IntentVM;
