import partition from 'lodash/partition';
import { DateTime } from 'luxon';

import TimexDTO, { TimexItem } from './TimexDTO';

/**
 * A timex, modified for representation in the UI.
 */
interface TimexVM {
  has_date: boolean;
  time_format: string; // Ignore this and pass it back to the backend
  timezone: string;
  items: TimexVMItem[];
}

export interface TimexVMItem {
  start: DateTime;
  end: DateTime | null;
  positive: boolean;
}

export const isTimexVMItem = (item: any): item is TimexVMItem => {
  const timexVMItem = item as TimexVMItem;
  const hasStart =
    !!timexVMItem.start && DateTime.isDateTime(timexVMItem.start);
  const isEndValid = timexVMItem.end
    ? DateTime.isDateTime(timexVMItem.end)
    : true;
  const hasPositive =
    !!timexVMItem.positive && typeof timexVMItem.positive === 'boolean';

  return hasStart && hasPositive && isEndValid;
};

export const ISO_WITH_MICROSECONDS = "yyyy-LL-dd'T'TT.SSS000";

const toDTO: (vm: TimexVM) => TimexDTO = (vm) => {
  const [positives, negatives] = partition(vm.items, 'positive');
  const positiveTimexes: TimexItem[] = positives.map(({ start, end }) => {
    return [start.toUTC().toISO(), end ? end.toUTC().toISO() : null];
  });

  const negativeTimexes: TimexItem[] = negatives.map(({ start, end }) => {
    return [start.toUTC().toISO(), end ? end.toUTC().toISO() : null];
  });
  return {
    has_date: vm.has_date,
    time_format: vm.time_format,
    timezone: vm.timezone,
    type: 'Timex',
    timex: {
      positive_timexes: positiveTimexes,
      negative_timexes: negativeTimexes,
    },
  };
};

export const createTimexItem: (
  start: DateTime,
  end: DateTime | null,
  positive: boolean
) => TimexVMItem = (start, end, positive) => {
  return {
    start,
    end,
    positive,
  };
};

const fromDTO: (dto: TimexDTO) => TimexVM = (dto) => {
  const positiveItems = dto.timex?.positive_timexes ?? [];

  const positiveTimes: TimexVMItem[] = positiveItems
    .map(([start, end]) =>
      createTimexItem(
        DateTime.fromISO(start ?? '', { zone: dto.timezone }),
        end !== null ? DateTime.fromISO(end, { zone: dto.timezone }) : null,
        true
      )
    )
    .filter((time) => time.start.isValid);

  const negativeItems = dto.timex?.negative_timexes ?? [];
  const negativeTimes: TimexVMItem[] = negativeItems
    .map(([start, maybeEnd]) =>
      createTimexItem(
        DateTime.fromISO(start ?? '', { zone: dto.timezone }),
        maybeEnd !== null
          ? DateTime.fromISO(maybeEnd, { zone: dto.timezone })
          : null,
        false
      )
    )
    .filter((time) => time.start.isValid);

  return {
    has_date: dto.has_date,
    time_format: dto.time_format,
    timezone: dto.timezone,
    items: positiveTimes.concat(negativeTimes),
  };
};

// Because we want TimexVM to be an interface with static methods.
// eslint-disable-next-line @typescript-eslint/no-redeclare
const TimexVM = { toDTO, fromDTO, createTimexItem };

export default TimexVM;
