import { useAuth0 } from '@auth0/auth0-react';
import { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ModelIoOutputPage from '../state-viewer/ModelIoOutputPage';

const EditScopePage = lazy(() => import('../scope/EditScopeLoaderPage'));
const CommunityRouter = lazy(() => import('../communities/CommunityRouter'));
const ProfilePage = lazy(() => import('../auth/ProfilePage'));
const AssistantsPage = lazy(() => import('../assistants/AssistantsPage'));
const EditAssistantPage = lazy(() => import('../assistants/EditAssistantPage'));
const CommunitiesPage = lazy(() => import('../communities/CommunitiesPage'));
const ConversationPage = lazy(
  () => import('../conversations/ConversationPage')
);
const EditCommunityPage = lazy(
  () => import('../communities/EditCommunityPage')
);
const OrgDashboardPage = lazy(
  () => import('../organizations/OrgDashboardPage')
);
const EditUserPage = lazy(() => import('../users/EditUserPage'));
const CreateUserPage = lazy(() => import('../users/CreateUserPage'));
const ManageUsersPage = lazy(() => import('../users/ManageUsersPage'));
const LandingPage = lazy(() => import('../landing/LandingPage'));
const StatusPage = lazy(() => import('../layouts/StatusPage'));
const ScriptSearchPage = lazy(() => import('../scripts/ScriptSearchPage'));
const ScriptAddPage = lazy(() => import('../scripts/ScriptAddPage'));
const ScriptEditPage = lazy(() => import('../scripts/ScriptEditPage'));
const NotFoundPage = lazy(() => import('../layouts/NotFoundPage'));
const UnsubscribePage = lazy(() => import('../unsubscribe/UnsubscribePage'));
const AntiCrmCalendarPage = lazy(() => import('../calendar/CalendarPage'));
const TagStatePage = lazy(() => import('../state-tagger/TagNextStatePage'));
const EscalatedTagsQueue = lazy(
  () => import('../state-tagger/escalations/EscalatedTagsQueue')
);
const DeescalatePage = lazy(
  () => import('../state-tagger/escalations/DeescalatePage')
);
const TaggingStats = lazy(
  () => import('../state-tagger/state/tagging-dashboards/TaggingStatsPage')
);
const EntityPage = lazy(() => import('../entity/EntityPage'));
const CreateEntityPage = lazy(() => import('../entity/CreateEntityPage'));
const EntityCalendarAvailabilitiesPage = lazy(
  () =>
    import('../entity/calendarAvailabilities/EntityCalendarAvailabilitiesPage')
);
const EntityLinksPage = lazy(() => import('../entity/EntityLinksPage'));
const StateViewerPage = lazy(() => import('../state-viewer/StateViewerPage'));
const ConvAIObjectPage = lazy(
  () => import('../ConvAIComponentLibrary/ConvAIPage/ConvAIObjectPage')
);
const TimetravelPage = lazy(() => import('../state-viewer/TimetravelPage'));
const Semantle = lazy(() => import('../games/Semantle'));
const SemantleNew = lazy(() => import('../games/SemantleNew'));
const BrainfreezesPage = lazy(() => import('../state-viewer/BrainfreezesPage'));
const BrainfreezePage = lazy(() => import('../state-viewer/BrainfreezePage'));
const PagerPage = lazy(() => import('../pager/PagerPage'));
const IssuePage = lazy(() => import('../health/IssuePage'));
const IssuesPage = lazy(() => import('../health/IssuesPage'));
const StatusUpdatesPage = lazy(() => import('../health/StatusUpdatesPage'));
const IssueTypePage = lazy(() => import('../health/IssueTypePage'));
const EntitySearchPage = lazy(() => import('../health/EntitySearchPage'));
const EntityHealthPage = lazy(() => import('../health/EntityPage'));
const HealthReportPage = lazy(() => import('../health/HealthReportPage'));
const StatusTypePage = lazy(() => import('../health/StatusTypePage'));
const HealthPage = lazy(() => import('../health/HealthPage'));
const ModelIoInputPage = lazy(() => import('../state-viewer/ModelIoInputPage'));
const WebChat = lazy(() => import('../chat/ChatBookmarkletPage'));
const ReportingPage = lazy(() => import('../reporting/ReportingPage'));
const ChatDemoPage = lazy(() => import('../chat/ChatDemoPage'));
const DashboardPage = lazy(() => import('../dashboard/DashboardLoaderPage'));
const MEHomeConversationsPage = lazy(
  () => import('../mehomeconversations/ConversationsPage')
);
const ConvRedirect = lazy(() => import('../mehomeconversations/ConvRedirect'));
const OrgListPage = lazy(() => import('../organizations/OrgListPage'));
const PartySearchPage = lazy(() => import('../search/PartySearchPage'));
const MigrationPage = lazy(() => import('../migration/MigrationPage'));
const SchedulingTogglePage = lazy(
  () => import('../schedulingToggle/SchedulingTogglePage')
);
const CalendarV2Page = lazy(() => import('../calendarV2/CalendarV2Page'));
const CommunitiesV2TablePage = lazy(
  () =>
    import('../communitiesv2/CommunitiesTablePage/CommunitiesTableLoaderPage')
);
const CommunitiesV2DetailsPage = lazy(
  () =>
    import('../communitiesv2/CommunityDetailsPage/CommunityDetailsLoaderPage')
);
const ToolsPage = lazy(() => import('../tools/ToolsPage'));
const ValidateCredentialsPage = lazy(
  () => import('../validatecredentials/ValidateCredentialsPage')
);
const ScopeSearchPage = lazy(() => import('../scopev2/Search/ScopeSearchPage'));
const ScopeEditPage = lazy(() => import('../scopev2/Edit/EditScopePage'));
const TaggingDebuggerByInputPage = lazy(
  () => import('../state-tagger/TaggingDebuggerByInputPage')
);
const TaggingDebuggerByIdInputPage = lazy(
  () => import('../state-tagger/TaggingDebuggerByIdInputPage')
);
const AssignJob = lazy(() => import('../state-tagger/AssignJob'));
const TaggingDebuggerByIdPage = lazy(
  () => import('../state-tagger/TaggingDebuggerById')
);
const ResumeTagSession = lazy(() => import('../state-tagger/ResumeTagSession'));
const TaggedStateViewerPage = lazy(
  () => import('../state-tagger/tagged-states/TaggedStateViewerPage')
);

const TaggedStatePage = lazy(
  () => import('../state-tagger/tagged-states/TaggedStatePage')
);

const ReportingV2Page = lazy(
  () => import('../reportingV2/ReportingV2LoaderPage')
);

const ConversationPlaygroundPage = lazy(
  () => import('../conversationplayground/ConversationPlaygroundPage')
);

const ConversationPlaygroundLatestFetchPage = lazy(
  () =>
    import('../conversationplayground/ConversationPlaygroundLatestFetchPage')
);

const ModifySnapshotPage = lazy(
  () => import('../modifysnapshot/ModifySnapshotPage')
);
const ViewSnapshotsPage = lazy(
  () => import('../viewsnapshots/ViewSnapshotsPage')
);

const IntentSandbox = lazy(
  () => import('../state-tagger/intent/IntentSandbox')
);

const V2ScriptQAPage = lazy(() => import('../v2sriptqa/V2ScriptQAPage'));
const MarkersPage = lazy(() => import('../state-viewer/MarkersPage'));
const GuestCardPage = lazy(() => import('../state-viewer/GuestCardPage'));
const IntentLibrary = lazy(
  () => import('../state-tagger/intent/IntentLibrary')
);
const SnippetsGraphPage = lazy(() => import('./GraphTool/SnippetsGraphPage'));
const VerifyExpansionsPage = lazy(
  () => import('../verifyexpansion/VerifyExpansionsPage')
);

const SnapshotsRangePage = lazy(
  () => import('../entity/snapshots/range/SnapshotsRangePage')
);
const DatasetCorrectorPage = lazy(
  () => import('../datasetCorrector/DatasetCorrectorPage')
);
const ActivityPage = lazy(() => import('../activity/ActivityPage'));

export function AppRouteSwitch() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route
        exact
        path="/login"
        render={() => {
          loginWithRedirect();
          return null;
        }}
      />
      <Route exact path="/dataset/:id" component={DatasetCorrectorPage} />
      <Route exact path="/tools" component={ToolsPage} />
      <Route exact path="/tools/:toolName" component={ToolsPage} />
      <Route exact path="/profile" component={ProfilePage} />
      <Route exact path="/assistants" component={AssistantsPage} />
      <Route exact path="/assistants/:id" component={EditAssistantPage} />
      <Route exact path="/users" component={ManageUsersPage} />
      <Route path="/users/new" component={CreateUserPage} />
      <Route path="/user/:id" component={EditUserPage} />
      <Route exact path="/org/:id" component={OrgDashboardPage} />
      <Route exact path="/orgs" component={OrgListPage} />
      <Route exact path="/activity" component={ActivityPage} />
      <Route exact path="/communities" component={CommunitiesPage} />
      <Route exact path="/communities/v2" component={CommunitiesV2TablePage} />
      <Route
        exact
        path="/communities/v2/details/:buildingId"
        component={CommunitiesV2DetailsPage}
      />
      <Route exact path="/verify/expansion" component={VerifyExpansionsPage} />
      <Route exact path="/integrations" component={ValidateCredentialsPage} />
      <Route exact path="/search" component={PartySearchPage} />
      <Route exact path="/migration" component={MigrationPage} />
      <Route exact path="/scheduling-toggle" component={SchedulingTogglePage} />
      <Redirect
        exact
        path="/communities/new"
        to="https://meetelise.retool.com/apps/onboarding/community-setup"
      />
      <Redirect exact path="/communities/:id" to="/communities/:id/settings" />
      <Route
        exact
        path="/communities/:id/settings"
        component={EditCommunityPage}
      />
      <Route
        exact
        path="/communities/:id/calendar"
        component={AntiCrmCalendarPage}
      />
      <Route exact path="/redesign/calendar" component={CalendarV2Page} />
      <Route exact path="/conv/:id" component={ConversationPage} />
      <Route exact path="/conv/mc/:mcId" component={ConvRedirect} />
      <Route exact path="/conversations/" component={MEHomeConversationsPage} />
      <Route exact path="/games/semantle/new" component={SemantleNew} />
      <Route exact path="/games/semantle/game/:gameId" component={Semantle} />
      <Route
        path="/qa/building/:buildingId/answers"
        component={V2ScriptQAPage}
      />
      <Route
        path="/playground/building/:buildingId/latest"
        component={ConversationPlaygroundLatestFetchPage}
      />
      <Route
        exact
        path="/playground/conversation"
        component={ConversationPlaygroundPage}
      />
      <Route exact path="/scope/v2" component={ScopeSearchPage} />
      <Route path="/scope/v2/:id" component={ScopeEditPage} />
      <Redirect exact path="/scope" to="/scope/sms/default" />
      <Route
        path="/scope/:scopeName/:scopeClient/:intent?"
        component={EditScopePage}
      />
      <Route exact path="/intentSandbox" component={IntentSandbox} />
      <Route exact path="/templates" component={ScriptSearchPage} />
      <Route exact path="/templates/new" component={ScriptAddPage} />
      <Route exact path="/templates/:id" component={ScriptEditPage} />
      <Route exact path="/status" component={StatusPage} />
      <Route exact path="/calendar" component={CalendarV2Page} />
      <Route exact path="/calendar/:id" component={CalendarV2Page} />
      <Route path="/unsubscribe" component={UnsubscribePage} />
      <Redirect
        exact
        path="/conversation-state"
        to="/conversation-state/next"
      />
      <Route path="/conversation-state/next" component={TagStatePage} />
      <Route
        exact
        path="/conversation-state/escalation-queue"
        component={EscalatedTagsQueue}
      />
      <Route
        exact
        path="/conversation-state/deescalate/:tagJobId"
        component={DeescalatePage}
      />
      <Route
        path="/conversation-state/debugger"
        component={TaggingDebuggerByInputPage}
      />
      <Route
        exact
        path="/conversation-state/debugById"
        component={TaggingDebuggerByIdInputPage}
      />
      <Route
        exact
        path="/conversation-state/:tagIdType(tagJobId|tagJobBatchId)/:id"
        component={AssignJob}
      />
      {/* :tagIdType is either 'tagId' or 'tagJobId' */}
      <Route
        exact
        path="/conversation-state/debugById/:tagIdType/:id"
        component={TaggingDebuggerByIdPage}
      />
      <Route
        exact
        path="/conversation-state/resumeTagSession/:tagId"
        component={ResumeTagSession}
      />
      <Route path="/tagged-states/:id/:type" component={TaggedStatePage} />
      <Route path="/tagged-states" component={TaggedStateViewerPage} />

      <Redirect exact path="/conversation-state/:id" to="/state/:id" />
      <Route path="/state/:id/timetravel" component={TimetravelPage} />
      <Route exact path="/state/:id/brainfreeze" component={BrainfreezesPage} />
      <Route
        path="/state/:id/brainfreeze/:brainfreezeId"
        component={BrainfreezePage}
      />
      <Route path="/state/intentLibrary" component={IntentLibrary} />
      <Route path="/state/:id/markers" component={MarkersPage} />
      <Route path="/state/:id/guestCard" component={GuestCardPage} />

      <Route path="/pager" component={PagerPage} />
      <Route path="/entity/create" component={CreateEntityPage} />
      <Route
        exact
        path="/entity/:entityId/snapshots/range"
        component={SnapshotsRangePage}
      />
      <Route
        path="/entity/:entityId/calendar_availabilities"
        component={EntityCalendarAvailabilitiesPage}
      />
      <Route path="/entity/:entityId/links" component={EntityLinksPage} />
      <Route path="/entity/:entityId" component={EntityPage} />
      <Route path="/health/statusUpdates" component={StatusUpdatesPage} />
      <Route path="/health/entity/search" component={EntitySearchPage} />
      <Route path="/health/entity/:entityId" component={EntityHealthPage} />
      <Route path="/health/statusType/:statusType" component={StatusTypePage} />
      <Route path="/health/issue/:issueId" component={IssuePage} />
      <Route path="/health/issues" component={IssuesPage} />
      <Route path="/health/issueTypes/:issueType" component={IssueTypePage} />
      <Route path="/health/report" component={HealthReportPage} />
      <Route path="/health" component={HealthPage} />
      <Route path="/state/timetravel/:id/input" component={ModelIoInputPage} />
      <Route
        path="/state/timetravel/:id/output"
        component={ModelIoOutputPage}
      />
      <Route path="/state/taggingStats" component={TaggingStats} />

      <Route exact path="/state/:id" component={StateViewerPage} />

      <Route path="/object/:objectId" component={ConvAIObjectPage} />
      <Route path="/webchat" component={WebChat} />
      <Route path="/chat-demo" component={ChatDemoPage} />
      <Route exact path="/reporting" component={ReportingPage} />
      <Route exact path="/reporting/v2" component={ReportingV2Page} />
      <Route exact path="/reporting/v2/:section" component={ReportingV2Page} />
      <Route exact path="/graphs/snippets" component={SnippetsGraphPage} />
      <Route path="/snapshot/all" exact component={ViewSnapshotsPage} />
      <Route path="/snapshot/:stateSnapshotId" component={ModifySnapshotPage} />
      <Route path="/dashboard" component={DashboardPage} />
      {/* This route must stay at the bottom of the list since it does not have anything before the slugs */}
      <Route path="/:organization/:building" component={CommunityRouter} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}
