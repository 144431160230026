import { Tabs, Tab } from '@blueprintjs/core';
import { useState } from 'react';

import { safelyRenderMessageHtml } from '../../utils';

const ToggleableEmailBodyView = ({
  message,
  body,
  iframeTitle,
}: {
  message?: string;
  body: string;
  iframeTitle: string;
}) => {
  const [selectedTabId, setSelectedTabId] = useState(
    message ? 'plain-text' : 'html'
  );
  const bodyToUse = typeof body === 'number' ? '' + body : body;
  const styledEmailBodyDiv = `<div>${safelyRenderMessageHtml(
    (bodyToUse || '').replaceAll(/\n/g, '<br />')
  )}</div>`;
  const bodyView = (
    <iframe
      title={iframeTitle}
      style={{
        border: 'none',
        width: '100%',
        height: '400px',
      }}
      srcDoc={styledEmailBodyDiv}
    />
  );

  return (
    <div>
      <Tabs
        id="email-message-toggle"
        selectedTabId={selectedTabId}
        onChange={(id) => setSelectedTabId(id as string)}
        renderActiveTabPanelOnly
      >
        <Tab title="Plain Text" id="plain-text" disabled={!message} />
        <Tab title="HTML" id="html" />
      </Tabs>
      <div
        style={{
          whiteSpace: 'pre-wrap',
          paddingLeft: '16px',
          borderLeft: '2px lightgray solid',
          marginTop: '16px',
          marginBottom: '16px',
          overflow: 'auto',
        }}
      >
        {selectedTabId === 'plain-text' ? (
          <div
            dangerouslySetInnerHTML={{
              __html: safelyRenderMessageHtml(
                (message ?? '').replaceAll(/\n/g, '<br />')
              ),
            }}
          />
        ) : (
          bodyView
        )}
      </div>
    </div>
  );
};

export default ToggleableEmailBodyView;
