export const getName = (
  firstName?: string | null,
  lastName?: string | null,
  fallback = 'prospect'
) => {
  if (!firstName && lastName) {
    return lastName;
  }
  if (!firstName && !lastName) {
    return fallback;
  }
  const separator = lastName ? ' ' : '';

  return `${firstName}${separator}${lastName ?? ''}`;
};
