/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AppointmentScheduledUpdateDto = {
    type?: AppointmentScheduledUpdateDto.type;
    appointment_time?: string;
    appointment_end_time?: string;
    tour_type?: AppointmentScheduledUpdateDto.tour_type;
    update_id?: number;
    appointment_external_id?: string;
}

export namespace AppointmentScheduledUpdateDto {

    export enum type {
        WALK_IN = 'WALK_IN',
        APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
        APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
        SHOW = 'SHOW',
        AGENT_TAKEOVER = 'AGENT_TAKEOVER',
        INACTIVATION = 'INACTIVATION',
        NO_SHOW = 'NO_SHOW',
        AGENT_NOTE = 'AGENT_NOTE',
        LEAD_NOTE = 'LEAD_NOTE',
        ELISE_REACTIVATED = 'ELISE_REACTIVATED',
        AGENT_PERSONALIZED_MESSAGE = 'AGENT_PERSONALIZED_MESSAGE',
        ADDITIONAL_INTENTS = 'ADDITIONAL_INTENTS',
    }

    export enum tour_type {
        UNKNOWN = 'UNKNOWN',
        NONE = 'NONE',
        ANY = 'ANY',
        NOT_ESCORTED = 'NOT_ESCORTED',
        ESCORTED = 'ESCORTED',
        NOT_SELF_GUIDED = 'NOT_SELF_GUIDED',
        SELF_GUIDED = 'SELF_GUIDED',
        NOT_VIRTUAL_TOUR = 'NOT_VIRTUAL_TOUR',
        VIRTUAL_TOUR = 'VIRTUAL_TOUR',
        NOT_MEDIA_TOUR = 'NOT_MEDIA_TOUR',
        MEDIA_TOUR = 'MEDIA_TOUR',
    }


}
