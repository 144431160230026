import React, { useEffect, useMemo } from 'react';
import {
  TableOptions,
  usePagination,
  useSortBy,
  useTable,
  useFilters,
  Filters,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import filterTypes from './filterTypes';
import TableUI from './TableUI';

interface TableProps<D extends object> extends TableOptions<D> {
  loading?: boolean; // TODO implement
  title?: string;
  copyCellOnClick?: boolean;
  persistFilters?: (filters: Filters<D>) => void;
  filters?:
    | {
        id: string;
        value: any;
      }[]
    | null;
}

/**
 * A standard <Table /> element to unify tables in the app.
 *
 * This uses react-table for logic and a fluent API, and
 * Blueprint's HTMLTable for styles. Viz., this is glue code
 * to couple Blueprint's HTMLTable to react-table's logic:
 * pagination, layout, sorting, etc.
 */
export default function Table<D extends object>(options: TableProps<D>) {
  // react-table gives mostly sensible defaults to Column options,
  // except that it enables filters by default, which isn't a good
  // default for this project. Instead, this disables filtering on
  // columns without a `Filter` property.
  const columns = useMemo(
    () =>
      options.columns.map((column) => ({
        ...column,
        disableFilters: !column.Filter,
      })),
    [options.columns]
  );

  const instance = useTable(
    {
      filterTypes,
      ...options,
      columns,
    },
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  if (instance.initialState && options.filters) {
    instance.initialState.filters = options.filters;
  }

  useEffect(() => {
    if (options.persistFilters) {
      options.persistFilters(instance.state.filters);
    }
  });

  return (
    <TableUI
      instance={instance}
      title={options.title}
      copyCellOnClick={options.copyCellOnClick}
    />
  );
}
