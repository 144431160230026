import { useQuery } from 'react-query';

import AuthUser from '../auth/AuthUser';
import { useMaybeUser } from '../auth/hooks';
import Permission from '../auth/Permission';

import { getEnabledConersationPageBuildings } from './useEnabledBuildings';

export const useHasAccessToActivityPage = () => {
  const user = useMaybeUser();
  const { data, isLoading } = useQuery(
    ['useHasAccessToActivityPage'],
    async () => await hasAccessToActivityPage(user)
  );
  return { isEnabled: data, isLoading };
};

const hasAccessToActivityPage = async (user: AuthUser | null) => {
  const { orgIds, buildingIds } = await getEnabledConersationPageBuildings();
  if (!user) {
    return false;
  }

  if (user.isInternal) {
    return true;
  }

  if (buildingIds.filter((eb) => user.communityIds.includes(eb)).length > 0) {
    return true;
  }

  if (
    orgIds.includes(user.organizationId) &&
    user.hasPermission(Permission.READ_BUILDINGS)
  ) {
    return true;
  }

  return false;
};
