import { Tag, Intent as UIIntent } from '@blueprintjs/core';
import { MenuItem2 } from '@blueprintjs/popover2';
import { MultiSelect2 } from '@blueprintjs/select';
import { produce } from 'immer';

import { SingleClassificationVM } from './ClassificationVM';
import useDefaultNanLabel from './useDefaultNanLabel';

interface ClassificationSelectProps {
  classification: SingleClassificationVM;
  onChangeClassification?(c: SingleClassificationVM): void;
  className?: string;
}

/**
 * A select dropdown for a classification with a single value.
 */
export default function ClassificationMultiSelect(
  props: ClassificationSelectProps
) {
  const { classification, onChangeClassification, className } = props;
  const { selected, labels } = classification;
  const canFilter = (labels ?? []).length > 7;

  useDefaultNanLabel(classification, onChangeClassification);

  const onClassificationItemSelect = (item: string) => {
    const isItemNan = item.startsWith('nan_');
    const isItemSelected = selected?.some((el) => el === item);

    if (isItemSelected) {
      return onChangeClassification?.(
        produce(classification, (draft) => {
          draft.selected = (draft.selected ?? []).filter((el) => el !== item);
        })
      );
    }
    if (isItemNan) {
      return onChangeClassification?.(
        produce(classification, (draft) => {
          draft.selected = [item];
        })
      );
    }
    return onChangeClassification?.(
      produce(classification, (draft) => {
        draft.selected = (draft.selected ?? [])
          .concat(item)
          .filter((el) => !el.startsWith('nan_'));
      })
    );
  };

  return (
    <MultiSelect2
      className={className}
      items={labels ?? []}
      itemRenderer={(item, { index, handleClick, modifiers }) => (
        <MenuItem2
          key={`${item}-${index}`}
          text={item}
          onClick={handleClick}
          active={modifiers.active && canFilter}
          selected={selected?.includes(item)}
          disabled={modifiers.disabled}
          roleStructure="listoption"
        />
      )}
      itemPredicate={(query, item) =>
        item.toLowerCase().includes(query.toLowerCase())
      }
      resetOnSelect
      selectedItems={selected ?? []}
      onItemSelect={onClassificationItemSelect}
      onRemove={(_item, iToRemove) => {
        onChangeClassification?.(
          produce(classification, (draft) => {
            draft.selected = selected?.filter((_, i) => i !== iToRemove);
          })
        );
      }}
      tagRenderer={(value) => <Tag>{value}</Tag>}
      tagInputProps={{
        leftIcon: (
          <Tag
            intent={UIIntent.PRIMARY}
            style={{
              marginTop: 5,
              marginBottom: 5,
              marginRight: 7,
              height: 24,
            }}
          >
            {classification.name}
          </Tag>
        ),
      }}
    />
  );
}
