/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MEHomeTourInfo } from './MEHomeTourInfo';

export type MEHomeCalendarEvent = {
    id: number;
    aiAssistantId: number;
    startTimeISO: string;
    endTimeISO: string;
    eventType: MEHomeCalendarEvent.eventType;
    tourInfo?: MEHomeTourInfo;
    title?: string;
    conversationKey?: string;
    recurringEventId?: number;
    assignedAgentId?: number;
    canceled?: boolean;
}

export namespace MEHomeCalendarEvent {

    export enum eventType {
        NON_BLOCKING_INTERNAL = 'NON_BLOCKING_INTERNAL',
        BLACKOUT = 'BLACKOUT',
        APPOINTMENT = 'APPOINTMENT',
        OTHER = 'OTHER',
    }


}
