import { useAuth0 } from '@auth0/auth0-react';
import {
  Navbar as BlueprintNavbar,
  Boundary,
  Button,
  Menu,
  MenuDivider,
  OverflowList,
  Position,
  AnchorButton,
} from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Authorizer from '../../auth/Authorizer';
import { useAuthUser } from '../../auth/hooks';
import Permission from '../../auth/Permission';
import {
  Building,
  ChatLeftText,
  DoorClosed,
  DoorOpen,
  Envelope,
  Globe,
  People,
  Person,
  QuestionCircle,
  ThreeDots,
} from '../../components/Icons';
import LinkButton from '../../components/LinkButton';
import { ReactComponent as MeetEliseLogo } from '../VanillaScssPage/ME_Logo-Default.svg';

export default function Navbar() {
  return (
    <BlueprintNavbar className="d-flex align-items-center" fixedToTop>
      <MeetEliseLogo />
      <Authorizer fallback={<SupportLinkButton />}>
        <AppLinks />
      </Authorizer>
      <BlueprintNavbar.Divider />
      <Authorizer fallback={<LoginButton />}>
        <AuthUserIcon />
      </Authorizer>
    </BlueprintNavbar>
  );
}

interface NavItem {
  text: string;
  icon: JSX.Element;
  to: string;
  permission?: Permission;
}

const items: NavItem[] = [
  {
    text: 'AI Tagging',
    icon: <ChatLeftText />,
    to: '/conversation-state',
    permission: Permission.READ_ORGS, // TODO: make a dedicated AIT permission and role
  },
  {
    text: 'Templates',
    icon: <ChatLeftText />,
    to: '/templates',
    permission: Permission.UPDATE_RESPONSE_TEMPLATES,
  },
  {
    text: 'Organizations',
    icon: <Globe />,
    to: '/',
    permission: Permission.READ_ORGS,
  },
  {
    text: 'Communities',
    icon: <Building />,
    to: '/communities',
  },
  {
    text: 'AI Leasing Assistants',
    icon: <Envelope />,
    to: '/assistants',
    permission: Permission.UPDATE_AI_ASSISTANTS,
  },
  {
    text: 'Manage Users',
    icon: <People />,
    to: '/users',
    permission: Permission.UPDATE_EXTERNAL_USERS,
  },
];

const AppLinks: FC<{ className?: string }> = (p) => (
  <OverflowList
    className={p.className}
    items={items}
    collapseFrom={Boundary.END}
    visibleItemRenderer={visibleItemRenderer}
    overflowRenderer={overflowRenderer}
  />
);

const visibleItemRenderer = (item: NavItem, i: number) => {
  return (
    <Authorizer key={i} permission={item.permission}>
      <LinkButton
        minimal
        icon={item.icon}
        to={item.to}
        style={{ whiteSpace: 'nowrap' }}
      >
        {item.text}
      </LinkButton>
    </Authorizer>
  );
};

const overflowRenderer = (items: NavItem[]) => (
  <Popover2
    className="p-2"
    position={Position.BOTTOM}
    content={
      <Menu>
        {items.map((item, i) => (
          <DropdownNavItem key={i} item={item} />
        ))}
      </Menu>
    }
  >
    <ThreeDots />
  </Popover2>
);

const DropdownNavItem: FC<{ item: NavItem }> = ({ item }) => {
  const history = useHistory();
  return (
    <Authorizer permission={item.permission}>
      <MenuItem2
        icon={item.icon}
        text={item.text}
        onClick={() => history.push(item.to)}
      />
    </Authorizer>
  );
};

const AuthUserIcon: FC = () => {
  const { logout } = useAuth0();
  return (
    <Popover2
      content={
        <Menu>
          <MenuDivider title={useAuthUser().email} />
          <Link
            to="/profile"
            component={(p) => (
              <MenuItem2 {...p} icon={<Person />} text="Profile" />
            )}
          />
          <MenuItem2
            icon={<QuestionCircle />}
            href="//support.meetelise.com"
            text="Support"
          />
          <MenuItem2
            icon={<DoorClosed />}
            onClick={() => logout({ returnTo: window.location.origin })}
            text="Sign out"
          />
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button icon={<Person />} minimal />
    </Popover2>
  );
};

const LoginButton: FC = () => {
  const { loginWithRedirect, isLoading } = useAuth0();
  return (
    <Button
      minimal
      icon={<DoorOpen />}
      text="Log In"
      loading={isLoading}
      onClick={loginWithRedirect}
    />
  );
};

const SupportLinkButton: FC<{ className?: string }> = (p) => {
  return (
    <AnchorButton
      className={p.className}
      icon={<QuestionCircle />}
      minimal
      text="Support"
      href="//support.meetelise.com"
    />
  );
};
