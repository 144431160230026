/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { XagacityUser } from '../models/XagacityUser';
import { request as __request } from '../core/request';

export class UserApiControllerService {

    /**
     * @param id
     * @returns XagacityUser OK
     * @throws ApiError
     */
    public static async getOne1(
        id: number,
    ): Promise<XagacityUser> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/user/${id}`,
        });
        return result.body;
    }

    /**
     * @param id
     * @param requestBody
     * @returns XagacityUser OK
     * @throws ApiError
     */
    public static async update1(
        id: number,
        requestBody: XagacityUser,
    ): Promise<XagacityUser> {
        const result = await __request({
            method: 'PUT',
            path: `/api/v1/user/${id}`,
            body: requestBody,
        });
        return result.body;
    }

    /**
     * @param active
     * @returns XagacityUser OK
     * @throws ApiError
     */
    public static async getAll(
        active?: boolean,
    ): Promise<Array<XagacityUser>> {
        const result = await __request({
            method: 'GET',
            path: `/api/v1/user/`,
            query: {
                'active': active,
            },
        });
        return result.body;
    }

}