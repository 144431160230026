import { Button, Intent as UIIntent, Dialog, Classes } from '@blueprintjs/core';
import { produce } from 'immer';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import appToaster from '../../appToaster';
import { getNewPartyProperty } from '../state/taggingApiRepository';

import { AddPartyPropertyButton } from './AddPartyPropertyButton';
import PartyDTO from './PartyDTO';
import { PartyPropertyDisplay } from './PartyPropertyDisplay';
import { PartyPropertyDTO } from './PartyPropertyDTO';
import PartyPropertyVM from './PartyPropertyVM';
import PartyVM from './PartyVM';

interface UpdatePartyDialogProps {
  isOpen: boolean;
  party: PartyVM;
  scopeName: string;
  onUpdateParty?(party: PartyDTO): void;
  onClose(): void;
  readOnly?: boolean;
}

/**
 * A dialog that shows a party in a form, for editing.
 *
 * When the user clicks 'OK', calls the callback.
 */
export default function UpdatePartyDialog(props: UpdatePartyDialogProps) {
  const { isOpen, party, scopeName, onUpdateParty, onClose, readOnly } = props;

  // As in "current working state of the party in the dialog form", not fantasy football
  const [draftParty, setDraftParty] = useState(party);

  const defaultTimezone = party.timezone;

  const [requestGetNewPartyProperty, { isLoading: isAddingPartyProperty }] =
    useMutation(getNewPartyProperty, {
      onSuccess: (partyProperty: PartyPropertyDTO) => {
        const updatedParty = produce(draftParty, (draft) => {
          draft.properties.push(PartyPropertyVM.fromDTO(partyProperty));
        });
        setDraftParty(updatedParty);
      },
      onError: (e) => {
        appToaster.toastError(e);
      },
    });

  useEffect(() => {
    setDraftParty(party);
  }, [party]);

  const { name, timezone, properties } = draftParty;
  return (
    <Dialog
      isOpen={isOpen}
      title={`${readOnly ? '' : 'Update'} ${party.displayName}`}
      onClose={onClose}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          ID: <strong>{name}</strong>
        </p>
        <p>
          Timezone: <strong>{timezone}</strong>
        </p>
        {properties.map((property, i) => {
          const key = `${name}-${i}`;
          return (
            <PartyPropertyDisplay
              key={key}
              // classificationOptions={classifications} // TODO: maybe add this back
              partyProperty={property}
              onRemoveProperty={() => {
                const updatedParty = produce(draftParty, (draft) => {
                  draft.properties = draft.properties.filter((_, j) => i !== j);
                });

                setDraftParty(updatedParty);
              }}
              onChangeProperty={(partyProperty) => {
                const updatedParty = produce(draftParty, (draft) => {
                  draft.properties[i] = partyProperty;
                });
                setDraftParty(updatedParty);
              }}
              isReadOnly={!!readOnly}
            />
          );
        })}
        {!readOnly && (
          <AddPartyPropertyButton
            minimal={true}
            scopeName={scopeName}
            className="mt-4"
            loading={isAddingPartyProperty}
            defaultTimezone={defaultTimezone}
            onAddPartyProperty={(propertyName) => {
              requestGetNewPartyProperty({ propertyName });
            }}
          />
        )}
      </div>
      {!readOnly ? (
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button text="Cancel" onClick={onClose} />
            <Button
              intent={UIIntent.PRIMARY}
              text="OK"
              onClick={() => {
                onClose();
                const dto = PartyVM.toDTO(draftParty);
                onUpdateParty?.(dto);
              }}
            />
          </div>
        </div>
      ) : null}
    </Dialog>
  );
}
