import { produce } from 'immer';
import { useEffect } from 'react';

import ClassificationDTO from './ClassificationDTO';

const useDefaultNanLabel = (
  classification: ClassificationDTO,
  onChangeClassification?: (newClassification: ClassificationDTO) => void
) => {
  useEffect(() => {
    const { labels, selected } = classification;
    const nanLabel = labels?.find((el) => el.startsWith('nan_'));
    if ((selected?.length ?? 0) > 0 || !nanLabel) {
      return;
    }

    onChangeClassification?.(
      produce(classification, (draft) => {
        draft.selected = [nanLabel];
      })
    );
  }, [classification, onChangeClassification]);
};

export default useDefaultNanLabel;
