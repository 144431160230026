import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.scss';
import './initialize';
import './vanillaelise/Text.scss';

import ReactDOM from 'react-dom';

import App from './app/App';
import AppContexts from './app/AppContexts';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <AppContexts>
    <App />
  </AppContexts>,
  document.getElementById('root')
);

reportWebVitals();
