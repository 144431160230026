import AttributeClassificationDTO from './AttributeClassificationDTO';

const TIMEX_CLASSIFICATION_NAMES = [
  'startstop',
  'daymonth',
  'dayweek',
  'day-mod',
  'hour',
  'minute',
  'month',
  'negate-time',
  'time-mod',
  'time',
  'week',
];

export const isTimexClassification = (
  classification: AttributeClassificationDTO
) => {
  return TIMEX_CLASSIFICATION_NAMES.includes(classification.name);
};
