import { ControlGroup, Button, Tag } from '@blueprintjs/core';
import { MenuItem2 } from '@blueprintjs/popover2';
import { Select2 } from '@blueprintjs/select';
import { produce } from 'immer';

import { SingleClassificationVM } from './ClassificationVM';

interface ClassificationSingleSelectProps {
  classification: SingleClassificationVM;
  onChangeClassification?(c: SingleClassificationVM): void;
  className?: string;
}

/**
 * A select dropdown for a classification with a single value.
 */
export default function ClassificationSingleSelect(
  props: ClassificationSingleSelectProps
) {
  const { classification, onChangeClassification, className } = props;
  const options = classification.labels;
  const canFilter = (options ?? []).length > 7;
  return (
    <Select2
      items={options ?? []}
      itemRenderer={(item, { handleClick, modifiers }) => (
        <MenuItem2
          key={item}
          text={item}
          onClick={handleClick}
          active={modifiers.active}
          selected={classification.selected?.includes(item)}
          disabled={modifiers.disabled}
          roleStructure="listoption"
        />
      )}
      itemPredicate={(query, item) =>
        item.toLowerCase().includes(query.toLowerCase())
      }
      filterable={canFilter}
      onItemSelect={(item) =>
        onChangeClassification?.(
          produce(classification, (draft) => {
            draft.selected = [item];
          })
        )
      }
    >
      <ControlGroup className={className}>
        <Button
          rightIcon="chevron-down"
          icon={<Tag>{classification.name}</Tag>}
        >
          {classification.selected?.[0]}
        </Button>
      </ControlGroup>
    </Select2>
  );
}
