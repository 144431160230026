import styles from './TimexChart.module.scss';

// Note: go to https://www.gdoctohtml.com/ in order to convert google doc table to html

const TimexSchedulingChart = () => {
  return (
    <table className={styles.timexChart}>
      <tbody>
        <tr>
          <td>
            <strong>Lead phrasing is similar to:</strong>
          </td>
          <td>
            <strong>Select Absolute Timex:</strong>
          </td>
        </tr>
        <tr>
          <td>no timeframe given</td>
          <td>Leave timex alone</td>
        </tr>
        <tr>
          <td>as soon as possible or ASAP</td>
          <td>12am of current day through 12 am 8 days out&nbsp;</td>
        </tr>
        <tr>
          <td>now</td>
          <td>hour and minute from timestamp of the message</td>
        </tr>
        <tr>
          <td>morning</td>
          <td>8am-12pm</td>
        </tr>
        <tr>
          <td>afternoon</td>
          <td>12pm-6pm</td>
        </tr>
        <tr>
          <td>daytime</td>
          <td>8am - 6pm</td>
        </tr>
        <tr>
          <td>evening</td>
          <td>5pm-9pm</td>
        </tr>
        <tr>
          <td>early</td>
          <td>7am-10am</td>
        </tr>
        <tr>
          <td>before work</td>
          <td>7am-9am</td>
        </tr>
        <tr>
          <td>early morning</td>
          <td>7am-9am</td>
        </tr>
        <tr>
          <td>mid-morning</td>
          <td>9am-11am</td>
        </tr>
        <tr>
          <td>late morning</td>
          <td>10:30am-12pm</td>
        </tr>
        <tr>
          <td>lunch time</td>
          <td>11:30am-1pm</td>
        </tr>
        <tr>
          <td>mid-day</td>
          <td>11am-1pm</td>
        </tr>
        <tr>
          <td>early afternoon</td>
          <td>12pm-2:30pm</td>
        </tr>
        <tr>
          <td>mid-afternoon</td>
          <td>2pm-4pm</td>
        </tr>
        <tr>
          <td>late afternoon</td>
          <td>4pm-6pm</td>
        </tr>
        <tr>
          <td>later in the day</td>
          <td>3pm-11pm</td>
        </tr>
        <tr>
          <td>early evening</td>
          <td>5pm-6pm</td>
        </tr>
        <tr>
          <td>dusk</td>
          <td>6pm-8pm</td>
        </tr>
        <tr>
          <td>after work</td>
          <td>6pm-11pm</td>
        </tr>
        <tr>
          <td>before X time</td>
          <td>12am - 1 minute before X time</td>
        </tr>
        <tr>
          <td>after X time</td>
          <td>1 minute after X time - 12am (day after X)</td>
        </tr>
        <tr>
          <td>around X time</td>
          <td>1 hour before X time - 1 hour after X time</td>
        </tr>
        <tr>
          <td>before X date</td>
          <td>12am of the day message received - 12am on X date</td>
        </tr>
        <tr>
          <td>after X date</td>
          <td>12am of the day after X date - 14 days out</td>
        </tr>
        <tr>
          <td>late</td>
          <td>7pm-11pm</td>
        </tr>
        <tr>
          <td>early this week</td>
          <td>Mon &amp; Tues</td>
        </tr>
        <tr>
          <td>mid-week</td>
          <td>Wed</td>
        </tr>
        <tr>
          <td>later this week</td>
          <td>Thurs &amp; Fri</td>
        </tr>
        <tr>
          <td>next week</td>
          <td>
            Mon-Sun of next week(Sun = the following Monday set to 12:00am)
          </td>
        </tr>
        <tr>
          <td>this week</td>
          <td>
            today-Sun of this week(Sun = the following Monday set to 12:00am)
          </td>
        </tr>
        <tr>
          <td>a date without a month (e.g. &ldquo;the 6th&rdquo;)</td>
          <td>the soonest occurence of that date</td>
        </tr>
      </tbody>
    </table>
  );
};

export default TimexSchedulingChart;
