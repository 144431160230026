/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetReportsForBuildingsAndDateRangeDto = {
    buildingIds?: Array<number>;
    startTime?: string;
    endTime?: string;
    timeFrequency?: GetReportsForBuildingsAndDateRangeDto.timeFrequency;
}

export namespace GetReportsForBuildingsAndDateRangeDto {

    export enum timeFrequency {
        NONE = 'NONE',
        HOURLY = 'HOURLY',
        DAILY = 'DAILY',
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
        YEARLY = 'YEARLY',
    }


}
