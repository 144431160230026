import { RefObject, useEffect } from 'react';

export const useOnTransitionEnd = (
  ref: RefObject<HTMLElement>,
  onTransitionEnd: () => void
) => {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.addEventListener('transitionend', onTransitionEnd);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref.current?.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [onTransitionEnd, ref]);
};
