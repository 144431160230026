import { Button } from '@blueprintjs/core';

import { useScopeConfigs } from '../state/taggingApiRepository';
import TimexHelpPopover from '../timex/TimexHelpPopover';

import { tvmNamesOrder } from './ClassificationVM';

interface AddRelativeTimexButtonProps {
  onAddRelativeTimexClassification: () => void;
  className?: string;
  minimal?: boolean;
  scopeName: string;
  loading: boolean;
}

/**
 * A button to add a relative timex.
 */
export default function AddRelativeTimexButton({
  onAddRelativeTimexClassification,
  className,
  minimal,
  scopeName,
  loading,
}: AddRelativeTimexButtonProps) {
  const { data: scopeConfigs } = useScopeConfigs(scopeName);
  const { classifications } = scopeConfigs ?? {};
  const classificationNames = classifications
    ?.map((classification) => classification.name)
    .filter((name) => !tvmNamesOrder.includes(name));
  classificationNames?.unshift('Relative Timex');

  return (
    <>
      <Button
        text="Add Relative Timex"
        icon="add"
        className={className}
        minimal={minimal}
        loading={loading}
        onClick={onAddRelativeTimexClassification}
      />
      <TimexHelpPopover iconName="help" />
    </>
  );
}
