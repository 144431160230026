/** Whether or not a string is a valid CSS color. */
export function isValidColor(strColor: string): boolean {
  // Thanks, https://stackoverflow.com/q/48484767/2516450
  const s = new Option().style;
  s.color = strColor;
  return !!s.color;
}

/**
 * Throw if a color is not a valid CSS color.
 */
export const assertIsValidColor = (color: string) => {
  if (!isValidColor(color)) {
    throw new Error(`${color} is not a valid hex color`);
  }
};
