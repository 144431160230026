/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ReportSetting = {
    active?: boolean;
    id?: number;
    name?: string;
    reportNamePart1?: string;
    reportNamePart2?: string;
    startDate?: string;
    endDate?: string;
    initialReportDate?: string;
    isOrgLevel?: boolean;
    organizationIds?: Array<number>;
    buildingIds?: Array<number>;
    recipients?: Array<string>;
    sheetIds?: Array<'A1' | 'A1unsub' | 'A1ytd' | 'A2' | 'A3' | 'A3wk' | 'A3ytd' | 'A4' | 'BELL2' | 'BELL4' | 'BOZ1' | 'BOZ2' | 'S1' | 'S1shows' | 'S1unsub' | 'S1wk' | 'S2' | 'S2org' | 'S2wk' | 'S3' | 'S3org' | 'S3wk' | 'S4' | 'S4org' | 'S5' | 'S5org' | 'SR1ytd' | 'IVR1' | 'IVR2'>;
    type?: ReportSetting.type;
    sendingMedium?: ReportSetting.sendingMedium;
    htmlEmailMessage?: string;
    orgLevel?: boolean;
}

export namespace ReportSetting {

    export enum type {
        PROSPECT_REPORT = 'PROSPECT_REPORT',
        AUTOMATIC_REPORT = 'AUTOMATIC_REPORT',
        OWNER_REPORT = 'OWNER_REPORT',
        INTERNAL_REPORT = 'INTERNAL_REPORT',
    }

    export enum sendingMedium {
        SFTP = 'SFTP',
        AUTOMATED_EMAIL = 'AUTOMATED_EMAIL',
        MANUAL_EMAIL = 'MANUAL_EMAIL',
    }


}
