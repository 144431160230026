import clsx from 'clsx';
import {
  CSSProperties,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CloseIcon } from '../../components/Icons/CloseIcon';
import { useOnTransitionEnd } from '../useOnTransitionEnd';
import { VisualState } from '../VisualState';

import styles from './Toast.module.scss';
import { isBottom, ToasterPosition } from './ToasterPosition';
import { ToastType } from './ToastType';

export interface ToastProps {
  /**
   * Icon to display on the left side of the toast
   */
  icon?: ReactElement;

  /**
   * Title to display in bold text in the toast
   */
  title: string;

  /**
   * Description to display in the body of the toast
   */
  description?: ReactNode;

  /**
   * Callback invoked when the toast is dismissed,
   * either by the user or by the timeout.
   * The value of the argument indicates whether
   * the toast was closed because the timeout expired
   */
  onDismiss?: (didTimeoutExpire: boolean) => void;

  /**
   * Milliseconds to wait before automatically
   * dismissing toast. Providing a value less
   * than or equal to 0 will disable the timeout
   * (this is discouraged)
   * @default 5000
   */
  timeoutInMilliseconds?: number;

  /**
   * Whether to show a close button to manually close
   * the toast
   *
   * @default true
   */
  showCloseButton?: boolean;

  /**
   * The type of the toast which affects
   * the visual display of the toast
   *
   * @default ToastType.Default
   */
  toastType?: ToastType;

  position: ToasterPosition;

  /**
   * Whether to force the closing
   * state on the toast. Used
   * primarily for when the number
   * of toasts exceeds the maximum
   * allowed
   */
  forceClose?: boolean;

  /**
   * For use in animation. Used to determine whether
   * to change the animation class. For example,
   * if the index increases, we translate down.
   * If the index decreases, we translate up
   */
  index?: number;

  /**
   * For animation. To determine whether the toast
   * should move up to replace the position of a younger sibling
   * that transitioned out
   */
  didYoungerSiblingDismiss?: boolean;
}

export const Toast: FunctionComponent<ToastProps> = ({
  timeoutInMilliseconds = 5000,
  onDismiss,
  icon,
  title,
  description,
  showCloseButton = true,
  toastType = ToastType.Default,
  position,
  forceClose,
  index,
  didYoungerSiblingDismiss,
}) => {
  const [timeoutId, setTimeoutId] = useState<number | null>(null);
  const [visualState, setVisualState] = useState(VisualState.Opening);
  const [indexInternal, setIndexInternal] = useState(index);
  const [toastStyles, setToastStyles] = useState({} as CSSProperties);
  const toastWrapperRef = useRef<HTMLDivElement>(null);
  const clearTimeout = useCallback(() => {
    if (timeoutId === null) {
      return;
    }
    window.clearTimeout(timeoutId);
    setTimeoutId(null);
  }, [timeoutId]);

  const dismiss = useCallback(
    (didTimeoutExpire: boolean) => {
      clearTimeout();
      onDismiss?.(didTimeoutExpire);
    },
    [clearTimeout, onDismiss]
  );

  const onTransitionEnd = useCallback(() => {
    if (visualState === VisualState.Closing) {
      dismiss(true);
      return;
    }
    if (didYoungerSiblingDismiss) {
      setToastStyles({ transition: 'none' });
    }
  }, [didYoungerSiblingDismiss, dismiss, visualState]);

  useOnTransitionEnd(toastWrapperRef, onTransitionEnd);

  useEffect(() => {
    if (index === undefined || indexInternal === undefined) {
      setIndexInternal(index);
      return;
    }

    if (indexInternal < index) {
      setToastStyles({ transition: 'none' });
      setVisualState(VisualState.Opening);
      setTimeout(() => {
        setToastStyles({});
        if (visualState !== VisualState.Closing) {
          setVisualState(VisualState.Opened);
        }
      }, 10);
    }
    setIndexInternal(index);
    // We only care about when the prop here changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    if (didYoungerSiblingDismiss === false) {
      setToastStyles({});
    }
  }, [didYoungerSiblingDismiss]);

  useEffect(() => {
    if (forceClose) {
      clearTimeout();
      setVisualState(VisualState.Closing);
    }
    // We only care about the force close function here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceClose]);

  useEffect(() => {
    if (visualState === VisualState.Opening) {
      setTimeout(() => setVisualState(VisualState.Opened), 10);
    }
    // We only want to do this after the initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startTimeout = () => {
    clearTimeout();
    if (!timeoutInMilliseconds) {
      return;
    }
    const id = window.setTimeout(() => {
      setVisualState(VisualState.Closing);
    }, timeoutInMilliseconds);
    setTimeoutId(id);
  };

  useEffect(() => {
    startTimeout();
    // This is the initial timeout being set, so only do it on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      onBlur={startTimeout}
      onFocus={clearTimeout}
      onMouseEnter={clearTimeout}
      onMouseLeave={startTimeout}
      tabIndex={0}
      className={getToastClassName(
        toastType,
        visualState,
        position,
        didYoungerSiblingDismiss
      )}
      ref={toastWrapperRef}
      style={toastStyles}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.message}>
        <div className={styles.title}>{title}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      {showCloseButton && (
        <div
          aria-label="close"
          alt-text="Close"
          role="button"
          className={styles.closeIcon}
          onClick={() => {
            if (toastStyles.transition === 'none') {
              return;
            }
            setVisualState(VisualState.Closing);
          }}
        >
          <CloseIcon isInverse />
        </div>
      )}
    </div>
  );
};

const getToastClassName = (
  toastType: ToastType,
  visualState: VisualState,
  position: ToasterPosition,
  didYoungerSiblingDismiss?: boolean
) =>
  clsx(styles.toastWrapper, {
    [styles.default]: toastType === ToastType.Default,
    [styles.error]: toastType === ToastType.Error,
    [styles.success]: toastType === ToastType.Success,
    [styles.warning]: toastType === ToastType.Warning,
    [styles.opening]: visualState === VisualState.Opening,
    [styles.opened]: visualState === VisualState.Opened,
    [styles.closing]: visualState === VisualState.Closing,
    [styles.bottom]: isBottom(position),
    [styles.youngerSiblingDismiss]: didYoungerSiblingDismiss,
  });
