import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import api from '../api/axios';
import StateView from '../ConvAIComponentLibrary/StateView/StateView';
import LoadingPage from '../layouts/LoadingPage';
import NotFoundPage from '../layouts/NotFoundPage';
import StateDTO from '../state-tagger/state/StateDTO';
import { StateContextProvider } from '../StateContext/StateContext';

const fetchInputOutput = (id: string, method: string): Promise<StateDTO> => {
  return api
    .post('../../conversationApi/extra', {
      method,
      id,
    })
    .then((res) => res.data);
};

export function ModelIoPage({ type }: { type: string }) {
  const { id } = useParams<{ id: string }>();
  const method = type === 'input' ? 'GetModelIOInput' : 'GetModelIOOutput';

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<StateDTO>();

  const onApiCall = useCallback(
    async () => {
      setLoading(true);
      const result = await fetchInputOutput(id, method);
      setState(result);
      setLoading(false);
      return;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useEffect(
    () => {
      onApiCall();
    },
    // load once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  if (loading) return <LoadingPage />;
  if (!state) return <NotFoundPage />;
  return (
    <StateContextProvider initialState={state}>
      <StateView />
    </StateContextProvider>
  );
}
