import { Blockquote, Colors } from '@blueprintjs/core';
import { produce } from 'immer';
import isNil from 'lodash/isNil';
import { useMutation, useQuery } from 'react-query';
import { Spinner } from 'reactstrap';

import { useAuthUser } from '../../auth/hooks';
import Permission from '../../auth/Permission';
import ExpandableTag from '../../components/ExpandableTag';
import TimexTagCollection from '../../ConvAIComponentLibrary/Timex/TimexTagCollection';
import AddRelativeTimexButton from '../classification/AddRelativeTimexButton';
import ClassificationDTO from '../classification/ClassificationDTO';
import ClassificationSelect from '../classification/ClassificationSelect';
import ClassificationVM from '../classification/ClassificationVM';
import {
  fetchNewRelativeTimexClassifications,
  getAitFromID,
} from '../state/taggingApiRepository';
import TimexDTO from '../timex/TimexDTO';
import TimexHelpPopover from '../timex/TimexHelpPopover';
import TimexVM from '../timex/TimexVM';

import FilledStarMessage from './FilledStarMessage';
import IntentTableInputDialogButton from './IntentTableInputDialogButton';
import IntentTextInput from './IntentTextInput';
import IntentTextsInput from './IntentTextsInput';
import IntentVM from './IntentVM';

interface IntentInputProps {
  intent: IntentVM;
  eventId: string;
  className?: string;
  canEdit?: boolean;
  onChangeIntent?: (intent: IntentVM) => void;
  onRemove?: (intentIdx: number) => void;
  initialIsOpen?: boolean;
  intentIdx: number;
  scopeName?: string;
  showDisabledIcon?: boolean;
  isSpeedMode?: boolean;
}

/**
 * An expandable tag to render and optionally edit an intent.
 *
 * When it's closed, it's a small inline tag. When it's opened, it's a
 * block of content that lists text, timex, and classifications.
 */
export default function IntentInput(props: IntentInputProps) {
  const {
    intent,
    className,
    onChangeIntent,
    onRemove,
    canEdit,
    initialIsOpen = false,
    intentIdx,
    scopeName,
    showDisabledIcon,
    isSpeedMode,
  } = props;

  const handleRemoveIntent = () => {
    if (canEdit && onRemove) {
      return () => onRemove(intentIdx);
    }
    return undefined;
  };

  const handleChangeIntent = (newIntent: IntentVM) => {
    if (onChangeIntent) {
      onChangeIntent(newIntent);
    }
  };

  const [
    requestAddRelativeTimexClassifications,
    { isLoading: isAddingRelativeTimex },
  ] = useMutation(fetchNewRelativeTimexClassifications, {
    onSuccess: (newClassifications: ClassificationDTO[]) => {
      const timexVM = ClassificationVM.fromDTOs(newClassifications);
      onChangeIntent?.(
        produce(intent, (draft) => {
          draft.classifications = (draft.classifications ?? []).concat(timexVM);
        })
      );
    },
  });

  const onHandleTimexUpdate = (timex: TimexVM) => {
    onChangeIntent &&
      onChangeIntent(
        produce(intent, (draft) => {
          draft.timex = timex;
        })
      );
  };

  const timex = intent.timex;

  const isAitCreatedIntent = intent?.intent_creator?.includes('AIT_');
  const { data: ait, isLoading } = useQuery(
    intent.intent_creator,
    (aitName: string) => getAitFromID(aitName.replace('AIT_', '')),
    {
      enabled: isAitCreatedIntent,
    }
  );

  const user = useAuthUser();
  const shouldShowAitName = user.hasPermission(
    Permission.READ_AIT_INTENT_CREATOR
  );

  return (
    <ExpandableTag
      title={<IntentTitle intentName={intent.name} />}
      subtitle={
        isAitCreatedIntent && isLoading ? (
          <Spinner />
        ) : ait ? (
          shouldShowAitName ? (
            `Intent created by: ${ait.name ?? intent.intent_creator}`
          ) : (
            'Anonymous'
          )
        ) : (
          `Intent created by: ${intent.intent_creator}`
        )
      }
      className={className}
      initialIsOpen={initialIsOpen}
      disabled={false}
      onRemove={handleRemoveIntent()}
      rightIcon={showDisabledIcon && !canEdit ? 'disable' : undefined}
    >
      <p>{intent.description}</p>
      <Blockquote>
        <FilledStarMessage intent={intent} />
      </Blockquote>
      <div className="d-flex flex-wrap align-items-center">
        {!isNil(intent.text) && (
          <IntentTextInput
            className="m-1"
            text={intent.text}
            disabled={!canEdit}
            onChangeText={(text) => {
              onChangeIntent &&
                onChangeIntent(
                  produce(intent, (draft) => {
                    draft.text = text;
                  })
                );
            }}
          />
        )}
        {intent.texts && intent.script_str && (
          <IntentTextsInput
            className="m-1"
            scriptStr={intent.script_str}
            // texts={intent.texts}
            texts={intent.texts.map((text) => (text === null ? '' : text))}
            // This ^^^^ is a temp fix and should be removed
            disabled={!canEdit}
            onChangeTexts={(texts: string[]) => {
              onChangeIntent &&
                onChangeIntent(
                  produce(intent, (draft) => {
                    draft.texts = texts;
                  })
                );
            }}
          />
        )}
        {intent.tables &&
          intent.tables.map((table, idx) => (
            <IntentTableInputDialogButton
              key={idx}
              tableIdx={idx}
              initialData={table}
              submitOnChange
              onSubmit={(data) =>
                onChangeIntent &&
                onChangeIntent(
                  produce(intent, (draft) => {
                    if (draft.tables) {
                      draft.tables[idx] = data;
                    }
                  })
                )
              }
            />
          ))}

        {timex && (
          <>
            <TimexHelpPopover iconName="time" />
            <TimexTagCollection
              timex={TimexVM.toDTO(timex)}
              tagClassName="m-1"
              readOnly={!canEdit}
              onUpdateTimex={(newTimex) =>
                onHandleTimexUpdate(TimexVM.fromDTO(newTimex as TimexDTO))
              }
              onAddClassifications={(newClassifications) => {
                handleChangeIntent(
                  produce(intent, (draft) => {
                    const newClassificationsVM = ClassificationVM.fromDTOs(
                      newClassifications as ClassificationDTO[]
                    );
                    draft.classifications?.push(...newClassificationsVM);
                  })
                );
              }}
            />
          </>
        )}
        {intent.classifications?.map((classification, i) => (
          <ClassificationSelect
            key={`${classification.name}-${i}`}
            className="m-1"
            disabled={!canEdit || classification.name === intent.name}
            classification={classification}
            onChangeClassification={(newClassification) => {
              let selected = newClassification.selected;
              if (
                (selected ?? []).filter((label) => !label.startsWith('nan'))
                  .length > 0
              ) {
                selected = selected?.filter(
                  (label) => !label.startsWith('nan')
                );
              }
              const classificationVM = intent.classifications?.[i];
              if (classificationVM) {
                const updatedClassification = {
                  ...ClassificationVM.toDTOs([classificationVM])[0],
                  selected,
                };
                const updatedClassificationVM = ClassificationVM.fromDTO(
                  updatedClassification
                );
                const updatedClassificationVMs =
                  intent.classifications?.slice();
                if (updatedClassificationVMs) {
                  updatedClassificationVMs[i] = updatedClassificationVM;
                  onChangeIntent &&
                    onChangeIntent(
                      produce(intent, (draft) => {
                        draft.classifications = updatedClassificationVMs;
                      })
                    );
                }
              }
            }}
            onChangeTimexClassification={(newClassification) => {
              onChangeIntent &&
                onChangeIntent(
                  produce(intent, (draft) => {
                    if (draft.classifications) {
                      draft.classifications[i] = newClassification;
                    }
                  })
                );
            }}
            onRemove={() => {
              handleChangeIntent(
                produce(intent, (draft) => {
                  if (draft.classifications) {
                    draft.classifications.splice(i, 1);
                  }
                })
              );
            }}
            isSpeedMode={isSpeedMode}
          />
        ))}
        {canEdit &&
          intent.classifications &&
          scopeName &&
          intent.timex &&
          !isSpeedMode && (
            <AddRelativeTimexButton
              scopeName={scopeName}
              className="m-1"
              loading={isAddingRelativeTimex}
              onAddRelativeTimexClassification={
                requestAddRelativeTimexClassifications
              }
            />
          )}
      </div>
    </ExpandableTag>
  );
}

const IntentTitle = ({ intentName }: { intentName: string }) => {
  const getColor = () => {
    if (intentName.startsWith('request')) {
      return Colors.CERULEAN3;
    }
    if (intentName.startsWith('statement')) {
      return Colors.SEPIA3;
    }
    return '';
  };

  return <div style={{ color: getColor() }}>{intentName}</div>;
};
