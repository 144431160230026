/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EliseMessageIntent = {
    id?: number;
    clientEmail?: string;
    leadEmail?: string;
    leadId?: number;
    hotMailId?: number;
    intentNameClassifiedAuto?: string;
    intentValue?: string;
    intentClassifer?: string;
    type?: number;
    verifiedHuman?: number;
    intentNameClassifiedManual?: string;
    intentNameSubintentClassifiedManual?: string;
    responseRequiringApprovalId?: number;
    pendingApproval?: number;
    strippedText?: string;
    isDeleted?: number;
    hotMailGmailMessageId?: string;
    processed?: number;
    getzIndex?: number;
    descriptiveNounForUnknownIntent?: string;
    aiTrainerInstructions?: string;
    messageType?: EliseMessageIntent.messageType;
    tag?: string;
}

export namespace EliseMessageIntent {

    export enum messageType {
        EMAIL_INTENT = 'EMAIL_INTENT',
        TEXT_INTENT = 'TEXT_INTENT',
    }


}
