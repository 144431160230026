/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BuildingTourTypePreferences = {
    id?: number;
    buildingId?: number;
    escortedToursAllowed?: boolean;
    escortedToursTypeOffered?: BuildingTourTypePreferences.escortedToursTypeOffered;
    escortedTourClientName?: string;
    escortedToursLink?: string;
    mediaToursAllowed?: boolean;
    mediaToursTypeOffered?: BuildingTourTypePreferences.mediaToursTypeOffered;
    mediaTourClientName?: string;
    mediaToursLink?: string;
    liveVirtualToursAllowed?: boolean;
    liveVirtualToursTypeOffered?: BuildingTourTypePreferences.liveVirtualToursTypeOffered;
    liveVirtualTourClientName?: string;
    liveVirtualToursLink?: string;
    selfGuidedToursAllowed?: boolean;
    selfGuidedToursTypeOffered?: BuildingTourTypePreferences.selfGuidedToursTypeOffered;
    selfGuidedTourClientName?: string;
    selfGuidedToursLink?: string;
    selfGuidedTourSmartLockType?: BuildingTourTypePreferences.selfGuidedTourSmartLockType;
}

export namespace BuildingTourTypePreferences {

    export enum escortedToursTypeOffered {
        LINK = 'LINK',
        CLIENT_SCHEDULED = 'CLIENT_SCHEDULED',
        SCHEDULED_BY_ME_MANAGED_BY_ME = 'SCHEDULED_BY_ME_MANAGED_BY_ME',
        SCHEDULED_BY_ME_MANAGED_BY_CLIENT = 'SCHEDULED_BY_ME_MANAGED_BY_CLIENT',
        SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE = 'SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE',
    }

    export enum mediaToursTypeOffered {
        LINK = 'LINK',
        CLIENT_SCHEDULED = 'CLIENT_SCHEDULED',
        SCHEDULED_BY_ME_MANAGED_BY_ME = 'SCHEDULED_BY_ME_MANAGED_BY_ME',
        SCHEDULED_BY_ME_MANAGED_BY_CLIENT = 'SCHEDULED_BY_ME_MANAGED_BY_CLIENT',
        SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE = 'SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE',
    }

    export enum liveVirtualToursTypeOffered {
        LINK = 'LINK',
        CLIENT_SCHEDULED = 'CLIENT_SCHEDULED',
        SCHEDULED_BY_ME_MANAGED_BY_ME = 'SCHEDULED_BY_ME_MANAGED_BY_ME',
        SCHEDULED_BY_ME_MANAGED_BY_CLIENT = 'SCHEDULED_BY_ME_MANAGED_BY_CLIENT',
        SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE = 'SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE',
    }

    export enum selfGuidedToursTypeOffered {
        LINK = 'LINK',
        CLIENT_SCHEDULED = 'CLIENT_SCHEDULED',
        SCHEDULED_BY_ME_MANAGED_BY_ME = 'SCHEDULED_BY_ME_MANAGED_BY_ME',
        SCHEDULED_BY_ME_MANAGED_BY_CLIENT = 'SCHEDULED_BY_ME_MANAGED_BY_CLIENT',
        SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE = 'SCHEDULED_BY_ME_MANAGED_BY_CONCIERGE',
    }

    export enum selfGuidedTourSmartLockType {
        UNKNOWN = 'UNKNOWN',
        CONCIERGE = 'CONCIERGE',
        IGLOOHOME = 'IGLOOHOME',
        AUGUST_SMARTLOCK_3 = 'AUGUST_SMARTLOCK_3',
        CONCIERGE_WITH_ID_VERIFICATION = 'CONCIERGE_WITH_ID_VERIFICATION',
    }


}
