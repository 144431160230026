import { Dictionary } from '../../Dictionary';
import ClassificationDTO from '../classification/ClassificationDTO';
import { IntentTableDTO } from '../intent/IntentTableInput';
import TimexVM from '../timex/TimexVM';

import { PartyPropertyDTO } from './PartyPropertyDTO';

interface PartyPropertyVM {
  classifications?: ClassificationDTO[];
  description?: string;
  metadata: Dictionary<string>;
  name: string;
  type: 'Property';
  text?: string | null;
  texts?: string[] | null;
  timex?: TimexVM | null;
  property_creator?: string | null;
  tables?: null | IntentTableDTO[];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const PartyPropertyVM = {
  fromDTO(dto: PartyPropertyDTO): PartyPropertyVM {
    return {
      ...dto,
      property_creator: dto.property_creator,
      timex: dto.timex ? TimexVM.fromDTO(dto.timex) : null,
    };
  },

  toDTO(dto: PartyPropertyVM): PartyPropertyDTO {
    return {
      ...dto,
      property_creator: dto.property_creator,
      timex: dto.timex ? TimexVM.toDTO(dto.timex) : null,
    };
  },
};

export default PartyPropertyVM;
