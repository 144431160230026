/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TourAvailabilityResponse } from '../models/TourAvailabilityResponse';
import { request as __request } from '../core/request';

export class BuildingSchedulingControllerService {

    /**
     * @param buildingId
     * @param startTime
     * @param endTime
     * @returns TourAvailabilityResponse OK
     * @throws ApiError
     */
    public static async getTourAvailability(
        buildingId: number,
        startTime: string,
        endTime: string,
    ): Promise<TourAvailabilityResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/pub/v1/buildings/${buildingId}/tour/availabilities`,
            query: {
                'startTime': startTime,
                'endTime': endTime,
            },
        });
        return result.body;
    }

}