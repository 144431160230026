import { Button, Colors, H4, HTMLTable } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import { useClipboard } from '@mantine/hooks';
import clsx from 'clsx';
import noop from 'lodash/noop';
import { Filters, TableInstance } from 'react-table';

import { SortDown, SortDownAlt } from '../Icons';

import PaginationControls from './PaginationControls';
import styles from './TableUI.module.css';

interface TableUIProps<D extends object> {
  instance: TableInstance<D>;
  title?: string;
  copyCellOnClick?: boolean;
  persistFilters?: (filters: Filters<D>) => void;
}

/**
 * A component to render the UI for a table instance returned by the
 * react-table `useTable()` hook.
 *
 * For a more high-level API that includes pagination and
 * sorting by default, use <Table /> instead of <TableUI />.
 */
export default function Table<D extends object>({
  instance,
  title,
  copyCellOnClick = false,
}: TableUIProps<D>) {
  const {
    getTableProps,
    getTableBodyProps,
    page,
    prepareRow,
    headerGroups,
    setAllFilters,
  } = instance;
  const clipboard = useClipboard();

  return (
    <>
      <div className={styles.titleAndActions}>
        <H4>{title}</H4>
        <Button
          className={styles.clearFitlersButton}
          text="Clear Filters"
          onClick={() => setAllFilters([])}
        />
      </div>
      <div className={styles.scrollWrapper}>
        <HTMLTable className={styles.expandLast} {...getTableProps()}>
          <thead>
            {/* eslint-disable react/jsx-key */}
            {/* because react-table adds keys */}
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    {column.canSort &&
                    column.isSorted &&
                    column.isSortedDesc ? (
                      <SortDown
                        title="Toggle SortBy"
                        className="ml-2"
                        color={Colors.BLACK}
                        {...column.getSortByToggleProps()}
                      />
                    ) : column.canSort && column.isSorted ? (
                      <SortDownAlt
                        title="Toggle SortBy"
                        className="ml-2"
                        color={Colors.BLACK}
                        {...column.getSortByToggleProps()}
                      />
                    ) : column.canSort ? (
                      <SortDown
                        title="Toggle SortBy"
                        className="ml-2"
                        color={Colors.GRAY5}
                        {...column.getSortByToggleProps()}
                      />
                    ) : null}
                    {column.canFilter ? column.render('Filter') : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={clsx({
                          [styles.copieableTableCell]: copyCellOnClick,
                        })}
                        onClick={() =>
                          copyCellOnClick ? clipboard.copy(cell.value) : noop
                        }
                        {...cell.getCellProps()}
                      >
                        <Tooltip2
                          content="Click to Copy"
                          disabled={!copyCellOnClick}
                        >
                          {cell.render('Cell')}
                        </Tooltip2>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </HTMLTable>
      </div>
      {page ? (
        <PaginationControls className="mt-2" instance={instance} />
      ) : null}
    </>
  );
}
