import {
  Button,
  Dialog,
  FormGroup,
  InputGroup,
  TagInput,
} from '@blueprintjs/core';
import { produce } from 'immer';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import { FunctionComponent, useState } from 'react';

import { useAuthUser } from '../../auth/hooks';
import AttributeClassificationDTO from '../../ConvAIComponentLibrary/AttributeClassification/AttributeClassificationDTO';
import TimexDTO from '../../ConvAIComponentLibrary/Timex/TimexDTO';
import TimexTagCollection from '../../ConvAIComponentLibrary/Timex/TimexTagCollection';
import ClassificationDTO from '../classification/ClassificationDTO';
import ClassificationSelect from '../classification/ClassificationSelect';
import ClassificationVM from '../classification/ClassificationVM';
import IntentTableInput from '../intent/IntentTableInput';
import IntentTableInputDialogButton from '../intent/IntentTableInputDialogButton';
import { default as OldTimexDTO } from '../timex/TimexDTO';
import TimexVM from '../timex/TimexVM';

import { getPropertyCreatorName } from './getPropertyCreatorName';
import PartyPropertyVM from './PartyPropertyVM';

export const PartyPropertyDisplay: FunctionComponent<{
  partyProperty: PartyPropertyVM;
  isReadOnly: boolean;
  // classificationOptions: ClassificationDTO[];
  onRemoveProperty: () => void;
  onChangeProperty: (partyProperty: PartyPropertyVM) => void;
}> = ({
  partyProperty,
  isReadOnly,
  // classificationOptions,
  onRemoveProperty,
  onChangeProperty,
}) => {
  const user = useAuthUser();

  // const onChangeTimex = (newTimex: TimexVM) => {
  //   const updatedPartyProperty = produce(partyProperty, (draft) => {
  //     draft.timex = newTimex;
  //     draft.property_creator = getPropertyCreatorName(user.id);
  //   });
  //   onChangeProperty(updatedPartyProperty);
  // };

  const handleUpdateTimex = (newTimex: TimexDTO) => {
    onChangeProperty(
      produce(partyProperty, (draft) => {
        draft.timex = TimexVM.fromDTO(newTimex as OldTimexDTO);
        draft.property_creator = getPropertyCreatorName(user.id);
        // TODO: Is this necessary? ^^^^
      })
    );
  };

  const handleAddClassifications = (
    newClassifications: AttributeClassificationDTO[]
  ) => {
    onChangeProperty(
      produce(partyProperty, (draft) => {
        draft.classifications?.push(
          ...(newClassifications as ClassificationDTO[])
        );
      })
    );
  };

  const { text, texts, name, timex, classifications, description, tables } =
    partyProperty;

  const [viewingTableIdx, setViewingTableIdx] = useState<number>();
  return (
    <div className="mt-2">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <p className="mb-0">{name}</p>
        {!isReadOnly && (
          <Button icon="cross" onClick={onRemoveProperty} minimal />
        )}
      </div>
      {!isNil(text) && (
        <FormGroup>
          <InputGroup
            readOnly={isReadOnly}
            value={text}
            onChange={(e) => {
              const updatedPartyProperty = produce(partyProperty, (draft) => {
                draft.text = e.target.value;
                draft.property_creator = getPropertyCreatorName(user.id);
              });
              onChangeProperty(updatedPartyProperty);
            }}
          />
        </FormGroup>
      )}
      {texts && (
        <FormGroup>
          <TagInput
            onAdd={(added) => {
              const updatedPartyProperty = produce(partyProperty, (draft) => {
                draft.texts?.push(...added);
                draft.property_creator = getPropertyCreatorName(user.id);
              });
              onChangeProperty(updatedPartyProperty);
            }}
            onRemove={(_, idx) => {
              const updatedPartyProperty = produce(partyProperty, (draft) => {
                draft.texts?.splice(idx, 1);
                draft.property_creator = getPropertyCreatorName(user.id);
              });
              onChangeProperty(updatedPartyProperty);
            }}
            placeholder="Texts"
            rightElement={
              <Button
                disabled={texts?.length === 0}
                icon={'cross'}
                minimal={true}
                onClick={() => {
                  const updatedPartyProperty = produce(
                    partyProperty,
                    (draft) => {
                      draft.texts = [];
                      draft.property_creator = getPropertyCreatorName(user.id);
                    }
                  );
                  onChangeProperty(updatedPartyProperty);
                }}
              />
            }
            values={texts}
          />
        </FormGroup>
      )}
      {tables && (
        <>
          {tables?.map((table, idx) => (
            <IntentTableInputDialogButton
              key={idx}
              tableIdx={idx}
              initialData={table}
              submitOnChange
              onSubmit={(data) => {
                const updatedPartyProperty = produce(partyProperty, (draft) => {
                  if (draft.tables) {
                    draft.tables[idx] = data;
                  }
                });
                onChangeProperty(updatedPartyProperty);
              }}
            />
          ))}
          {isNumber(viewingTableIdx) && (
            <Dialog
              title={`{tables:${viewingTableIdx}}`}
              isOpen={isNumber(viewingTableIdx)}
              onClose={() => setViewingTableIdx(undefined)}
              style={{ height: '90vh', width: '90vw' }}
            >
              <IntentTableInput
                key={viewingTableIdx}
                initialData={tables[viewingTableIdx]}
                submitOnChange
                onSubmit={(data) => {
                  const updatedPartyProperty = produce(
                    partyProperty,
                    (draft) => {
                      if (draft.tables) {
                        draft.tables[viewingTableIdx] = data;
                      }
                    }
                  );
                  onChangeProperty(updatedPartyProperty);
                }}
              />
            </Dialog>
          )}
        </>
      )}
      {timex && (
        <TimexTagCollection
          timex={TimexVM.toDTO(timex)}
          readOnly={isReadOnly}
          onUpdateTimex={handleUpdateTimex}
          onAddClassifications={handleAddClassifications}
        />
      )}
      <p>{description}</p>
      {
        <div>
          {classifications?.map((c, i) => (
            <ClassificationSelect
              key={`${c.name}-${i}`}
              className="m-1"
              disabled={isReadOnly || c.name === name}
              classification={c}
              onChangeClassification={(newClassification) => {
                const updatedClassification = produce(
                  newClassification,
                  (draft) => {
                    if (
                      (draft.selected ?? []).filter(
                        (label) => !label.startsWith('nan')
                      ).length > 0
                    ) {
                      draft.selected = (draft.selected ?? []).filter(
                        (label) => !label.startsWith('nan')
                      );
                    }
                    return draft;
                  }
                );
                const updatedPartyProperty = produce(partyProperty, (draft) => {
                  if (draft.classifications) {
                    draft.classifications[i] = ClassificationVM.toDTOs([
                      updatedClassification,
                    ])[0];
                    draft.property_creator = getPropertyCreatorName(user.id);
                  }
                });

                onChangeProperty(updatedPartyProperty);
              }}
              onRemove={() => {
                const updatedPartyProperty = produce(partyProperty, (draft) => {
                  draft.classifications = draft.classifications?.filter(
                    (_, j) => i !== j
                  );
                  draft.property_creator = getPropertyCreatorName(user.id);
                });

                onChangeProperty(updatedPartyProperty);
              }}
            />
          ))}
          {/* <AddClassificationButton
            classifications={classificationOptions}
            onAddClassification={(newClassification) => {
              const updatedPartyProperty = produce(partyProperty, (draft) => {
                draft.classifications.push(
                  ClassificationVM.toDTOs([newClassification])[0]
                );
                draft.property_creator = getPropertyCreatorName(user.id);
              });

              onChangeProperty(updatedPartyProperty);
            }}
            minimal={true}
          /> */}
        </div>
      }
    </div>
  );
};
