/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SettingVirtualTourType = {
    value?: SettingVirtualTourType.value;
    enabled?: boolean;
    locked?: boolean;
}

export namespace SettingVirtualTourType {

    export enum value {
        NOT_ENABLED = 'NOT_ENABLED',
        SELF_MANAGED = 'SELF_MANAGED',
        WHEREBY = 'WHEREBY',
    }


}
