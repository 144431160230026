import { Button, Switch } from '@blueprintjs/core';
import { DateRangeInput } from '@blueprintjs/datetime';
import { Popover2 } from '@blueprintjs/popover2';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { FilterProps } from 'react-table';

import { Funnel, FunnelFill } from '../Icons';

/**
 * This filter component is built to be used with the inDateRange filter type
 * found in ./filterTypes.ts
 */

function DateRangeFilter<T extends object>({
  column: { filterValue = { hasDateValue: false }, setFilter },
}: FilterProps<T>) {
  const FunnelIcon =
    (filterValue.startDate && filterValue.endDate) || filterValue.hasDateValue
      ? FunnelFill
      : Funnel;

  return (
    <Popover2
      onClosing={() => {
        if (!(filterValue.startDate && filterValue.endDate)) {
          setFilter(() => ({ hasDateValue: filterValue.hasDateValue }));
        }
      }}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
          }}
        >
          <Button
            text="Clear"
            onClick={() => setFilter({ hasDateValue: false })}
            style={{ marginBottom: '1rem' }}
          />
          <Switch
            checked={filterValue.hasDateValue || false}
            label="Has Date"
            onChange={() =>
              setFilter(() => ({
                hasDateValue: !filterValue.hasDateValue,
              }))
            }
          />
          <DateRangeInput
            disabled={filterValue.hasDateValue}
            value={[filterValue.startDate, filterValue.endDate]}
            onChange={([startDate, endDate]) => {
              setFilter(() => ({
                ...filterValue,
                startDate,
                endDate,
              }));
            }}
            highlightCurrentDay
            parseDate={(dateString) =>
              parse(dateString, 'MM/dd/yyyy', new Date())
            }
            formatDate={(date) => format(date, 'MM/dd/yyyy')}
          />
        </div>
      }
    >
      <FunnelIcon title="Filter" className="ml-2" />
    </Popover2>
  );
}

export default DateRangeFilter;
