/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Script = {
    id?: number;
    organizationId?: number;
    media?: Script.media;
    type?: Script.type;
    category?: string;
    subIntent?: string;
    text?: string;
    handoffIntentState?: Script.handoffIntentState;
    communityId?: number;
}

export namespace Script {

    export enum media {
        BOTH = 'BOTH',
        EMAIL = 'EMAIL',
        SMS = 'SMS',
    }

    export enum type {
        ALL = 'ALL',
        TEMPLATE = 'TEMPLATE',
        TEMPLATE_ITEM = 'TEMPLATE_ITEM',
    }

    export enum handoffIntentState {
        NONE = 'NONE',
        YELLOW_STATUS = 'YELLOW_STATUS',
        RED_ALERT_SEND = 'RED_ALERT_SEND',
        RED_ALERT_DO_NOT_SEND = 'RED_ALERT_DO_NOT_SEND',
    }


}
