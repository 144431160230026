import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { FilterProps } from 'react-table';

import { Funnel, FunnelFill } from '../Icons';

/**
 * This filter component is built to be used with the beween filter type
 * that comes with react-table
 */

function NumericRangeFilter<T extends object>({
  column: { filterValue = [], setFilter, id },
}: FilterProps<T>) {
  const FunnelIcon =
    (filterValue[0] || filterValue[0] === 0) &&
    (filterValue[1] || filterValue[1] === 0)
      ? FunnelFill
      : Funnel;

  return (
    <Popover2
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
          }}
        >
          <Button
            text="Clear"
            onClick={() => setFilter([])}
            style={{ marginBottom: '1rem' }}
          />
          <input
            value={filterValue[0] || ''}
            type="number"
            onChange={(e) => {
              const val = e.target.value;
              setFilter((old = []) => [
                val ? parseInt(val, 10) : undefined,
                old[1],
              ]);
            }}
            placeholder="Min"
          />
          to
          <input
            value={filterValue[1] || ''}
            type="number"
            onChange={(e) => {
              const val = e.target.value;
              setFilter((old = []) => [
                old[0],
                val ? parseInt(val, 10) : undefined,
              ]);
            }}
            placeholder="Max"
          />
        </div>
      }
    >
      <FunnelIcon title="Filter" className="ml-2" />
    </Popover2>
  );
}

export default NumericRangeFilter;
