/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RecurringEventDto = {
    id?: number;
    calendarId?: number;
    startTime?: string;
    endTime?: string;
    daysOfWeek?: Array<number>;
    daysOfMonth?: Array<number>;
    startOfRecurrence?: string;
    endOfRecurrence?: string;
    frequency?: RecurringEventDto.frequency;
    frequencyMultiplier?: number;
    timeCreated?: string;
    timeMostRecentlyUpdated?: string;
    lastEventGeneratedTimestamp?: string;
    createdBy?: string;
    summary?: string;
    deleted?: boolean;
}

export namespace RecurringEventDto {

    export enum frequency {
        WEEKLY = 'WEEKLY',
        MONTHLY = 'MONTHLY',
    }


}
