import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { FC } from 'react';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { getTokenOrNull } from '../auth/auth0';

import AuthProvider from './AuthProvider';

const httpLink = createHttpLink({ uri: '/graphql' });

const authLink = setContext(async (_, { headers }) => {
  const token = await getTokenOrNull();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  defaultOptions: {
    query: {
      pollInterval: 30 * 1000,
    },
  },
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const rqConfig: ReactQueryConfig = {
  queries: {
    retry: (failureCount, error) => {
      // If the error is an instance of Response, it means the
      // network is fine, but the server returned the error, so
      // retrying won't help.
      if (error instanceof Response) return false;

      // Otherwise, retry 3 times.
      return failureCount < 3;
    },
  },
};

const AppContexts: FC = ({ children }) => (
  <BrowserRouter>
    <ReactQueryConfigProvider config={rqConfig}>
      <AuthProvider>
        <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
      </AuthProvider>
    </ReactQueryConfigProvider>
  </BrowserRouter>
);

export default AppContexts;
