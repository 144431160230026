/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateScriptRequest = {
    organizationId?: number;
    media: UpdateScriptRequest.media;
    category: string;
    subIntent?: string;
    text: string;
    handoffIntentState?: UpdateScriptRequest.handoffIntentState;
    communityId?: number;
}

export namespace UpdateScriptRequest {

    export enum media {
        BOTH = 'BOTH',
        EMAIL = 'EMAIL',
        SMS = 'SMS',
    }

    export enum handoffIntentState {
        NONE = 'NONE',
        YELLOW_STATUS = 'YELLOW_STATUS',
        RED_ALERT_SEND = 'RED_ALERT_SEND',
        RED_ALERT_DO_NOT_SEND = 'RED_ALERT_DO_NOT_SEND',
    }


}
