import flatten from 'lodash/flatten';
import zip from 'lodash/zip';
import { ReactNodeArray } from 'react';

import TimexText from '../timex/TimexText';

import IntentVM from './IntentVM';

interface FilledStarMessageProps {
  intent: IntentVM;
  className?: string;
}

const placeholderRegex = /\{.*?\}/g;

/**
 * Inline, formatted text for an intent's star message.
 *
 * Parses intent.script_str for placeholders and fills them in from its
 * values. For example, replaces "{text}" with the value of
 * intent.text, likewise for "{timex}" but with a few more rules.
 */
export default function FilledStarMessage({
  intent,
  className,
}: FilledStarMessageProps) {
  const template = intent.script_str;
  const literals = template?.split(placeholderRegex);

  const placeholders = Array.from(
    (template ?? '').matchAll(placeholderRegex),
    (m) => m[0].replace('{', '').replace('}', '')
  );

  const classifications = intent.classifications?.flatMap((c) =>
    c.type === 'TimexClassification' ? Object.values(c) : [c]
  );

  const resolvedTexts: ReactNodeArray = placeholders.map((token, i) => {
    if (token === 'text' && intent.text) {
      return <strong key={token + i}>{intent.text}</strong>;
    }
    if (token === 'time' && intent.timex?.items.length) {
      return (
        <strong key={token + i}>
          <TimexText timex={intent.timex} />
        </strong>
      );
    }
    if (token === 'texts' && intent.texts && intent.texts.length > 0) {
      return <strong key={token + i}>{intent.texts.join(', ')}</strong>;
    }
    if (token.startsWith('texts') && intent.texts) {
      const textsIndex = parseInt(token.split(':')[1]);
      if (intent.texts[textsIndex]) {
        return <strong key={token + i}>{intent.texts[textsIndex]}</strong>;
      }
    }

    const classificationName = token.split(':')[1];
    if (classificationName) {
      const classification = classifications?.find(
        (c) => c && c.name === classificationName
      );
      if (classification) {
        const text = classification.selected.join(', ');
        if (text) return <strong key={token + i}>{text}</strong>;
      }
    }
    return (
      <code key={token + i} style={{ color: 'rgb(168, 42, 42)' }}>
        {'{' + token + '}'}
      </code>
    );
  });
  return (
    <span className={className}>{flatten(zip(literals, resolvedTexts))}</span>
  );
}
