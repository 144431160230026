/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Attachment } from './Attachment';

export type ResponseAtomicTemplate = {
    id?: number;
    scriptType?: number;
    organizationId?: number;
    category?: string;
    subIntent?: string;
    categoryReadableName?: string;
    responseText?: string;
    entity?: string;
    parent?: number;
    children?: Array<ResponseAtomicTemplate>;
    attachments?: Array<Attachment>;
    aiTrainerInstructions?: string;
    handoffIntentState?: ResponseAtomicTemplate.handoffIntentState;
    superCategory?: string;
    attachmentsFromDatabase?: string;
}

export namespace ResponseAtomicTemplate {

    export enum handoffIntentState {
        NONE = 'NONE',
        YELLOW_STATUS = 'YELLOW_STATUS',
        RED_ALERT_SEND = 'RED_ALERT_SEND',
        RED_ALERT_DO_NOT_SEND = 'RED_ALERT_DO_NOT_SEND',
    }


}
