import { Card, Elevation, Tag } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { Brainfreeze } from '../../state-viewer/brainfreezeTypes';
import { ModelIO } from '../../state-viewer/TimetravelPage';

import { DebuggingItem } from './components/EventViewContainer';

interface DebuggingInfoCardProps {
  debuggingItems: DebuggingItem[];
  timezone: string;
  stateId: string;
}

const isBrainfreeze = (item: DebuggingItem): item is Brainfreeze =>
  'thought' in item;

const DebuggingInfoCard = ({
  debuggingItems,
  timezone,
  stateId,
}: DebuggingInfoCardProps) => {
  return (
    <Card className="mb-2" elevation={Elevation.TWO}>
      {debuggingItems.map((item) =>
        isBrainfreeze(item) ? (
          <BrainfreezeTag
            brainfreeze={item}
            key={item.id}
            timezone={timezone}
          />
        ) : (
          <TimetravelTag
            modelIo={item}
            key={item.id}
            timezone={timezone}
            stateId={stateId}
          />
        )
      )}
    </Card>
  );
};

const BrainfreezeTag = ({
  brainfreeze,
  timezone,
}: {
  brainfreeze: Brainfreeze;
  timezone: string;
}) => {
  return (
    <Tooltip2
      content={format(
        utcToZonedTime(new Date(brainfreeze.thought.time), timezone),
        'EEEE LLLL d, y - h:m:s a'
      )}
    >
      <Link
        to={`/state/${brainfreeze.state_id}/brainfreeze/${brainfreeze.id}`}
        target="_blank"
      >
        <Tag
          className="mb-2 mr-2"
          large
          minimal
          rightIcon="predictive-analysis"
        >
          {brainfreeze.component_name ?? brainfreeze.id}
          {brainfreeze.component_id ? ` - ${brainfreeze.component_id}` : null}
        </Tag>
      </Link>
    </Tooltip2>
  );
};

const TimetravelTag = ({
  modelIo,
  timezone,
  stateId,
}: {
  modelIo: ModelIO;
  timezone: string;
  stateId: string;
}) => {
  return (
    <Tooltip2
      content={format(
        utcToZonedTime(new Date(modelIo.output_time), timezone),
        'EEEE LLLL d, y - h:m:s a'
      )}
    >
      <Link to={`/state/${stateId}/timetravel/output`} target="_blank">
        <Tag className="mb-2 mr-2" large minimal rightIcon="time">
          {modelIo.model_name ?? modelIo.id}
        </Tag>
      </Link>
    </Tooltip2>
  );
};
export default DebuggingInfoCard;
