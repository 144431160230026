import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { isElementOfType } from '../isElementOfType';

import { Tab, TabProps } from './Tab';
import styles from './Tabs.module.scss';
import {
  generateTabComponentId,
  generateTabTitleId,
  TabTitle,
} from './TabTitle';

type TabElement = React.ReactElement<TabProps & { children: React.ReactNode }>;

const TAB_SELECTOR = 'tab-title';

interface TabsProps {
  /**
   * ID of the default tab to open with
   */
  defaultSelectedTabId?: string | number;
  /**
   * ID to be used for accessibility purposes
   */
  id: string | number;
  /**
   * The ID of the selected tab. If provided,
   * the component will enter controlled mode
   */
  selectedTabId?: string | number;
  /**
   * Class name to apply custom content
   */
  className?: string;

  /**
   * Call back that passes the new tab and previous tab
   * to the parent for controlled usage
   */
  onChange?(
    newTabId: string | number,
    prevTabId: string | number | undefined,
    event: React.MouseEvent<HTMLElement>
  ): void;

  /**
   * The content to be rendered by the tab.
   * Only children that are <Tab />s will be
   * rendered
   * @example
   * <Tabs id="eventDetails">
   * <Tab
   *     id="info"
   *     title="Info"
   *     component={
   *       <MyComponent />
   *     }
   *   />
   *   <Tab id="preferences" title="Preferences" component={<MyOtherComponent />} />
   * </Tabs>
   */
  children?: React.ReactNode;

  /**
   * Whether the header of the tabs should stick
   * to their position on the page while scrolling
   */
  isSticky?: boolean;
}

export const Tabs: FunctionComponent<TabsProps> = (props) => {
  const [selectedTabId, setSelectedTabId] = useState(
    getInitialSelectedTabId(props)
  );
  const tabListRef = useRef<HTMLDivElement | null>(null);
  const [indicatorWrapperStyles, setIndicatorWrapperStyles] = useState({});
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    moveSelectionIndicator(animate);
    setAnimate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    moveSelectionIndicator(animate);
    setAnimate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  useEffect(() => {
    if (props.selectedTabId) {
      setSelectedTabId(props.selectedTabId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedTabId]);

  const moveSelectionIndicator = (animate = true) => {
    if (!tabListRef.current) {
      return;
    }

    const tabIdSelector = `.${TAB_SELECTOR}[data-tab-id="${selectedTabId}"]`;
    const selectedTabElement = tabListRef.current.querySelector(
      tabIdSelector
    ) as HTMLElement;

    let indicatorWrapperStyle: React.CSSProperties = { display: 'none' };
    if (selectedTabElement != null) {
      const { clientHeight, clientWidth, offsetLeft, offsetHeight } =
        selectedTabElement;
      indicatorWrapperStyle = {
        height: clientHeight,
        transform: `translateX(${Math.floor(
          offsetLeft
        )}px) translateY(${Math.floor(
          offsetHeight - 4 /* width of the indicator */
        )}px)`,
        width: clientWidth,
      };

      if (!animate) {
        indicatorWrapperStyle.transition = 'none';
        indicatorWrapperStyle.transitionTimingFunction = 'unset';
        indicatorWrapperStyle.transitionDuration = 'unset';
      }
    }
    setIndicatorWrapperStyles(indicatorWrapperStyle);
  };

  const handleTabClick = (
    newTabId: string | number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    props.onChange?.(newTabId, selectedTabId, event);
    if (props.selectedTabId === undefined) {
      setSelectedTabId(newTabId);
    }
  };

  const tabTitles = React.Children.map(props.children, (child) => {
    if (!isTabElement(child)) {
      return child;
    }
    const { id } = child.props;
    return (
      <TabTitle
        {...child.props}
        key={id}
        parentId={props.id}
        onClick={handleTabClick}
        isSelected={id === selectedTabId}
      />
    );
  });

  const tabComponents = getTabChildren(props)
    .filter((tab) => tab.props.id === selectedTabId)
    .map((tab) => {
      if (!tab.props.component) {
        return undefined;
      }
      const { className, component, id } = tab.props;

      return (
        <div
          aria-labelledby={generateTabTitleId(props.id, id)}
          aria-hidden={id !== selectedTabId}
          className={clsx(styles.tabPanel, className)}
          id={generateTabComponentId(props.id, id)}
          key={id}
          role="tabpanel"
        >
          {component}
        </div>
      );
    });

  const classes = clsx(styles.tabs, props.className);

  return (
    <div className={classes}>
      <div className={clsx({ [styles.stickyHeader]: props.isSticky })}>
        <div className={styles.tabList} ref={tabListRef} role="tablist">
          <div
            className={styles.tabIndicatorWrapper}
            style={indicatorWrapperStyles}
          >
            <div className={styles.tabIndicator} />
          </div>
          {tabTitles}
        </div>
        <div className={styles.divider} />
      </div>
      {tabComponents}
    </div>
  );
};

const getInitialSelectedTabId = (
  props: TabsProps & { children?: React.ReactNode }
) => {
  const { selectedTabId, defaultSelectedTabId } = props;
  if (selectedTabId !== undefined) {
    return selectedTabId;
  } else if (defaultSelectedTabId !== undefined) {
    return defaultSelectedTabId;
  } else {
    // select first tab in absence of user input
    const tabs = getTabChildren(props);
    return tabs.length === 0 ? undefined : tabs[0].props.id;
  }
};

/** Filters children to only `<Tab>`s */
const getTabChildren = (props: TabsProps & { children?: React.ReactNode }) => {
  return React.Children.toArray(props.children).filter(isTabElement);
};

const isTabElement = (child: any): child is TabElement => {
  return isElementOfType(child, Tab);
};
