import styles from './TimexChart.module.scss';

// Note: go to https://www.gdoctohtml.com/ in order to convert google doc table to html

const TimexMoveInChart = () => {
  return (
    <table className={styles.timexChart}>
      <tbody>
        <tr>
          <td>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <strong>Lead: I want to move in...</strong>
                    </td>
                    <td>
                      <strong>Start:</strong>
                    </td>
                    <td>
                      <strong>End:</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ul>
                        <li>As soon as possible</li>
                        <li>ASAP</li>
                        <li>Flexible (without indication of other timex)</li>
                        <li>Earliest Availability</li>
                      </ul>
                    </td>
                    <td>Tomorrow</td>
                    <td>30 days from now</td>
                  </tr>
                  <tr>
                    <td>
                      On X date, but I&rsquo;m flexible (they mean within a few
                      weeks)
                    </td>
                    <td>One month before X date</td>
                    <td>One month after X date</td>
                  </tr>
                  <tr>
                    <td>
                      On X date, but I&rsquo;m flexible (they mean within a few
                      months)
                    </td>
                    <td>Two months before X date</td>
                    <td>Two months after X date</td>
                  </tr>
                  <tr>
                    <td>On X date</td>
                    <td>X date (at 12am)</td>
                    <td>(the following date at 12am)</td>
                  </tr>
                  <tr>
                    <td>Around X date</td>
                    <td>7 days before X date</td>
                    <td>7 days after X date</td>
                  </tr>
                  <tr>
                    <td>After X date</td>
                    <td>The day after X date</td>
                    <td>30 days after start date</td>
                  </tr>
                  <tr>
                    <td>By X date</td>
                    <td>Tomorrow</td>
                    <td>X date (at 12am)</td>
                  </tr>
                  <tr>
                    <td>Early/Start of July</td>
                    <td>July 1</td>
                    <td>July 15</td>
                  </tr>
                  <tr>
                    <td>Mid-July</td>
                    <td>July 10</td>
                    <td>July 20</td>
                  </tr>
                  <tr>
                    <td>Late/End of July</td>
                    <td>July 15</td>
                    <td>July 31</td>
                  </tr>
                  <tr>
                    <td>Winter</td>
                    <td>December 21</td>
                    <td>March 20</td>
                  </tr>
                  <tr>
                    <td>Spring</td>
                    <td>March 21</td>
                    <td>June 20</td>
                  </tr>
                  <tr>
                    <td>Summer</td>
                    <td>June 21</td>
                    <td>September 20</td>
                  </tr>
                  <tr>
                    <td>Fall</td>
                    <td>September 21</td>
                    <td>December 20</td>
                  </tr>
                  <tr>
                    <td>Early in the year</td>
                    <td>January 1</td>
                    <td>April 30</td>
                  </tr>
                  <tr>
                    <td>Mid-year/middle of the year</td>
                    <td>May1</td>
                    <td>August 31</td>
                  </tr>
                  <tr>
                    <td>Later in the year</td>
                    <td>September 1</td>
                    <td>December 31</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};
export default TimexMoveInChart;
