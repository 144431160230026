import { Button, ButtonGroup, Spinner, useHotkeys } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import clsx from 'clsx';
import { produce } from 'immer';
import sortBy from 'lodash/sortBy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { useMutation } from 'react-query';

import IntentDTO from '../../state-tagger/intent/IntentDTO';
import IntentInput from '../../state-tagger/intent/IntentInput';
import IntentVM from '../../state-tagger/intent/IntentVM';
import MessageDTO from '../../state-tagger/message/MessageDTO';
import { updateIntents } from '../../state-tagger/state/taggingApiRepository';
import { appToaster } from '../../vanillaelise/appToaster';

import styles from './IntentViewContainer.module.scss';

interface IntentViewContainerProps {
  intents: IntentDTO[];
  event: MessageDTO;
  setEvent?: (event: MessageDTO) => void;
  canEdit?: boolean;
  intentsInitializeOpen?: boolean;
  scopeName?: string;
  horizontal?: boolean;
  maxHeight?: string;
  isLoadingNewIntent?: boolean;
  showDisabledIcon?: boolean;
  isSpeedMode?: boolean;
  /** Disable interaction with the intents on a predicted event until user has confirmed that they would like to proceed */
  disabledIfPredicted?: boolean;
}

export default function IntentViewContainer({
  intents,
  event,
  setEvent,
  canEdit,
  intentsInitializeOpen,
  scopeName,
  horizontal,
  maxHeight,
  isLoadingNewIntent,
  showDisabledIcon,
  isSpeedMode,
  disabledIfPredicted = true,
}: IntentViewContainerProps) {
  const [allIntentsOpen, setAllIntentsOpen] = useState(intentsInitializeOpen);
  const [hasConfirmedToContinue, setHasConfirmedToContinue] = useState(false);

  const onDragEnd = (dropResult: DropResult) => {
    // dropped outside the list
    if (!dropResult.destination) {
      return;
    }

    const { source, destination } = dropResult;
    onSwapIntentIndices?.(source.index, destination.index);
  };

  const [requestUpdateIntents] = useMutation(updateIntents, {
    onError: (e) => {
      appToaster.toastError(e);
    },
  });

  const onRemoveIntent = (intentIdx: number) => {
    if (!setEvent) return;
    const updatedEvent = produce(event, (draft) => {
      draft.intents = draft.intents.filter((_, i) => i !== intentIdx);
    });
    requestUpdateIntents({
      eventId: event.id,
      intents: updatedEvent.intents,
    });
    setEvent(updatedEvent);
  };

  const onChangeIntent = (intentIdx: number, intent: IntentDTO) => {
    if (!setEvent) return;

    const updatedEvent = produce(event, (draft) => {
      draft.intents[intentIdx] = intent;
    });
    requestUpdateIntents({
      eventId: event.id,
      intents: updatedEvent.intents,
    });
    setEvent(updatedEvent);
  };

  // Maintain disabled intents as first intent at all times
  // Disabled intents will maintain alphabetical order
  useEffect(() => {
    let needsToReorder = false;
    let seenEnabledIntent = false;
    for (const i of event.intents) {
      if (!i.unknown?.tagging_app_metadata?.disabled) {
        seenEnabledIntent = true;
      } else {
        if (seenEnabledIntent) {
          needsToReorder = true;
          break;
        }
      }
    }
    if (needsToReorder) {
      setEvent?.(
        produce(event, (draft) => {
          draft.intents = draft.intents.filter(
            (intent) => !intent.unknown?.tagging_app_metadata?.disabled
          );

          const disabledIntents = event.intents.filter(
            (intent) => intent.unknown?.tagging_app_metadata?.disabled
          );
          const sorted = sortBy(disabledIntents, [(intent) => intent.name]);

          draft.intents.unshift(...sorted);
        })
      );
    }
  }, [event, setEvent]);

  const onSwapIntentIndices = (fromIdx: number, toIdx: number) => {
    if (!setEvent) return;
    const updatedEvent = produce(event, (draft) => {
      const moving = draft.intents[fromIdx];
      const removed = draft.intents.filter((el, idx) => idx !== fromIdx);
      draft.intents = [
        ...removed.slice(0, toIdx),
        moving,
        ...removed.slice(toIdx),
      ];
    });
    requestUpdateIntents({ eventId: event.id, intents: updatedEvent.intents });
    setEvent(updatedEvent);
  };

  const shouldShowIntentLock = event.predicted && disabledIfPredicted;
  const areIntentsLocked =
    event.predicted && disabledIfPredicted && !hasConfirmedToContinue;
  const toggleExpanded = useCallback(
    () => setAllIntentsOpen(!allIntentsOpen),
    [allIntentsOpen]
  );

  const hotkeys = useMemo(
    () => [
      {
        combo: 'shift + x',
        global: true,
        label: 'Toggle Expanded/Collapsed Intents',
        onKeyDown: toggleExpanded,
      },
    ],
    [toggleExpanded]
  );
  useHotkeys(hotkeys);

  return (
    <div>
      <ButtonGroup className={styles.buttons}>
        {intents.length > 1 ? (
          <Button
            text={allIntentsOpen ? 'Collapse Intents' : 'Expand Intents'}
            icon={allIntentsOpen ? 'double-chevron-up' : 'double-chevron-down'}
            onClick={toggleExpanded}
          />
        ) : (
          <div />
        )}
        {shouldShowIntentLock && (
          <div>
            <Tooltip2 content="Editing intents while generating a response is not suggested. Please make sure that you mean to edit the intents.">
              <Button
                icon={hasConfirmedToContinue ? 'unlock' : 'lock'}
                onClick={() =>
                  setHasConfirmedToContinue(!hasConfirmedToContinue)
                }
                minimal
              />
            </Tooltip2>
          </div>
        )}
      </ButtonGroup>
      <div
        style={{ maxHeight }}
        className={clsx(styles.container, {
          [styles.disabledContainer]: areIntentsLocked,
        })}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className={clsx({
                  [styles.horizontalWrapped]: horizontal && !allIntentsOpen,
                })}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {intents.map((intent, idx) => {
                  const { disabled, reactKey } =
                    intent.unknown?.tagging_app_metadata ?? {};

                  return (
                    <Draggable
                      key={`draggable-${reactKey}`}
                      draggableId={`draggable-${reactKey}`}
                      index={idx}
                      isDragDisabled={!canEdit || areIntentsLocked}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onMouseDown={(e) => e.currentTarget.focus()}
                        >
                          <IntentInput
                            key={`${event.id}-${reactKey}`}
                            eventId={event.id}
                            intentIdx={idx}
                            className={'mb-2 mr-2'}
                            intent={IntentVM.fromDTO(intent)}
                            canEdit={!areIntentsLocked && canEdit && !disabled}
                            showDisabledIcon={
                              canEdit && disabled && showDisabledIcon
                            }
                            initialIsOpen={allIntentsOpen}
                            onChangeIntent={(intentVM) =>
                              onChangeIntent &&
                              onChangeIntent(idx, IntentVM.toDTO(intentVM))
                            }
                            onRemove={onRemoveIntent}
                            scopeName={scopeName}
                            isSpeedMode={isSpeedMode}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isLoadingNewIntent && <Spinner />}
      </div>
    </div>
  );
}
