/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EliseGuestCardDto = {
    id?: string;
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    source?: string;
    externalClientMarketingSource?: string;
    desiredMoveInDate?: string;
    comments?: string;
    integrationSource?: EliseGuestCardDto.integrationSource;
    initialLeadChannel?: EliseGuestCardDto.initialLeadChannel;
    layout?: number;
    desiredLeaseTerm?: number;
}

export namespace EliseGuestCardDto {

    export enum integrationSource {
        ME_CALENDAR = 'ME-Calendar',
        SST = 'sst',
        EQR_API = 'eqr-api',
        ELISE = 'Elise',
        AVB = 'avb',
        YARDI = 'yardi',
        ENTRATA = 'entrata',
        ANYONEHOME = 'anyonehome',
        REALPAGE = 'realpage',
    }

    export enum initialLeadChannel {
        WALK_IN = 'WALK_IN',
        CALL_TO_PROSPECT = 'CALL_TO_PROSPECT',
        CALL_FROM_PROSPECT = 'CALL_FROM_PROSPECT',
        OTHER = 'OTHER',
        TEXT = 'TEXT',
        EMAIL = 'EMAIL',
        WEB_SERVICE = 'WEB_SERVICE',
        CHAT = 'CHAT',
    }


}
