import { Button, Intent, Menu, MenuDivider, Tag } from '@blueprintjs/core';
import { MenuItem2, Popover2 } from '@blueprintjs/popover2';
import { produce } from 'immer';
import { FC } from 'react';

import styles from './ClassificationTimexSelect.module.css';
import {
  SingleClassificationVM,
  TimexClassificationVM,
  TimexKey,
} from './ClassificationVM';

interface ClassificationTimexSelectProps {
  classification: TimexClassificationVM;
  onChangeClassification?(tvm: TimexClassificationVM): void;
  className?: string;
  onRemove?(): void;
  readOnly?: boolean;
}

/**
 * Select button to modify a Timex classification bundle.
 *
 * On click, shows menus and sub-menus to select values for the
 * different parts of the timex classification set.
 */
export default function ClassificationTimexSelect({
  classification,
  onChangeClassification,
  className,
  onRemove,
  readOnly,
}: ClassificationTimexSelectProps) {
  const text = getTimexClassificationText(classification);
  if (readOnly) {
    return (
      <Tag large className={className}>
        Timex:
        <strong>{text || '(No values selected)'}</strong>
      </Tag>
    );
  }
  return (
    <Popover2
      content={
        <TimexClassificationMenu
          classification={classification}
          onChangeClassification={onChangeClassification}
          onRemove={onRemove}
        />
      }
    >
      <Button
        className={className}
        intent={Intent.PRIMARY}
        text={`Timex: ${text || '(Select values)'}`}
      />
    </Popover2>
  );
}

function getTimexClassificationText(tvm: TimexClassificationVM) {
  return TimexClassificationVM.toSinglesArray(tvm)
    .map((c) => {
      const val = c.selected?.[0];
      return val?.startsWith('nan_') ? '' : val;
    })
    .join(' ')
    .trim();
}

interface TimexClassificationMenuProps {
  classification: TimexClassificationVM;
  onChangeClassification?(tvm: TimexClassificationVM): void;
  onRemove?(): void;
}

const TimexClassificationMenu: FC<TimexClassificationMenuProps> = (props) => {
  const { classification, onChangeClassification } = props;

  const updateSelected = (key: TimexKey) => (selected: string) =>
    onChangeClassification?.(
      produce(classification, (draft) => {
        const field = draft[key];
        if (TimexClassificationVM.isDefined(field)) {
          field.selected = [selected];
        }
      })
    );

  const createMenuItem = (key: TimexKey) => {
    const element = classification[key];
    if (!TimexClassificationVM.isDefined(element)) return [];

    return [
      <TimexClassificationMenuItem
        key={key}
        timexClassification={element}
        onChangeClassification={updateSelected(key)}
      />,
    ];
  };

  const groups: TimexKey[][] = [
    ['startStop', 'negate'],
    ['month', 'dayMonth', 'week', 'dayMod', 'dayWeek'],
    ['time', 'timeMod', 'hour', 'minute'],
  ];

  const elements = groups.map((group) => group.flatMap(createMenuItem));

  return (
    <Menu>
      {elements[0]}

      {elements[1].length > 0 && <MenuDivider title="Date" />}
      {elements[1]}

      {elements[2].length > 0 && <MenuDivider title="Time" />}
      {elements[2]}

      <MenuDivider />
      <MenuItem2
        text="Remove"
        icon="minus"
        onClick={() => props.onRemove?.()}
        intent={Intent.WARNING}
      />
    </Menu>
  );
};

interface TimexClassificationMenuItemProps {
  timexClassification: SingleClassificationVM;
  onChangeClassification?(selected: string): void;
}

const TimexClassificationMenuItem: FC<TimexClassificationMenuItemProps> = (
  props
) => {
  const { timexClassification, onChangeClassification } = props;

  return (
    <MenuItem2
      text={timexClassification.selected?.[0]}
      popoverProps={{ popoverClassName: styles.scrollMenu }}
    >
      {timexClassification.labels?.map((val) => (
        <MenuItem2
          key={val}
          text={val}
          onClick={(e) => {
            e.stopPropagation();
            onChangeClassification?.(val);
          }}
        />
      ))}
    </MenuItem2>
  );
};
