import {
  Button,
  Callout,
  Collapse,
  H4,
  Icon,
  IconName,
  MaybeElement,
  Tag,
} from '@blueprintjs/core';
import { useEffect, useState, ReactNode } from 'react';

interface ExpandableTagProps {
  initialIsOpen?: boolean;
  /**
   * The content to show when the tag is expanded.
   */
  children: ReactNode;
  /**
   * The content to show when the tag is closed, and the title
   * when it's expanded.
   */
  title: ReactNode;
  /**
   * A subtitle to show when the tag is expanded.
   */
  subtitle?: ReactNode;
  className?: string;
  /**
   * If an onRemove callback is provided, the expanded view will display
   * an X button, which triggers this callback.
   */
  onRemove?(): void;
  /**
   * If the tag is disabled, it can't be expanded.
   */
  disabled: boolean;
  rightIcon?: IconName | MaybeElement;
}

enum OpenState {
  CLOSED,
  OPENING,
  OPEN,
  CLOSING,
}

const TRANSITION_MS = Collapse.defaultProps.transitionDuration;

/**
 * A tag that can expand into a callout block.
 *
 * When it's closed, it's a small inline tag. When it's opened, it's a
 * full-width content block.
 */
export default function ExpandableTag({
  initialIsOpen,
  title,
  subtitle,
  disabled,
  className,
  onRemove,
  children,
  rightIcon,
}: ExpandableTagProps) {
  const [openState, setOpenState] = useState(
    initialIsOpen && !disabled ? OpenState.OPEN : OpenState.CLOSED
  );
  useEffect(() => {
    if (openState === OpenState.OPENING) {
      setOpenState(OpenState.OPEN);
    } else if (openState === OpenState.CLOSING) {
      const timerID = setTimeout(() => {
        setOpenState(OpenState.CLOSED);
      }, TRANSITION_MS);
      return () => clearTimeout(timerID);
    }
  }, [openState]);

  useEffect(() => {
    setOpenState(
      initialIsOpen && !disabled ? OpenState.OPEN : OpenState.CLOSED
    );
  }, [initialIsOpen, disabled]);

  if (openState === OpenState.CLOSED) {
    return (
      <>
        <Tag
          large
          minimal
          interactive={!disabled}
          rightIcon={
            rightIcon ? (
              <>
                <Icon icon="chevron-right" />
                <Icon icon={rightIcon} />
              </>
            ) : (
              'chevron-right'
            )
          }
          className={className}
          onClick={disabled ? undefined : () => setOpenState(OpenState.OPENING)}
        >
          {title}
        </Tag>
      </>
    );
  }

  return (
    <Callout className={className}>
      <H4
        className="d-flex align-items-center"
        onClick={() => setOpenState(OpenState.CLOSING)}
      >
        <div
          className="title-subtitle-block"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <span>{title}</span>
          <h6 className="bp4-heading">
            <span className="text-muted">{subtitle}</span>
          </h6>
        </div>
        <Button
          className="ml-auto"
          minimal
          icon="chevron-down"
          onClick={() => setOpenState(OpenState.CLOSING)}
        />
        {onRemove && (
          <Button
            className="ml-2"
            minimal
            icon="trash"
            intent="danger"
            onClick={() => onRemove()}
          />
        )}
        {rightIcon && <Icon icon={rightIcon} />}
      </H4>
      <Collapse isOpen={openState === OpenState.OPEN} keepChildrenMounted>
        {children}
      </Collapse>
    </Callout>
  );
}
