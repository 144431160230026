import {
  Button,
  Checkbox,
  ControlGroup,
  H5,
  H6,
  Icon,
  Menu,
  Radio,
  RadioGroup,
  Switch,
} from '@blueprintjs/core';
import clsx from 'clsx';
import { produce } from 'immer';
import { useCallback } from 'react';

import styles from './EventFilter.module.scss';

export type EventFilterMap = {
  sort?: 'asc' | 'desc';
  toggleGroups?: {
    [groupName: string]: {
      displayName: string;
      toggles: {
        [toggleName: string]: {
          label: string;
          checked: boolean;
          exclusive?: boolean; // If this can only be true if all others in group are false
        };
      };
    };
  };
  checkboxGroups?: {
    [groupName: string]: {
      displayName: string;
      checkboxes: {
        [checkboxName: string]: {
          label: string;
          checked: boolean;
          color: string;
        };
      };
    };
  };
};

export default function EventFilter({
  filter,
  setFilter,
  onSetViewFullContext,
  viewFullContext,
}: {
  filter: EventFilterMap;
  setFilter: (filter: EventFilterMap) => void;
  onSetViewFullContext?: (value: boolean) => void;
  viewFullContext?: boolean;
}) {
  const numChecked = useCallback(
    (groupName: string) => {
      if (!filter.checkboxGroups) return;
      return Object.values(filter.checkboxGroups[groupName].checkboxes).filter(
        (checkbox) => !!checkbox.checked
      ).length;
    },
    [filter.checkboxGroups]
  );

  return (
    <Menu className={styles.filterContainer}>
      {viewFullContext !== undefined && onSetViewFullContext && (
        <div>
          <div className={clsx(styles.header, 'd-flex')}>
            <Checkbox
              checked={viewFullContext}
              onChange={() => {
                onSetViewFullContext(!viewFullContext);
              }}
            />
            <H5>Show full context</H5>
          </div>
        </div>
      )}
      {filter.sort !== undefined && (
        <>
          <div className={styles.header}>
            <H5>Sort By</H5>
            <Icon icon="sort" />
          </div>

          <H6>Time</H6>
          <RadioGroup
            className="mb-3"
            onChange={(e) => {
              setFilter(
                produce(filter, (draft) => {
                  draft.sort = (e.target as HTMLInputElement).value as
                    | 'asc'
                    | 'desc';
                })
              );
            }}
            selectedValue={filter.sort}
          >
            <Radio className="ml-2" label="ascending" value="asc" />
            <Radio className="ml-2" label="descending" value="desc" />
          </RadioGroup>
        </>
      )}

      <div className={styles.header}>
        <H5>Filter</H5>
        <Icon icon="filter" />
      </div>
      <ControlGroup vertical>
        {filter.toggleGroups &&
          Object.entries(filter.toggleGroups).map(([groupName, group]) => (
            <div key={groupName}>
              <H6>{group.displayName}</H6>
              <ControlGroup vertical>
                {Object.entries(group.toggles).map(
                  ([toggleName, { label, checked }]) => (
                    <Switch
                      key={toggleName}
                      label={label}
                      checked={checked}
                      onChange={(e) => {
                        if (!filter.toggleGroups) return;
                        if (
                          //Turning on and exclusive
                          filter.toggleGroups[groupName].toggles[toggleName]
                            .checked === false &&
                          filter.toggleGroups[groupName].toggles[toggleName]
                            .exclusive
                        ) {
                          setFilter(
                            produce(filter, (draft) => {
                              if (!draft.toggleGroups) return;

                              const toggleNames = Object.keys(
                                draft.toggleGroups[groupName].toggles
                              );
                              toggleNames.forEach((name) => {
                                if (!draft.toggleGroups) return;
                                draft.toggleGroups[groupName].toggles[
                                  name
                                ].checked = false;
                              });
                              draft.toggleGroups[groupName].toggles[
                                toggleName
                              ].checked = true;
                              return draft;
                            })
                          );
                        } else {
                          setFilter(
                            produce(filter, (draft) => {
                              if (!draft.toggleGroups) return;
                              draft.toggleGroups[groupName].toggles[
                                toggleName
                              ].checked =
                                !draft.toggleGroups[groupName].toggles[
                                  toggleName
                                ].checked;
                              return draft;
                            })
                          );
                        }
                      }}
                      alignIndicator="right"
                    />
                  )
                )}
              </ControlGroup>
            </div>
          ))}
        {filter.checkboxGroups &&
          Object.entries(filter.checkboxGroups).map(([groupName, group]) => (
            <div key={groupName}>
              <H6>{group.displayName}</H6>
              <ControlGroup vertical>
                {Object.entries(group.checkboxes).map(
                  ([checkboxName, { label, checked, color }]) => (
                    <div
                      key={checkboxName}
                      className={styles.checkboxButtonContainer}
                    >
                      <div className={styles.checkboxIndicatorContainer}>
                        <div
                          className={styles.typeIndicator}
                          style={{
                            background: color,
                          }}
                        />
                        <Checkbox
                          label={label}
                          checked={checked}
                          onChange={(e) => {
                            if (
                              e.currentTarget.checked === false &&
                              numChecked(groupName) === 1
                            ) {
                              const checkboxNames = Object.keys(
                                filter.checkboxGroups?.[groupName].checkboxes ??
                                  {}
                              );
                              setFilter(
                                produce(filter, (draft) => {
                                  checkboxNames.forEach((name) => {
                                    if (!draft.checkboxGroups) return draft;
                                    draft.checkboxGroups[groupName].checkboxes[
                                      name
                                    ].checked = true;
                                  });
                                  return draft;
                                })
                              );
                            } else {
                              setFilter(
                                produce(filter, (draft) => {
                                  if (!draft.checkboxGroups) return;
                                  draft.checkboxGroups[groupName].checkboxes[
                                    checkboxName
                                  ].checked = e.currentTarget.checked;
                                })
                              );
                            }
                          }}
                        />
                      </div>
                      <Button
                        text={
                          numChecked(groupName) === 1 &&
                          filter.checkboxGroups?.[groupName].checkboxes[
                            checkboxName
                          ].checked
                            ? 'All'
                            : 'Only'
                        }
                        minimal
                        small
                        className={styles.onlyButton}
                        onClick={() => {
                          if (
                            numChecked(groupName) === 1 &&
                            filter.checkboxGroups?.[groupName].checkboxes[
                              checkboxName
                            ].checked
                          ) {
                            if (!filter.checkboxGroups) return;
                            const checkboxNames = Object.keys(
                              filter.checkboxGroups[groupName].checkboxes
                            );
                            setFilter(
                              produce(filter, (draft) => {
                                checkboxNames.forEach((name) => {
                                  if (!draft.checkboxGroups) return draft;
                                  draft.checkboxGroups[groupName].checkboxes[
                                    name
                                  ].checked = true;
                                });
                                return draft;
                              })
                            );
                          } else {
                            if (!filter.checkboxGroups) return;
                            const checkboxNames = Object.keys(
                              filter.checkboxGroups[groupName].checkboxes
                            );
                            setFilter(
                              produce(filter, (draft) => {
                                checkboxNames.forEach((name) => {
                                  if (!draft.checkboxGroups) return draft;
                                  draft.checkboxGroups[groupName].checkboxes[
                                    name
                                  ].checked = false;
                                });
                                if (!draft.checkboxGroups) return draft;
                                draft.checkboxGroups[groupName].checkboxes[
                                  checkboxName
                                ].checked = true;
                                return draft;
                              })
                            );
                          }
                        }}
                      />
                    </div>
                  )
                )}
              </ControlGroup>
            </div>
          ))}
      </ControlGroup>
    </Menu>
  );
}
