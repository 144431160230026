/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ValidateCredentialsRequest = {
    validationName: string;
    integrationValidationType: ValidateCredentialsRequest.integrationValidationType;
    buildingName: string;
    streetAddress: string;
    username?: string;
    password?: string;
    submitterEmail: string;
    submitterOrgId: number;
    marketingApiKey?: string;
    databaseName?: string;
    serverName?: string;
    voyagerUrl?: string;
    companyCode?: string;
    connectionUrl?: string;
    vendorApiToken?: string;
    entrataDomain?: string;
}

export namespace ValidateCredentialsRequest {

    export enum integrationValidationType {
        VOYAGER = 'VOYAGER',
        VOYAGER_PLUS_RENTCAFE_MARKETING_API_KEY_AND_VENDOR_API_TOKEN = 'VOYAGER_PLUS_RENTCAFE_MARKETING_API_KEY_AND_VENDOR_API_TOKEN',
        VOYAGER_PLUS_RENTCAFE_VENDOR_API_TOKEN = 'VOYAGER_PLUS_RENTCAFE_VENDOR_API_TOKEN',
        ENTRATA = 'ENTRATA',
        KNOCK = 'KNOCK',
    }


}
